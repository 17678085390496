import React, { Component } from 'react'

export default class Dashboard extends Component {
    render() {
        return (
            <div>
                <h1>Welcome to admin dashboard</h1>
            </div>
        )
    }
}
