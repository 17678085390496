import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { TabContext } from "@material-ui/lab";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../utilities/Firebase";
import { AdminFooter } from "./layouts";
import logo from "../assets/placeholders/WALSTRAT LOGO-.png";

// import filterCancelIcon from '../../assets/img/filter-cancel.png'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Snackbar,
  Typography,
  Tooltip,
  Zoom,
  withStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
} from "@material-ui/core";
// import noDataImg from "../../assets/img/NoData.png";
import Page from "./page";
// import ConfirmDialog from "../Register/ConfirmDialog";
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";

class EnquiryDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: "1",
      webInquiry: [],
      productEnquiry: [],
      meeting: [],
    };
  }

  componentDidMount = () => {
    firebase
      .firestore()
      .collection("walstrat")
      .doc("root")
      .collection("Enquiry")
      .onSnapshot((querySnapshot) => {
        var webEnquiry = [];
        var productEnquiry = [];
        var meeting = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().type == "Product Enquiry") {
            let Obj = doc.data();
            Obj.id = doc.id;
            productEnquiry.push(Obj);
          } else if (doc.data().type == "Meeting") {
            let Obj = doc.data();
            Obj.id = doc.id;
            meeting.push(Obj);
          } else if (doc.data().type == "Website Enquiry") {
            let Obj = doc.data();
            Obj.id = doc.id;
            webEnquiry.push(Obj);
          }
        });
        this.setState({
          webInquiry: webEnquiry,
          productEnquiry: productEnquiry,
          meeting: meeting,
        });
      });
  };

  handleChangeTab = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  goTOLogin = () => {
    localStorage.clear();
    window.location.href = "/admin";
  };

  render() {
    return (
      <div>
        <Page title="Enquiry Details">
          <Container style={{ marginTop: "110px" }}>
            <Box
              display="flex"
              style={{ alignItems: "center", margin: "10px" }}
            >
              <Box
                flexGrow={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <Typography variant="h5" style={{ fontFamily: "Sitka, serif" }}>Enquiry Details</Typography>
              </Box>
            </Box>
            <Divider />
            <Card>
              <CardContent>
                <TabContext value={this.state.tabValue}>
                  <AppBar
                    position="static"
                    style={{
                      color: "white",
                      background: "linear-gradient(60deg, #031163, #05716C)",
                    }}
                  >
                    <TabList
                      onChange={this.handleChangeTab}
                      aria-label="profiletab"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#fff",
                        }
                      }}
                    >
                      <Tab
                        label="Web Enquiry"
                        value="1"
                        style={{ fontFamily: "Montserrat" }}
                      />
                      <Tab
                        label="Product Enquiry"
                        value="2"
                        style={{ fontFamily: "Montserrat" }}
                      />
                      <Tab
                        label={"Meeting"}
                        value="3"
                        style={{ fontFamily: "Montserrat" }}
                      />
                    </TabList>
                  </AppBar>
                  <TabPanel value="1">
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Customer Name</TableCell>
                                <TableCell align="left">
                                  Customer Email
                                </TableCell>
                                <TableCell align="left">Message</TableCell>
                                <TableCell align="left">Mail Status</TableCell>
                                <TableCell align="left">Enquired On</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.webInquiry.map((data, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {data.userName}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.userEmail}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.userMessage}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.mailStatus == "success" ? (
                                      <div>
                                        <span>{data.mailStatus}</span>
                                        <img src="https://img.icons8.com/officexs/16/000000/checked.png" />
                                      </div>
                                    ) : (
                                      <div>
                                        <span>{data.mailStatus}</span>
                                        <img src="https://img.icons8.com/fluent/48/000000/cancel.png" />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {new Date(data.dateTime).toLocaleDateString(
                                      "en-IN"
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  {/* Only Product List */}
                  <TabPanel value="2">
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Customer Name</TableCell>
                                <TableCell align="left">
                                  Customer Email
                                </TableCell>
                                <TableCell align="left">Product Name</TableCell>
                                <TableCell align="left">Message</TableCell>
                                <TableCell align="left">Mail Status</TableCell>
                                <TableCell align="left">Enquired On</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.productEnquiry.map((data, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {data.userName}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.userEmail}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.productName}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.userMessage}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.mailStatus == "success" ? (
                                      <div>
                                        <span>{data.mailStatus}</span>
                                        <img src="https://img.icons8.com/officexs/16/000000/checked.png" />
                                      </div>
                                    ) : (
                                      <div>
                                        <span>{data.mailStatus}</span>
                                        <img src="https://img.icons8.com/fluent/16/000000/cancel.png" />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {new Date(data.dateTime).toLocaleDateString(
                                      "en-IN"
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  {/* Only Meeting List */}
                  <TabPanel value="3">
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Customer Name</TableCell>
                                <TableCell align="left">
                                  Customer Email
                                </TableCell>
                                <TableCell align="left">Message</TableCell>
                                <TableCell align="left">Meeting Date</TableCell>
                                <TableCell align="left">Mail Status</TableCell>
                                <TableCell align="left">Requested On</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.meeting.map((data, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {data.userName}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.userEmail}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.userMessage}
                                  </TableCell>
                                  <TableCell align="left">
                                    {new Date(
                                      data.meetingdateTime
                                    ).toLocaleString("en-IN")}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.mailStatus == "success" ? (
                                      <div>
                                        <span>{data.mailStatus}</span>
                                        <img src="https://img.icons8.com/officexs/16/000000/checked.png" />
                                      </div>
                                    ) : (
                                      <div>
                                        <span>{data.mailStatus}</span>
                                        <img src="https://img.icons8.com/fluent/16/000000/cancel.png" />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {new Date(data.dateTime).toLocaleDateString(
                                      "en-IN"
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </CardContent>
            </Card>

            {/* <Snackbar
              open={this.state.showSnackbar}
              autoHideDuration={4000}
              key="topcenter"
              onClose={this.closeSnackbar}
            >
              <Alert onClose={this.closeSnackbar} severity="success">
                {this.state.snackbarText}
              </Alert>
            </Snackbar> */}

            {/* <ConfirmDialog
              confirmDialog={this.state.confirmDialog}
              setConfirmDialog={this.setConfirmDialog}
            /> */}
          </Container>
        </Page>
      </div>
    );
  }
}
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
export default EnquiryDetail;
