import React, { useState } from "react";
//import styles from "./Services2.module.scss";
import THEME from "../../../state/theme";
import "bootstrap/dist/css/bootstrap.min.css";
import Flip from "react-reveal/Flip";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import manuimg1 from "../../../assets/placeholders/services/manu.jpg";
import productimg1 from "../../../assets/placeholders/products/1.png";
// import productimg2 from '../../../assets/placeholders/products/2.png';
// import productimg3 from '../../../assets/placeholders/products/3.png';
import productimg4 from "../../../assets/placeholders/products/7.png";
// import productimg5 from '../../../assets/placeholders/products/9.png';
// import productimg6 from '../../../assets/placeholders/products/10.png';
// import productimg7 from '../../../assets/placeholders/products/12.png';
// import productimg8 from '../../../assets/placeholders/products/8.png';
// import productimg9 from '../../../assets/placeholders/products/11.png';
import productimg10 from "../../../assets/placeholders/products/13.png";
// import productimg11 from '../../../assets/placeholders/products/14.png';
// import productimg12 from '../../../assets/placeholders/products/15.png';
import productimg13 from "../../../assets/placeholders/products/16.png";
// import productimg14 from '../../../assets/placeholders/products/17.png';
// import productimg15 from '../../../assets/placeholders/products/18.png';
import productimg16 from "../../../assets/placeholders/products/19.png";
// import productimg17 from '../../../assets/placeholders/products/20.png';
// import productimg18 from '../../../assets/placeholders/products/21.png';
// import productimg19 from '../../../assets/placeholders/products/22.png';
// import productimg20 from '../../../assets/placeholders/products/23.png';
// import productimg21 from '../../../assets/placeholders/products/24.png';
// import productimg22 from '../../../assets/placeholders/products/25.png';
// import productimg23 from '../../../assets/placeholders/products/26.png';
// import productimg24 from '../../../assets/placeholders/products/27.png';
import productimg25 from "../../../assets/placeholders/products/28.png";
// import productimg26 from '../../../assets/placeholders/products/29.png';
// import productimg27 from '../../../assets/placeholders/products/29.png';
// import productimg28 from '../../../assets/placeholders/products/30.png';
// import productimg29 from '../../../assets/placeholders/products/32.png';
// import productimg30 from '../../../assets/placeholders/products/33.png';
import productimg31 from "../../../assets/placeholders/products/42.png";
import productimg32 from "../../../assets/placeholders/products/43.png";
// import productimg33 from '../../../assets/placeholders/products/44.png';
// import productimg34 from '../../../assets/placeholders/products/45.png';
import productimg35 from "../../../assets/placeholders/products/47.png";

//import Header from "../Header/Header";
// import outsourcingicon from '../../../assets/placeholders/icon/outsourcing.png';

// import Particle from './particle';
// import Aviation from './aviation';

import Header from "../../layouts/Header/Header.js";
import { useCustomState } from "../../../state/state";
import { data } from "jquery";
import { useHistory } from "react-router";
import Home from "../../pages/Home/Home";
import { Switch, Route } from "react-router-dom";
import { About } from "../../pages";
import { Footer } from "../../layouts";
import ProductDialog from "./ProductDialog";

// const history = useHistory();
//const state = useCustomState()[0];

export default class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openProduct: false,
      productData: [],
    };
  }

  componentDidMount() {}
  goTOBack = () => {
    window.location.href = "/";
  };

  productInfo = (pId, pName) => {
    console.log("p data => ", pId, pName);
    let promies = new Promise((res, rej) => {
      this.setState({
        productData: [
          {
            id: pId,
            name: pName,
          },
        ],
      });
      res(true);
    });
    promies.then((res) => {
      console.log("Product data promies", this.state.productData);
      this.setState({
        openProduct: true,
      });
    });
  };

  handleClickCloseProduct = () => {
    this.setState({
      openProduct: false,
    });
  };

  loadSector1() {
    let sectorParent = document.getElementById("sector1");
    for (let i = 0; i < 100; i++) {
      let tempObj = document.createElement("a");

      // <a class="reflection-grid-cell reflection-grid-cell-1" href="#"></a>
    }
  }

  // goBack = () => {
  //     window.location.href="/"
  // }
  render() {
    return (
      <div>
        <Header></Header>

        {/* <div className="serviceheader">
                    <img src={logo} className="widthlogoslider positionlogo" onClick={this.goTOBack}></img>
                    <button onClick={this.goTOBack} className="backtohomebtn">Back to Home</button>

                </div> */}
        <section className="services-area section-padding">
          <div className="container">
            <div className="layout-header layputpadding" id="products">
              <Flip top>
                <span className="subtitle" style={{ color: THEME.color }}>
                  Our Products
                </span>
              </Flip>
            </div>
            {/* <div className="flip-card-container"> */}

            <div className="row">
              <div style={{width : '100%'}}>
              <button
              style={{width : 'auto'}}
                className="btn-glow"
                onClick={() => (window.open("http://eurolatex.in/",'_blank'))}
              >
                Visit Store &nbsp;
                <ShoppingCartIcon />
              </button>
              </div>
              <div className="col-lg-3 col-md-4 col-12 wow fadeInUp">
                {/*-------------------------------- product 1 ------------------------*/}
                {/* <!-- flip-card-container --> */}
                <div className="flip-card-container flip-card-containerproduct">
                  <div className="flip-card flipcardshadow">
                    <div className="card-front radiuszero ">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg1}
                          alt="Orthopedic Massage Cervical Pillow"
                        />
                      </figure>
                      <figcaption className="Figcaption bottomtext">
                        {" "}
                        Euro Latex Orthopedic Massage Cervical Pillow
                      </figcaption>
                    </div>
                    <div className="card-back radiuszero overlay4">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg1}
                          alt="Orthopedic Massage Cervical Pillow"
                        />
                      </figure>
                      <ul className="detailul">
                        <li className="detailli">
                          An orthopedic pillow made of 100% Natural Organic
                          Latex with 3 zones and convoluted surface for a
                          massage effect{" "}
                        </li>
                        <li>
                          <p>Dimension:60X35 cm</p>
                        </li>
                        <li>
                          <p>Density:45</p>
                        </li>
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={() =>
                            this.productInfo(
                              "",
                              "Euro Latex Orthopedic Massage Cervical Pillow"
                            )
                          }
                        >
                          Enquiry
                        </button>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/*-------------------------------- product 7------------------------*/}

              <div className="col-lg-3 col-md-4 col-12 wow fadeInUp">
                {/* <!-- flip-card-container --> */}
                <div className="flip-card-container flip-card-containerproduct">
                  <div className="flip-card flipcardshadow">
                    <div className="card-front radiuszero">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg4}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <figcaption className="Figcaption bottomtext">
                        {" "}
                        Euro Latex Orthopedic Cervical Pillow
                      </figcaption>
                    </div>
                    <div className="card-back radiuszero overlay4">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg4}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <ul className="detailul">
                        <li className="detailli">
                          An orthopedic pillow made of 100% Natural Organic
                          Latex with 3 zones.{" "}
                        </li>
                        <li>
                          <p>Dimension:60X35 cm</p>
                        </li>
                        <li>
                          <p>Density:45</p>
                        </li>
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={() =>
                            this.productInfo(
                              "",
                              "Euro Latex Orthopedic Cervical Pillow"
                            )
                          }
                        >
                          {" "}
                          Enquiry
                        </button>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/*-------------------------------- product 13------------------------*/}

              <div className="col-lg-3 col-md-4 col-12 wow fadeInUp">
                {/* <!-- flip-card-container --> */}
                <div className="flip-card-container flip-card-containerproduct">
                  <div className="flip-card flipcardshadow">
                    <div className="card-front radiuszero">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg10}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <figcaption className="Figcaption bottomtext">
                        {" "}
                        Euro Latex Heart Shaped Pillow
                      </figcaption>
                    </div>
                    <div className="card-back radiuszero overlay4">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg10}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <ul className="detailul">
                        <li className="detailli">
                          An Orthopedic Pillow made with 100% Natural Organic
                          Latex specially designed to support the neck and
                          shoulders
                        </li>
                        <li>
                          <p>Dimension:53X35 cm</p>
                        </li>
                        <li>
                          <p>Density:45</p>
                        </li>
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={() =>
                            this.productInfo(
                              "",
                              "Euro Latex Heart Shaped Pillow"
                            )
                          }
                        >
                          {" "}
                          Enquiry
                        </button>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-12 wow fadeInUp">
                {/* <!-- flip-card-container --> */}
                <div className="flip-card-container flip-card-containerproduct">
                  <div className="flip-card flipcardshadow">
                    <div className="card-front radiuszero">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg13}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <figcaption className="Figcaption bottomtext">
                        {" "}
                        Euro Latex Standard Fluffy Pillow
                      </figcaption>
                    </div>
                    <div className="card-back radiuszero overlay4">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg13}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <ul className="detailul">
                        <li className="detailli">
                          A Pillow made of 100% Natural Organic Latex{" "}
                        </li>
                        <li>
                          <p>Dimension:60X40 cm</p>
                        </li>
                        <li>
                          <p>Density:45</p>
                        </li>
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={() =>
                            this.productInfo(
                              "",
                              "Euro Latex Standard Fluffy Pillow"
                            )
                          }
                        >
                          {" "}
                          Enquiry
                        </button>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-12 wow fadeInUp">
                {/* <!-- flip-card-container --> */}
                <div className="flip-card-container flip-card-containerproduct">
                  <div className="flip-card flipcardshadow">
                    <div className="card-front radiuszero">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg16}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <figcaption className="Figcaption bottomtext">
                        {" "}
                        Euro Latex Bamboo Charcoal Orthopedic Massage Cervical
                        Pillow
                      </figcaption>
                    </div>
                    <div className="card-back radiuszero overlay4">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg16}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <ul className="detailul">
                        <li className="detailli">
                          An orthopedic pillow made of 100% Natural Organic
                          Latex with 3 zones and convoluted surface for a
                          massage effect{" "}
                        </li>
                        <li>
                          <p>Dimension:60X35 cm</p>
                        </li>
                        <li>
                          <p>Density:45</p>
                        </li>
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={() =>
                            this.productInfo(
                              "",
                              "Euro Latex Bamboo Charcoal Orthopedic Massage Cervical Pillow"
                            )
                          }
                        >
                          {" "}
                          Enquiry
                        </button>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-12 wow fadeInUp">
                {/* <!-- flip-card-container --> */}
                <div className="flip-card-container flip-card-containerproduct">
                  <div className="flip-card flipcardshadow">
                    <div className="card-front radiuszero">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg25}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <figcaption className="Figcaption bottomtext">
                        {" "}
                        Euro Latex Bamboo Charcoal Heart Shaped Massage Pillow
                      </figcaption>
                    </div>
                    <div className="card-back radiuszero overlay4">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg25}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <ul className="detailul">
                        <li className="detailli">
                          An Orthopedic Pillow made with 100% Natural Organic
                          Latex specially designed to support the neck and
                          shoulders with a message effect{" "}
                        </li>
                        <li>
                          <p>Dimension:60X38 cm</p>
                        </li>
                        <li>
                          <p>Density:45</p>
                        </li>
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={() =>
                            this.productInfo(
                              "",
                              "Euro Latex Bamboo Charcoal Heart Shaped Massage Pillow"
                            )
                          }
                        >
                          {" "}
                          Enquiry
                        </button>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-12 wow fadeInUp">
                {/* <!-- flip-card-container --> */}
                <div className="flip-card-container flip-card-containerproduct">
                  <div className="flip-card flipcardshadow">
                    <div className="card-front radiuszero">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg31}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <figcaption className="Figcaption bottomtext">
                        Euro Latex Boldster
                      </figcaption>
                    </div>
                    <div className="card-back radiuszero overlay4">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg31}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <ul className="detailul">
                        <li className="detailli">
                          A Boldster made with 100% Natural Organic Latexfor
                          perfect support{" "}
                        </li>
                        <li>
                          <p>Dimension:100X19 cm</p>
                        </li>
                        <li>
                          <p>Density:45</p>
                        </li>
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={() =>
                            this.productInfo("", "Euro Latex Boldster")
                          }
                        >
                          {" "}
                          Enquiry
                        </button>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-12 wow fadeInUp">
                {/* <!-- flip-card-container --> */}
                <div className="flip-card-container flip-card-containerproduct">
                  <div className="flip-card flipcardshadow">
                    <div className="card-front radiuszero">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg32}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <figcaption className="Figcaption bottomtext">
                        {" "}
                        Euro Latex Car Seat Cushion
                      </figcaption>
                    </div>
                    <div className="card-back radiuszero overlay4">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg32}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <ul className="detailul">
                        <li className="detailli">
                          An orthopedic Cushion that is perfect for anyone who
                          needs to sit for long hours in the perfect posture
                        </li>
                        <li>
                          <p>Dimension:30X30 cm</p>
                        </li>
                        <li>
                          <p>Density:45</p>
                        </li>
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={() =>
                            this.productInfo("", "Euro Latex Car Seat Cushion")
                          }
                        >
                          {" "}
                          Enquiry
                        </button>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-12 wow fadeInUp">
                {/* <!-- flip-card-container --> */}
                <div className="flip-card-container flip-card-containerproduct">
                  <div className="flip-card flipcardshadow">
                    <div className="card-front radiuszero">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg35}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <figcaption className="Figcaption bottomtext">
                        Euro Latex 7 Zone Latex Mattress
                      </figcaption>
                    </div>
                    <div className="card-back radiuszero overlay4">
                      <figure className="Figure">
                        <img
                          className="Img radiuszero widthImg"
                          src={productimg35}
                          alt="Brohm Lake"
                        />
                      </figure>
                      <ul className="detailul">
                        <li className="detailli">
                          Made in Thailand from 75-80 Density Latex, this
                          mattress is divided into 7 zones of hardness and
                          softness.{" "}
                        </li>
                        <li>
                          <p>Dimension:Multiple</p>
                        </li>
                        <li>
                          <p>Density:75</p>
                        </li>
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={() =>
                            this.productInfo(
                              "",
                              "Euro Latex 7 Zone Latex Mattress"
                            )
                          }
                        >
                          {" "}
                          Enquiry
                        </button>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductDialog
          open={this.state.openProduct}
          Product={this.state.productData}
          onClose={this.handleClickCloseProduct}
        />
        <Footer></Footer>
      </div>
    );
  }
}
