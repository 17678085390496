/* eslint-disable */

import React, { useState, useEffect, Fragment } from "react";
import styles from "./Header.module.scss";
import Layout from "../Layout/Layout";
import { Button, Logo } from "../../elements";
import THEME from "../../../state/theme";
import { useCustomState } from "../../../state/state";
import "./header.css";
import Logoslider from "../../elements/Logo/Logoslider";
import { Link } from "react-scroll";
import menuicon from "../../../assets/placeholders/menu.png";
import Product from "../../pages/Products/Product";
import { useHistory } from "react-router";
import melzounite from "../../../assets/placeholders/uinte.png";
import MeetingDialog from "../../widgets/Contacts2/MeetingDialog";

import GoogleTranslate from "../../../GoogleTranslate";

export default ({ data = [] }) => {
  const [sticky, setSticky] = useState(false);
  const [meeting, setMeeting] = useState(false);

  const actions = useCustomState()[1];
  const history = useHistory();
  const handleResize = () => {
    setSticky(window.pageYOffset > 200 ? true : false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleResize);
    return () => {
      window.removeEventListener("scroll", handleResize);
    };
  }, []);

  const menu = data.map((item, index) => {
    if (!item.children) {
      return (
        <li key={index}>
          <Link url={item.url} hoverStyle={{ color: THEME.color }}>
            {item.name}
          </Link>
        </li>
      );
    } else {
      return (
        <li key={index}>
          <span>
            <Link url={item.url} hoverStyle={{ color: THEME.color }}>
              {item.name}
            </Link>
          </span>
          <ul>
            {item.children.map((subitem, subindex) => (
              <li key={subindex}>
                <a href={subitem.url}>{subitem.name}</a>
              </li>
            ))}
          </ul>
        </li>
      );
    }
  });

  function gotoProducts() {
    window.location.href = "/products";
  }
  function homepage() {
    window.location.href = "/";
  }
  const schedulemeet = () => {
    setMeeting(true);
  };
  const handleClickCloseMeeting = () => {
    setMeeting(false);
  };
  const header = (
    <Layout>
      <div className={styles.header}>
        <Link url="home">
          <Logoslider dark />
        </Link>

        {/* <ul className={styles.menu}>{menu}</ul> */}
        <ul className={styles.menu}>
          <li>
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              duration={1000}
              className="cursorpointer"
              onClick={() => {
                history.push("/");
              }}
            >
              Home{" "}
            </Link>
            {/* <a href="#home" className="smoothscroll">Home</a> */}
          </li>
          <li>
            <a
              // activeClass="active"
              // to="about"
              // spy={true}
              // smooth={true}
              // duration={1000}
              className="cursorpointer"
              onClick={() => {
                history.push("/");
                setTimeout(() => {
                  let productEl = document.getElementById("about");
                  if (productEl) {
                    productEl.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                }, 500);
              }}
            >
              About{" "}
            </a>
            {/* <a href="#about" className="smoothscroll">About us</a> */}
          </li>
          <li>
            <a
              // activeClass="active"
              // to="services"
              // spy={true}
              // smooth={true}
              // duration={1000}
              className="cursorpointer"
              onClick={() => {
                history.push("/");
                setTimeout(() => {
                  let productEl = document.getElementById("services");
                  if (productEl) {
                    productEl.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                }, 500);
              }}
            >
              Our Expertise{" "}
            </a>
            <ul style={{ top: "80%" }}>
              <li>
                <a
                  className="cursorpointer"
                  onClick={() => {
                    history.push("/");
                    setTimeout(() => {
                      let productEl = document.getElementById("services");
                      if (productEl) {
                        productEl.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }, 500);
                  }}
                >
                  Services{" "}
                </a>
              </li>
              <li>
                <a className="cursorpointer" onClick={gotoProducts}>
                  Products{" "}
                </a>
              </li>
              <li>
                <a
                  className="cursorpointer"
                  onClick={() => (window.location.href = "/projects")}
                >
                  Projects{" "}
                </a>
              </li>
            </ul>
            {/* <a href="#services" className="smoothscroll">Services</a> */}
          </li>
          <li>
            <a
              // activeClass="active"
              // to="blogpage"
              // spy={true}
              // smooth={true}
              // duration={1000}
              className="cursorpointer"
              onClick={() => {
                history.push("/");
                setTimeout(() => {
                  let productEl = document.getElementById("blogpage");
                  if (productEl) {
                    productEl.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                }, 500);
              }}
            >
              Resources{" "}
            </a>
            <ul style={{ top: "80%" }}>
              <li>
                <a
                  className="cursorpointer"
                  onClick={() => {
                    history.push("/");
                    setTimeout(() => {
                      let productEl = document.getElementById("blogpage");
                      if (productEl) {
                        productEl.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }, 500);
                  }}
                >
                  Blogs{" "}
                </a>
              </li>
              <li>
                <a
                  className="cursorpointer"
                  onClick={() => {
                    history.push("/");
                    setTimeout(() => {
                      let productEl = document.getElementById("media");
                      if (productEl) {
                        productEl.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }, 500);
                  }}
                >
                  Media{" "}
                </a>
              </li>
              {/* <li>
                <a
                  className="cursorpointer"
                  onClick={() => {
                    history.push("/");
                    setTimeout(() => {
                      let productEl = document.getElementById("projectpage");
                      if (productEl) {
                        productEl.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }, 500);
                  }}
                >
                  Projects{" "}
                </a>
              </li> */}
            </ul>
            {/* <a href="#blog" className="smoothscroll">Blog</a> */}
          </li>
          {/* 
          <li>
            <Link
              activeClass="active"
              to="products"
              spy={true}
              smooth={true}
              duration={1000}
              className="cursorpointer"
              onClick={gotoProducts}
            >
              Products{" "}
            </Link>
          </li> */}

          <li>
            <Link
              // activeClass="active"
              // to="contact"
              // spy={true}
              // smooth={true}
              // duration={1000}
              className="cursorpointer"
              onClick={() => {
                history.push("/");
                setTimeout(() => {
                  let productEl = document.getElementById("contact");
                  if (productEl) {
                    productEl.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                }, 500);
              }}
            >
              Contact{" "}
            </Link>
            {/* <a href="#contact" className="smoothscroll">Contact</a> */}
          </li>

          <li>
            <GoogleTranslate className="sb-float-right-margin" />
          </li>

          {/* <li>
            <Link
              activeClass="active"
              to="admin"
              spy={true}
              smooth={true}
              duration={1000}
              className="cursorpointer"
            >
              Admin
            </Link>
          </li> */}
          <li>
            <a
              activeClass="active"
              // to="services"
              spy={true}
              smooth={true}
              duration={1000}
              className="cursorpointer"
              // href="https://unite.melzo.com/"

              target="_blank"
              title="24x7 Buyer Seller Meet"
            >
              <img
                src={melzounite}
                width="30px"
                onClick={() => schedulemeet()}
              ></img>
            </a>
          </li>
        </ul>
        {meeting && (
          <MeetingDialog
            open={meeting}
            onClose={handleClickCloseMeeting}
            type={"melzoUnite"}
          />
        )}
        {/* <div className={styles.btn_desktop}>
          <Button
            // after="&#xf107;"
            type="outline-white-tw"
            hoverType="solid-white-tb"
            click={() => actions.toogleModal()}
          >
            Schedule a meeting
          </Button>
        </div> */}

        <div
          className={styles.btn_mobile}
          onClick={() => actions.toogleSidebar()}
        >
          <Button
            // after="&#xf0c9;"
            type="solid-white-tb"
            hoverType="solid-gray-tb"
          >
            <img src={menuicon} />
          </Button>
        </div>
      </div>
    </Layout>
  );

  return (
    <Fragment>
      <div className={styles.wrapper} style={{ zIndex: 12 }}>
        {header}
      </div>
      <div
        className={[
          styles.wrapper,
          sticky ? styles.sticky : styles.hidden,
        ].join(" ")}
      >
        {header}
      </div>
    </Fragment>
  );
};
