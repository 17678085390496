import React, { Component } from "react";
import "./media.css";
import Zoom from "react-reveal/Zoom";
import Flip from "react-reveal/Flip";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import mediaimg1 from "../../../assets/placeholders/media/real estate.png";
import mediaimg2 from "../../../assets/placeholders/media/electrical 1.png";
import mediaimg3 from "../../../assets/placeholders/media/tourismm.png";
import LazyLoad from "react-lazy-load";
import firebase from "../../../utilities/Firebase";

export default class Media extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaData: [],
    };
  }
  componentDidMount() {
    let data = [];
    const self = this;
    new Promise((res, rej) => {
      firebase
        .firestore()
        .collection("walstrat/root/Events")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if(doc.data().published){
              data.push(doc.data());
              console.log("media data", doc.data());
            }
          });
          res(data);
        });
    }).then((res) => {
      self.setState({
        mediaData: res,
      });
    });
  }

  render() {
    return (
      <div id="media">
        {this.state.mediaData.length > 0 && (
          <div className="article__main container">
            <div className="layout-header">
              <LazyLoad>
                <Flip top>
                  <span className="subtitle">Media </span>
                </Flip>
              </LazyLoad>
            </div>
            <div className="row">
              <OwlCarousel
                className="owl-theme"
                items={3}
                id="owlBasicBar"
                loop={false}
                nav={false}
                autoplay={true}
                rewind={true}
                dots={true}
                responsiveClass={true}
                responsive={{
                  0: {
                    items: 1,
                    loop: false,
                    
                  },
                  600: {
                    items: 1,
                    loop: false,
                  },
                  700: {
                    items: 1,
                    loop: false,
                  },
                  1000: {
                    items: 3,
                    loop: false,
                  },
                }}
              >
                {this.state.mediaData.map((media, index) => (
                  <Zoom>
                    <div className="card card--small card--masonry card--news">
                      <div className="card__image">
                        <LazyLoad>
                          <Zoom>
                            <img src={media.image} height="330px" width="253px" alt="" />
                          </Zoom>
                        </LazyLoad>
                      </div>
                      <div className="card__content card__content--full">
                        <div className="card__date">
                          {new Date(media.eventDate).toLocaleDateString(
                            "en-IN"
                          )}
                        </div>
                        <LazyLoad>
                          <h3 className="card__heading">{media.eventName}</h3>
                        </LazyLoad>
                        <LazyLoad>
                          <div className="card__text">{media.eventDetails}</div>
                        </LazyLoad>
                        <div className="card__bottom">
                          <LazyLoad>
                            <a className="card__link" href={"/mediaPage"}>
                              Learn more
                              <svg width="20" height="20"></svg>
                            </a>
                          </LazyLoad>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                ))}
              </OwlCarousel>
            </div>
          </div>
        )}
      </div>
    );
  }
}
