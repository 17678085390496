import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { TabContext } from "@material-ui/lab";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../utilities/Firebase";
import { AdminFooter } from "./layouts";
import logo from "../assets/placeholders/WALSTRAT LOGO-.png";
import EditIcon from "@material-ui/icons/Edit";

// import filterCancelIcon from '../../assets/img/filter-cancel.png'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Snackbar,
  Typography,
  Tooltip,
  Zoom,
  withStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  TextField,
  TextareaAutosize,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
// import noDataImg from "../../assets/img/NoData.png";
import Page from "./page";
import Avatar from "material-ui/Avatar";
import moment from "moment";

// import ConfirmDialog from "../Register/ConfirmDialog";
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";

const db = firebase.firestore();
const collection = db.collection("walstrat/root/Events");
const storage = firebase.storage();

class MediaEvent2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: "1",
      title: "",
      eventName: "",
      eventDate: moment().format("YYYY-MM-DD"),
      eventDetails: "",
      posting_date: null,
      short: "",
      id: 1,
      author: "",
      docid: "",
      category_id: "",
      full: "",
      published: false,
      edit: false,
      postArray: [],
      image: null,
      url: "",
      isLogged: null,
      progress: 0,
      blogEdit: false,
    };
  }

  componentDidMount = () => {
    collection.orderBy("id").onSnapshot((querySnapshot) => {
      const posts = [];
      querySnapshot.forEach((doc) => {
        let postObj = doc.data();
        postObj.docid = doc.id;
        posts.push(postObj);
        console.log("doc data", doc.data());
      });
      setTimeout(() => {
        if (posts.length > 0) {
          this.setState(
            {
              postArray: posts,
              id: parseInt(posts[posts.length - 1].id.split("event_")[1]) + 1,
            },

            () => console.log(posts)
          );
        } else {
          this.setState({
            postArray: posts,
            id: 1,
          });
        }
      }, 100);
    });
  };

  handleChangeTab = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleImage = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(
        {
          image: image,
          imageSpin: true,
        },
        () => {
          this.handleUpload();
        }
      );
    }
  };

  handleUpload = () => {
    this.setState({
      imageSpin: true,
    });
    const { image } = this.state;
    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error) => {
        // error function ....
        console.log(error);
      },
      () => {
        storage
          .ref("images")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            this.setState({
              imageSpin: false,
              url: url,
            });
          });
      }
    );
  };

  publish = () => {
    if (this.validate()) {
      console.log(this.state);
      let post = {
        id: "event_" + this.state.id.toString(),
        posting_date: this.state.blogEdit
          ? this.state.posting_date
          : new Date().getTime(),
        updating_date: new Date().getTime(),
        published: true,
        eventName: this.state.eventName,
        eventDetails: this.state.eventDetails,
        eventDate: new Date(this.state.eventDate).getTime(),
        image: this.state.url,
      };
      this.pushToDB(post);
    }
  };

  saveAsDraft = () => {
    if (this.validate()) {
      let draft = {
        id: "event_" + this.state.id.toString(),
        posting_date: this.state.blogEdit
          ? this.state.posting_date
          : new Date().getTime(),
        updating_date: new Date().getTime(),
        published: false,
        eventName: this.state.eventName,
        eventDetails: this.state.eventDetails,
        eventDate: new Date(this.state.eventDate).getTime(),
        image: this.state.url,
      };
      this.pushToDB(draft);
    }
  };

  pushToDB = (event) => {
    if (!this.state.blogEdit) {
      collection.add(event).then(() => {
        console.log("Data from DB ", event);
        this.cleanInput();
      });
    } else {
      collection
        .doc(this.state.docid)
        .update(event)
        .then((res) => {
          console.log("res", res);
          this.cleanInput();
        });
    }
  };

  validate = () => {
    if (this.state.eventName === "") {
      alert("Please write event name.");
      return false;
    } else if (this.state.eventDetails === "") {
      alert("Please write event details!");
      return false;
    } else if (this.state.eventDate === "") {
      alert("Please select event date");
    } else {
      return true;
    }
  };

  cleanInput = () => {
    this.setState({
      eventName: "",
      eventDetails: "",
      url: "",
      image: null,
      eventDate: moment().format("YYYY-MM-DD"),
    });
  };

  editData = (key) => {
    this.state.postArray.map((post) => {
      if (post.id === key) {
        this.setState(
          {
            id: parseInt(key.split("event_")[1]),
            docid: post.docid,
            eventName: post.eventName,
            eventDetails: post.eventDetails,
            eventDate: moment(post.eventDate).format("YYYY-MM-DD"),
            isPublished: post.published ? post.published : false,
            posting_date: post.posting_date ? post.posting_date : null,
            tabValue: "1",
            blogEdit: true,
            url: post.image,
          },
          () => {
            console.log("edit data ", this.state);
          }
        );
      }
    });
  };

  goTOLogin = () => {
    localStorage.clear();
    window.location.href = "/admin";
  };

  render() {
    return (
      <div>
        <Page title="Enquiry Details">
          <Container style={{ marginTop: "110px" }}>
            <Box
              display="flex"
              style={{ alignItems: "center", margin: "10px" }}
            >
              <Box
                flexGrow={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <Typography variant="h5" style={{ fontFamily: "Sitka, serif" }}>
                  {this.state.tabValue == 1
                    ? this.state.blogEdit
                      ? "Edit Media Event"
                      : "Create Media Event"
                    : "Media List"}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Card>
              <CardContent>
                <TabContext value={this.state.tabValue}>
                  <AppBar
                    position="static"
                    style={{
                      color: "white",
                      background: "linear-gradient(60deg, #031163, #05716C)",
                    }}
                  >
                    <TabList
                      onChange={this.handleChangeTab}
                      aria-label="profiletab"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#fff",
                        }
                      }}
                    >
                      <Tab
                        label="Media"
                        value="1"
                        style={{ fontFamily: "Montserrat" ,fontSize : "medium" }}
                      />
                      <Tab
                        label="Media Event List"
                        value="2"
                        style={{ fontFamily: "Montserrat", fontSize : "medium" }}
                      />
                    </TabList>
                  </AppBar>
                  <TabPanel value="1">
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          className="inputadmin"
                          label="Event Name"
                          name="eventName"
                          id="eventName"
                          placeholder="Event Name"
                          onChange={this.handleChange}
                          required
                          value={this.state.eventName}
                          variant="standard"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="eventDate"
                          type="date"
                          placeholder="Event Date"
                          name="eventDate"
                          value={this.state.eventDate}
                          variant="standard"
                          fullWidth
                          onChange={this.handleChange}
                          className="inputadmin"
                          label="Event Date"
                          required
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="eventDetails"
                          rowsMin={3}
                          multiline
                          placeholder="Enter event details"
                          name="eventDetails"
                          value={this.state.eventDetails}
                          variant="standard"
                          fullWidth
                          onChange={this.handleChange}
                          className="inputadmin"
                          label="Event Details"
                          required
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <label htmlFor="images">
                          <input
                            // style={{ display: "none" }}
                            id="images"
                            name="images"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={this.handleImage}
                          />
                          {this.state.imageSpin ? (
                            <CircularProgress
                              color="secondary"
                              style={{ margin: "5px" }}
                              size={20}
                            />
                          ) : (
                            ""
                          )}
                        </label>
                        {this.state.url !== "" && (
                          <div>
                            <img
                              width="150px"
                              height="150px"
                              src={this.state.url}
                            />
                            <br></br>
                            <h6 style={{color : "red"}}>
                              If you upload new image, it override over previous
                              image
                            </h6>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <br />
                    <Button
                      variant="contained"
                      style={{ background: "linear-gradient(60deg, #031163, #05716C)", color: "#fff" }}
                      onClick={this.publish}
                    >
                      Publish
                    </Button>{" "}
                    &nbsp; &nbsp;
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ background: "linear-gradient(60deg, #031163, #05716C)", color: "#fff" },
                        this.state.isPublished ? { cursor: "not-allowed" } : {}
                      }
                      disabled={this.state.isPublished}
                      onClick={this.saveAsDraft}
                    >
                      Save Draft
                    </Button>
                  </TabPanel>
                  <TabPanel value="2">
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow
                                style={{ backgroundColor: "lightgrey" }}
                              >
                                <TableCell>Event Name</TableCell>
                                <TableCell align="left">Event Date</TableCell>
                                <TableCell align="left">
                                  Event Description
                                </TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left">Posting Date</TableCell>
                                <TableCell align="left">Update Date</TableCell>
                                <TableCell align="left">Edit</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.postArray.map((data, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {data.eventName}
                                  </TableCell>
                                  <TableCell align="left">
                                    {moment(data.eventDate).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.eventDetails
                                      ? data.eventDetails.length > 50
                                        ? data.eventDetails.slice(0, 50) +
                                          " ..."
                                        : data.eventDetails
                                      : ""}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {data.published ? "Published" : "Drafted"}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.posting_date
                                      ? new Date(
                                          data.posting_date
                                        ).toLocaleDateString("en-IN")
                                      : " - "}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.updating_date
                                      ? new Date(
                                          data.updating_date
                                        ).toLocaleDateString("en-IN")
                                      : " - "}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <EditIcon
                                      color={"inherit"}
                                      titleAccess={"Click to Edit"}
                                      onClick={() => this.editData(data.id)}
                                      variant="contained"
                                      size="sm"
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </TabPanel>
                
                </TabContext>
              </CardContent>
            </Card>

            {/* <Snackbar
              open={this.state.showSnackbar}
              autoHideDuration={4000}
              key="topcenter"
              onClose={this.closeSnackbar}
            >
              <Alert onClose={this.closeSnackbar} severity="success">
                {this.state.snackbarText}
              </Alert>
            </Snackbar> */}

            {/* <ConfirmDialog
              confirmDialog={this.state.confirmDialog}
              setConfirmDialog={this.setConfirmDialog}
            /> */}
          </Container>
        </Page>
      </div>
    );
  }
}
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
export default MediaEvent2;
