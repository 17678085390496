import React, { Component } from "react";
import "./slider.css";
import $ from "jquery";
// import SimpleImageSlider from "react-simple-image-slider";
// import { Slide } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import educationimg from "../../../assets/placeholders/educationslider.jpg";
import industryimg from "../../../assets/placeholders/industryslider.jpg";
import Aviationimg from "../../../assets/placeholders/Aviation.jpg";
import infrastructure1img from "../../../assets/placeholders/infrastructure1.jpg";
import healthcareimg from "../../../assets/placeholders/healthcareslider.jpg";
import Automobileimg from "../../../assets/placeholders/slider/auto mobiles.png";
import Researchimg from "../../../assets/placeholders/slider/research.jpg";
import Electricalimg from "../../../assets/placeholders/slider/Electrical.jpg";
import Financialimg from "../../../assets/placeholders/slider/Financial.jpg";
import FMCGimg from "../../../assets/placeholders/slider/event.png";
import Logisticsimg from "../../../assets/placeholders/slider/Logistics.jpg";
import Tourismimg from "../../../assets/placeholders/slider/tourism.jpg";
import Mediaimg from "../../../assets/placeholders/slider/media and entertainment.jpg";
import Metalsimg from "../../../assets/placeholders/slider/mining.jpg";
import Oilimg from "../../../assets/placeholders/slider/oil and gas.png";
import Outsourcingimg from "../../../assets/placeholders/slider/outsourcing.jpg";
import Pharmaimg from "../../../assets/placeholders/slider/pharma.jpg";
import Professionalimg from "../../../assets/placeholders/slider/Professional.jpg";
import Realimg from "../../../assets/placeholders/slider/real estate.jpg";
import Retailimg from "../../../assets/placeholders/slider/retail.jpg";
import Mergersimg from "../../../assets/placeholders/slider/mergers and acquisition.jpg";
import aiimg from "../../../assets/placeholders/slider/AI.jpg";
import manpowerimg from "../../../assets/placeholders/slider/manpower.jpg";
import consultancyimg from "../../../assets/placeholders/consultancy.jpg";
import marketingimg from "../../../assets/placeholders/marketing.png";
import projectimg from "../../../assets/placeholders/slider/project.png";
import productimg from "../../../assets/placeholders/slider/product.png";

import Videojs from "../../videojs";
import videoJsOptions from "../../videojs-option";
import LazyLoad from "react-lazy-load";
// import * as SliderLazy from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import homevideo from '../../../assets/placeholders/homevideo-c.mp4';
// import adminvideo from '../../../assets/placeholders/adminvideo.mp4';
//import aviationvideo from '../../../assets/placeholders/aviation.m4v';
import aviationicon from "../../../assets/placeholders/icon/plane.png";

import Aviationgif from "../Services2/aviation";

export default class Slider extends Component {
  componentDidMount() {
    /*
  More about this function - 
  https://codepen.io/rachsmith/post/animation-tip-lerp
*/

    function lerp({ x, y }, { x: targetX, y: targetY }) {
      const fraction = 0.1;

      x += (targetX - x) * fraction;
      y += (targetY - y) * fraction;

      return { x, y };
    }

    class InnerSlider {
      constructor(el) {
        const imgClass = (this.IMG_CLASS = "slider__images-item");
        const textClass = (this.TEXT_CLASS = "slider__text-item");
        const activeImgClass = (this.ACTIVE_IMG_CLASS = `${imgClass}--active`);
        const activeTextClass =
          (this.ACTIVE_TEXT_CLASS = `${textClass}--active`);

        this.el = el;
        this.contentEl1 = document.getElementById("slider-content1")
          ? document.getElementById("slider-content1")
          : "";
        this.contentEl2 = document.getElementById("slider-content2")
          ? document.getElementById("slider-content2")
          : "";
        this.contentEl3 = document.getElementById("slider-content3")
          ? document.getElementById("slider-content3")
          : "";
        this.contentEl4 = document.getElementById("slider-content4")
          ? document.getElementById("slider-content4")
          : "";
        this.contentEl5 = document.getElementById("slider-content5")
          ? document.getElementById("slider-content5")
          : "";
        this.contentEl6 = document.getElementById("slider-content6")
          ? document.getElementById("slider-content6")
          : "";
        this.contentEl7 = document.getElementById("slider-content7")
          ? document.getElementById("slider-content7")
          : "";
        this.contentEl8 = document.getElementById("slider-content8")
          ? document.getElementById("slider-content8")
          : "";
        // this.contentEl9 = document.getElementById("slider-content9")
        //   ? document.getElementById("slider-content9")
        //   : "";
        this.contentEl10 = document.getElementById("slider-content10")
          ? document.getElementById("slider-content10")
          : "";
        this.contentEl11 = document.getElementById("slider-content11")
          ? document.getElementById("slider-content11")
          : "";
        this.contentEl12 = document.getElementById("slider-content12")
          ? document.getElementById("slider-content12")
          : "";
        this.contentEl13 = document.getElementById("slider-content13")
          ? document.getElementById("slider-content13")
          : "";
        // this.contentEl14 = document.getElementById("slider-content14")
        //   ? document.getElementById("slider-content14")
        //   : "";
        this.contentEl15 = document.getElementById("slider-content15")
          ? document.getElementById("slider-content15")
          : "";
        this.contentEl16 = document.getElementById("slider-content16")
          ? document.getElementById("slider-content16")
          : "";
        this.contentEl17 = document.getElementById("slider-content17")
          ? document.getElementById("slider-content17")
          : "";
        this.contentEl18 = document.getElementById("slider-content18")
          ? document.getElementById("slider-content18")
          : "";
        this.contentEl19 = document.getElementById("slider-content19")
          ? document.getElementById("slider-content19")
          : "";
        this.contentEl20 = document.getElementById("slider-content20")
          ? document.getElementById("slider-content20")
          : "";
        this.contentEl21 = document.getElementById("slider-content21")
          ? document.getElementById("slider-content21")
          : "";
        this.contentEl22 = document.getElementById("slider-content22")
          ? document.getElementById("slider-content22")
          : "";
        this.contentEl23 = document.getElementById("slider-content23")
          ? document.getElementById("slider-content23")
          : "";
        this.contentEl24 = document.getElementById("slider-content24")
          ? document.getElementById("slider-content24")
          : "";
        this.contentEl25 = document.getElementById("slider-content25")
          ? document.getElementById("slider-content25")
          : "";

        this.onMouseMove = this.onMouseMove.bind(this);

        // taking advantage of the live nature of 'getElement...' methods
        this.activeImg = activeImgClass
          ? el.getElementsByClassName(activeImgClass)
          : "";
        this.activeText = el.getElementsByClassName(activeTextClass)
          ? el.getElementsByClassName(activeTextClass)
          : "";
        this.images = el.getElementsByTagName("img")
          ? el.getElementsByTagName("img")
          : "";

        // document.getElementById('slider-dots')
        //   .addEventListener('click', this.onDotClick.bind(this));

        // document.getElementById('left')
        //   .addEventListener('click', this.prev.bind(this));

        // document.getElementById('right')
        //   .addEventListener('click', this.next.bind(this));

        window.addEventListener("resize", this.onResize.bind(this));

        this.onResize();

        this.length = this.images.length;
        this.lastX = this.lastY = this.targetX = this.targetY = 0;
      }
      onResize() {
        const htmlStyles = getComputedStyle(document.documentElement);
        const mobileBreakpoint = htmlStyles.getPropertyValue("--mobile-bkp");

        const isMobile = (this.isMobile = matchMedia(
          `only screen and (max-width: ${mobileBreakpoint})`
        ).matches);

        this.halfWidth = window.innerWidth / 2;
        this.halfHeight = window.innerHeight / 2;
        this.zDistance = htmlStyles.getPropertyValue("--z-distance");

        if (!isMobile && !this.mouseWatched) {
          this.mouseWatched = true;
          this.el.addEventListener("mousemove", this.onMouseMove);
          this.el.style.setProperty(
            "--img-prev",
            `url(${
              this.images[+this.activeImg[0].dataset.id - 1] &&
              this.images[+this.activeImg[0].dataset.id - 1].src
            })`
          );
          this.contentEl1.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl2.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl3.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl4.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl5.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl6.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl7.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl8.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          // this.contentEl9.style.setProperty(
          //   "transform",
          //   `translateZ(${this.zDistance})`
          // );
          this.contentEl10.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl11.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl12.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl13.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          // this.contentEl14.style.setProperty(
          //   "transform",
          //   `translateZ(${this.zDistance})`
          // );
          this.contentEl15.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl16.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl17.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl18.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl19.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl20.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl21.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl22.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl23.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl24.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
          this.contentEl25.style.setProperty(
            "transform",
            `translateZ(${this.zDistance})`
          );
        } else if (isMobile && this.mouseWatched) {
          this.mouseWatched = false;
          this.el.removeEventListener("mousemove", this.onMouseMove);
          this.contentEl1.style.setProperty("transform", "none");
          this.contentEl2.style.setProperty("transform", "none");
          this.contentEl3.style.setProperty("transform", "none");
          this.contentEl4.style.setProperty("transform", "none");
          this.contentEl5.style.setProperty("transform", "none");
          this.contentEl6.style.setProperty("transform", "none");
          this.contentEl7.style.setProperty("transform", "none");
          this.contentEl8.style.setProperty("transform", "none");
          // this.contentEl9.style.setProperty("transform", "none");
          this.contentEl10.style.setProperty("transform", "none");
          this.contentEl12.style.setProperty("transform", "none");
          this.contentEl13.style.setProperty("transform", "none");
          // this.contentEl14.style.setProperty("transform", "none");
          this.contentEl15.style.setProperty("transform", "none");
          this.contentEl16.style.setProperty("transform", "none");
          this.contentEl17.style.setProperty("transform", "none");
          this.contentEl18.style.setProperty("transform", "none");
          this.contentEl19.style.setProperty("transform", "none");
          this.contentEl20.style.setProperty("transform", "none");
          this.contentEl21.style.setProperty("transform", "none");
          this.contentEl22.style.setProperty("transform", "none");
          this.contentEl23.style.setProperty("transform", "none");
          this.contentEl24.style.setProperty("transform", "none");
          this.contentEl25.style.setProperty("transform", "none");
        }
      }
      getMouseCoefficients({ pageX, pageY } = {}) {
        const halfWidth = this.halfWidth;
        const halfHeight = this.halfHeight;
        const xCoeff = ((pageX || this.targetX) - halfWidth) / halfWidth;
        const yCoeff = (halfHeight - (pageY || this.targetY)) / halfHeight;

        return { xCoeff, yCoeff };
      }
      onMouseMove({ pageX, pageY }) {
        this.targetX = pageX;
        this.targetY = pageY;

        if (!this.animationRunning) {
          this.animationRunning = true;
          this.runAnimation();
        }
      }
      runAnimation() {
        if (this.animationStopped) {
          this.animationRunning = false;
          return;
        }

        const maxX = 10;
        const maxY = 10;

        const newPos = lerp(
          {
            x: this.lastX,
            y: this.lastY,
          },
          {
            x: this.targetX,
            y: this.targetY,
          }
        );

        const { xCoeff, yCoeff } = this.getMouseCoefficients({
          pageX: newPos.x,
          pageY: newPos.y,
        });

        this.lastX = newPos.x;
        this.lastY = newPos.y;

        this.positionImage({ xCoeff, yCoeff });

        this.contentEl1.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl2.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl3.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl4.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl5.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl6.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl7.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl8.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        // this.contentEl9.style.setProperty(
        //   "transform",
        //   `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${maxX * xCoeff
        //   }deg)`
        // );
        this.contentEl10.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl11.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl12.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl13.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        // this.contentEl14.style.setProperty(
        //   "transform",
        //   `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${maxX * xCoeff
        //   }deg)`
        // );
        this.contentEl15.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl16.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl17.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl18.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl19.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl20.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl21.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl22.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl23.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl24.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );
        this.contentEl25.style.setProperty(
          "transform",
          `translateZ(${this.zDistance})rotateX(${maxY * yCoeff}deg)rotateY(${
            maxX * xCoeff
          }deg)`
        );

        if (this.reachedFinalPoint) {
          this.animationRunning = false;
        } else {
          requestAnimationFrame(this.runAnimation.bind(this));
        }
      }
      get reachedFinalPoint() {
        const lastX = ~~this.lastX;
        const lastY = ~~this.lastY;
        const targetX = this.targetX;
        const targetY = this.targetY;

        return (
          (lastX == targetX || lastX - 1 == targetX || lastX + 1 == targetX) &&
          (lastY == targetY || lastY - 1 == targetY || lastY + 1 == targetY)
        );
      }
      positionImage({ xCoeff, yCoeff }) {
        const maxImgOffset = 1;
        const currentImage = this.activeImg[0].children[0];

        currentImage.style.setProperty(
          "transform",
          `
      translateX(${maxImgOffset * -xCoeff}em)
      translateY(${maxImgOffset * yCoeff}em)
      `
        );
      }
      // onDotClick({ target }) {
      //   if (this.inTransit) return;

      //   const dot = target.closest('.slider__nav-dot');

      //   if (!dot) return;

      //   const nextId = dot.dataset.id;
      //   const currentId = this.activeImg[0].dataset.id;

      //   if (currentId == nextId) return;

      //   this.startTransition(nextId);
      // }
      transitionItem(nextId) {
        function onImageTransitionEnd(e) {
          e.stopPropagation();

          nextImg.classList.remove(transitClass);

          self.inTransit = false;

          this.className = imgClass;
          this.removeEventListener("transitionend", onImageTransitionEnd);
        }

        const self = this;
        const el = this.el;
        const currentImg = this.activeImg[0];
        const currentId = currentImg.dataset.id;
        const imgClass = this.IMG_CLASS;
        const textClass = this.TEXT_CLASS;
        const activeImgClass = this.ACTIVE_IMG_CLASS;
        const activeTextClass = this.ACTIVE_TEXT_CLASS;
        const subActiveClass = `${imgClass}--subactive`;
        const transitClass = `${imgClass}--transit`;
        const nextImg = el.querySelector(`.${imgClass}[data-id='${nextId}']`);
        const nextText = el.querySelector(`.${textClass}[data-id='${nextId}']`);

        let outClass = "";
        let inClass = "";

        this.animationStopped = true;

        nextText.classList.add(activeTextClass);

        el.style.setProperty("--from-left", nextId);

        currentImg.classList.remove(activeImgClass);
        currentImg.classList.add(subActiveClass);

        if (currentId < nextId) {
          outClass = `${imgClass}--next`;
          inClass = `${imgClass}--prev`;
        } else {
          outClass = `${imgClass}--prev`;
          inClass = `${imgClass}--next`;
        }

        nextImg.classList.add(outClass);

        requestAnimationFrame(() => {
          nextImg.classList.add(transitClass, activeImgClass);
          nextImg.classList.remove(outClass);

          this.animationStopped = false;
          this.positionImage(this.getMouseCoefficients());

          currentImg.classList.add(transitClass, inClass);
          currentImg.addEventListener("transitionend", onImageTransitionEnd);
        });

        if (!this.isMobile) this.switchBackgroundImage(nextId);
      }
      startTransition(nextId) {
        function onTextTransitionEnd(e) {
          if (!e.pseudoElement) {
            e.stopPropagation();

            requestAnimationFrame(() => {
              self.transitionItem(nextId);
            });

            this.removeEventListener("transitionend", onTextTransitionEnd);
          }
        }

        if (this.inTransit) return;

        const activeText = this.activeText[0];
        const backwardsClass = `${this.TEXT_CLASS}--backwards`;
        const self = this;

        this.inTransit = true;

        activeText.classList.add(backwardsClass);
        activeText.classList.remove(this.ACTIVE_TEXT_CLASS);
        activeText.addEventListener("transitionend", onTextTransitionEnd);

        requestAnimationFrame(() => {
          activeText.classList.remove(backwardsClass);
        });
      }
      // next() {
      //   if (this.inTransit) return;

      //   let nextId = +this.activeImg[0].dataset.id + 1;

      //   if (nextId > this.length)
      //     nextId = 1;

      //   this.startTransition(nextId);
      // }
      // prev() {
      //   if (this.inTransit) return;

      //   let nextId = +this.activeImg[0].dataset.id - 1;

      //   if (nextId < 1)
      //     nextId = this.length;

      //   this.startTransition(nextId);
      // }
      switchBackgroundImage(nextId) {
        function onBackgroundTransitionEnd(e) {
          if (e.target === this) {
            this.style.setProperty("--img-prev", imageUrl);
            this.classList.remove(bgClass);
            this.removeEventListener(
              "transitionend",
              onBackgroundTransitionEnd
            );
          }
        }

        const bgClass = "slider--bg-next";
        const el = this.el;
        const imageUrl = `url(${this.images[+nextId - 1].src})`;

        el.style.setProperty("--img-next", imageUrl);
        el.addEventListener("transitionend", onBackgroundTransitionEnd);
        el.classList.add(bgClass);
      }
    }

    const sliderEl1 = document.getElementById("slider1");
    const sliderEl2 = document.getElementById("slider2");
    const sliderEl3 = document.getElementById("slider3");
    const sliderEl4 = document.getElementById("slider4");
    const sliderEl5 = document.getElementById("slider5");
    const sliderEl6 = document.getElementById("slider6");
    const sliderEl7 = document.getElementById("slider7");
    const sliderEl8 = document.getElementById("slider8");
    // const sliderEl9 = document.getElementById("slider9");
    const sliderEl10 = document.getElementById("slider10");
    const sliderEl11 = document.getElementById("slider11");
    const sliderEl12 = document.getElementById("slider12");
    const sliderEl13 = document.getElementById("slider13");
    // const sliderEl14 = document.getElementById("slider14");
    const sliderEl15 = document.getElementById("slider15");
    const sliderEl16 = document.getElementById("slider16");
    const sliderEl17 = document.getElementById("slider17");
    const sliderEl18 = document.getElementById("slider18");
    const sliderEl19 = document.getElementById("slider19");
    const sliderEl20 = document.getElementById("slider20");
    const sliderEl21 = document.getElementById("slider21");
    const sliderEl22 = document.getElementById("slider22");
    const sliderEl23 = document.getElementById("slider23");
    const sliderEl24 = document.getElementById("slider24");
    const sliderEl25 = document.getElementById("slider25");

    const slider1 = new InnerSlider(sliderEl1);
    const slider2 = new InnerSlider(sliderEl2);
    const slider3 = new InnerSlider(sliderEl3);
    const slider4 = new InnerSlider(sliderEl4);
    const slider5 = new InnerSlider(sliderEl5);
    const slider6 = new InnerSlider(sliderEl6);
    const slider7 = new InnerSlider(sliderEl7);
    const slider8 = new InnerSlider(sliderEl8);
    // const slider9 = new InnerSlider(sliderEl9);
    const slider10 = new InnerSlider(sliderEl10);
    const slider11 = new InnerSlider(sliderEl11);
    const slider12 = new InnerSlider(sliderEl12);
    const slider13 = new InnerSlider(sliderEl13);
    // const slider14 = new InnerSlider(sliderEl14);
    const slider15 = new InnerSlider(sliderEl15);
    const slider16 = new InnerSlider(sliderEl16);
    const slider17 = new InnerSlider(sliderEl17);
    const slider18 = new InnerSlider(sliderEl18);
    const slider19 = new InnerSlider(sliderEl19);
    const slider20 = new InnerSlider(sliderEl20);
    const slider21 = new InnerSlider(sliderEl21);
    const slider22 = new InnerSlider(sliderEl22);
    const slider23 = new InnerSlider(sliderEl23);
    const slider24 = new InnerSlider(sliderEl24);
    const slider25 = new InnerSlider(sliderEl25);

    // ------------------ Demo stuff ------------------------ //

    let timer = 0;

    // function autoSlide() {
    //   requestAnimationFrame(() => {
    //     slider.next();
    //   });

    //   timer = setTimeout(autoSlide, 5000);
    // }

    function stopAutoSlide() {
      clearTimeout(timer);

      this.removeEventListener("touchstart", stopAutoSlide);
      this.removeEventListener("mousemove", stopAutoSlide);
    }

    sliderEl1.addEventListener("mousemove", stopAutoSlide);
    sliderEl1.addEventListener("touchstart", stopAutoSlide);

    sliderEl2.addEventListener("mousemove", stopAutoSlide);
    sliderEl2.addEventListener("touchstart", stopAutoSlide);

    sliderEl3.addEventListener("mousemove", stopAutoSlide);
    sliderEl3.addEventListener("touchstart", stopAutoSlide);

    sliderEl4.addEventListener("mousemove", stopAutoSlide);
    sliderEl4.addEventListener("touchstart", stopAutoSlide);

    sliderEl5.addEventListener("mousemove", stopAutoSlide);
    sliderEl5.addEventListener("touchstart", stopAutoSlide);

    sliderEl6.addEventListener("mousemove", stopAutoSlide);
    sliderEl6.addEventListener("touchstart", stopAutoSlide);

    sliderEl7.addEventListener("mousemove", stopAutoSlide);
    sliderEl7.addEventListener("touchstart", stopAutoSlide);

    sliderEl8.addEventListener("mousemove", stopAutoSlide);
    sliderEl8.addEventListener("touchstart", stopAutoSlide);

    // sliderEl9.addEventListener("mousemove", stopAutoSlide);
    // sliderEl9.addEventListener("touchstart", stopAutoSlide);

    sliderEl10.addEventListener("mousemove", stopAutoSlide);
    sliderEl10.addEventListener("touchstart", stopAutoSlide);

    sliderEl11.addEventListener("mousemove", stopAutoSlide);
    sliderEl12.addEventListener("touchstart", stopAutoSlide);

    sliderEl13.addEventListener("mousemove", stopAutoSlide);
    sliderEl13.addEventListener("touchstart", stopAutoSlide);

    // sliderEl14.addEventListener("mousemove", stopAutoSlide);
    // sliderEl14.addEventListener("touchstart", stopAutoSlide);

    sliderEl15.addEventListener("mousemove", stopAutoSlide);
    sliderEl15.addEventListener("touchstart", stopAutoSlide);

    sliderEl16.addEventListener("mousemove", stopAutoSlide);
    sliderEl16.addEventListener("touchstart", stopAutoSlide);

    sliderEl17.addEventListener("mousemove", stopAutoSlide);
    sliderEl17.addEventListener("touchstart", stopAutoSlide);

    sliderEl18.addEventListener("mousemove", stopAutoSlide);
    sliderEl18.addEventListener("touchstart", stopAutoSlide);

    sliderEl19.addEventListener("mousemove", stopAutoSlide);
    sliderEl19.addEventListener("touchstart", stopAutoSlide);

    sliderEl20.addEventListener("mousemove", stopAutoSlide);
    sliderEl20.addEventListener("touchstart", stopAutoSlide);

    sliderEl21.addEventListener("mousemove", stopAutoSlide);
    sliderEl21.addEventListener("touchstart", stopAutoSlide);

    sliderEl22.addEventListener("mousemove", stopAutoSlide);
    sliderEl22.addEventListener("touchstart", stopAutoSlide);

    sliderEl23.addEventListener("mousemove", stopAutoSlide);
    sliderEl23.addEventListener("touchstart", stopAutoSlide);

    sliderEl24.addEventListener("mousemove", stopAutoSlide);
    sliderEl24.addEventListener("touchstart", stopAutoSlide);

    sliderEl25.addEventListener("mousemove", stopAutoSlide);
    sliderEl25.addEventListener("touchstart", stopAutoSlide);

    // timer = setTimeout(autoSlide, 2000);
  }

  hidevideo() {
    document.getElementById("video-view").classList.add("animationvideo");
    // document.getElementById("slider").style.display="flex";
  }
  hidevideo1() {
    document.getElementById("video-view1").classList.add("animationvideo");
    // document.getElementById("slider").style.display="flex";
  }
  render() {
    const isAnimate = 0;

    return (
      // <SliderLazy {...settings}>
      <div id="home">
        {/* <LazyLoad> */}
        <OwlCarousel
          items="1"
          className="owl-theme"
          nav={true}
          navText = {["<i class='fa fa-chevron-left fa-4x' aria-hidden='true'></i>","<i class='fa fa-chevron-right fa-4x' aria-hidden='true'></i>"]}
          onChanged={(e) => {
            // first video text animation
            if (e.item.index == 0) {
              let ani1 = document.getElementById("video0H11");
              ani1 && (ani1.className = "videotext videotextanimation1 ");

              let ani2 = document.getElementById("video0H12");
              ani2 && (ani2.className = "videotext videotextanimation2 ");

              let ani3 = document.getElementById("VideoText0");
              ani3 &&
                (ani3.className = "positionvideotext videotextanimation3");
            } else {
              let ani1 = document.getElementById("video0H11");
              ani1 && (ani1.className = "videotext");

              let ani2 = document.getElementById("video0H12");
              ani2 && (ani2.className = "videotext");
              let ani3 = document.getElementById("VideoText0");
              ani3 && (ani3.className = "positionvideotext");
            }
            // second video text animatiob
            if (e.item.index == 1) {
              let ani1 = document.getElementById("video1H11");
              ani1 && (ani1.className = "videotext videotextanimation1 ");

              let ani2 = document.getElementById("video1H12");
              ani2 && (ani2.className = "videotext videotextanimation2 ");

              let ani3 = document.getElementById("VideoText");
              ani3 &&
                (ani3.className = "positionvideotext videotextanimation3 ");
            } else {
              let ani1 = document.getElementById("video1H11");
              ani1 && (ani1.className = "videotext");

              let ani2 = document.getElementById("video1H12");
              ani2 && (ani2.className = "videotext");

              let ani3 = document.getElementById("VideoText");
              ani3 && (ani3.className = "positionvideotext");
            }
            // plane animation
            if (e.item.index == 4) {
              let ani1 = document.getElementById("plane1");
              ani1 && (ani1.className = "iconmove1 plane-animation ");
            } else {
              let ani1 = document.getElementById("plane1");
              ani1 && (ani1.className = "iconmove1");
            }
          }}
        >
          {/* slider one */}

          <div id="video-view">
            <video
              className="video-js"
              autoPlay
              loop
              muted
              style={{ width: "100%", height: "100%" }}
            >
              <source
                src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/homevideo-c-c.mp4"
                type="video/mp4"
              />
            </video>
            {/* <SliderLazy {...settings}> */}
            {/* <Videojs
              {...videoJsOptions}
              src={
                "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/homevideo-c-c.mp4"
              }
            /> */}
            {/* <LazyLoad> */}
            <h1 id="video0H11" className="videotext videotextanimation1">
              <span>Holistic </span>
            </h1>
            {/* </LazyLoad> */}
            {/* <LazyLoad> */}
            <h1 id="video0H12" className="videotext videotextanimation2">
              <span> Solutions</span>
            </h1>
            {/* </LazyLoad> */}
            <div
              className="positionvideotext videotextanimation3"
              id="VideoText0"
            >
              {/* <LazyLoad> */}
              <p>WALSTRAT SERVICES PVT LTD.</p>
              {/* </LazyLoad> */}
            </div>
          </div>
          {/* slider second */}
          <div id="video-view1">
            <video
              className="video-js"
              autoPlay
              loop
              muted
              style={{ width: "100%", height: "100%" }}
            >
              <source
                src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/adminvideo-c.mp4"
                type="video/mp4"
              />
            </video>
            {/* <LazyLoad offsetVertical={0}> */}

            {/* <Videojs
              {...videoJsOptions}
              src={
                "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/adminvideo-c.mp4"
              }
            /> */}
            {/* </LazyLoad> */}
            {/* <h1 className="videotext videotextanimation">
              From Seasoned experts
          </h1> */}
            <h1 id="video1H11" className="videotext videotextanimation1 ">
              walnut
            </h1>
            <h1 id="video1H12" className="videotext videotextanimation2">
              <span> strategies</span>
            </h1>
            <div
              className="positionvideotext videotextanimation3"
              id="VideoText"
            >
              <p>Newer ideas for new beginnings.</p>
            </div>
          </div>
          {/* <!-- Marketing --> */}
          <div className="slider" id="slider25" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content25">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={marketingimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Marketing</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>End to End Marketing Solutions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Consultancy --> */}
          <div className="slider" id="slider24" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content24">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={consultancyimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Consultancy</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>Our expert solutions help you take better decisions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Aviation and Defense --> */}
          <div>
            <div className="iconmove1 plane-animation" id="plane1" />
            <div className="slider" id="slider2" style={{ marginTop: "100px" }}>
              {/* <Aviationgif></Aviationgif> */}
              <div className="slider__content" id="slider-content2">
                <div className="slider__images">
                  <div
                    className="slider__images-item slider__images-item--active"
                    data-id="1"
                  >
                    {/* <LazyLoad>  */}
                    <img src={Aviationimg} />
                    {/* </LazyLoad>  */}
                  </div>
                </div>
                <div className="slider__text">
                  <div
                    className="slider__text-item slider__text-item--active"
                    data-id="1"
                  >
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor1">Aviation and Defense</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>
                        Acquire Private Air Vehicles and build up Manufacturing
                        Units"
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Automobile & Auto Components --> */}

          <div className="slider" id="slider6" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content6">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={Automobileimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">
                      Automobile & Auto Components
                    </h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Grow your Business with our superior Strategies and Tech
                      Expertise
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Artificial Intelligence --> */}
          <div className="slider" id="slider22" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content22">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={aiimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Artificial Intelligence</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Leverage the power of new-age technologies and enrich your
                      business DNA
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Education --> */}
          <div className="slider" id="slider3" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content3">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={educationimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Education</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Pace towards success with new learning patterns and
                      innovations
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Electrical & Electronics --> */}

          <div className="slider" id="slider8" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content8">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={Electricalimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Electrical & Electronics</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>Advanced Technologies with proficient Personnel</p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Financial services --> */}

          {/* <div className="slider" id="slider9" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content9">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  <img src={Financialimg} />
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Financial services</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Step into an advanced field of Fintech
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <!-- FMCG --> */}

          <div className="slider" id="slider10" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content10">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={FMCGimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Events and Networking </h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>Right Networking Opportunities, for the best </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Healthcare --> */}
          <div className="slider" id="slider1" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content1">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={healthcareimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Healthcare</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>Holistic Healthcare Solutions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Industrial --> */}

          <div className="slider" id="slider4" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content4">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={industryimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Industrial</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      End to End Turnkey Project Management and Industrial
                      Knowhow
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Infrastructure --> */}

          <div className="slider" id="slider5" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content5">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={infrastructure1img} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Infrastructure</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Cost-effective strategies with superior standards and
                      designs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Logistics --> */}

          <div className="slider" id="slider11" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content11">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={Logisticsimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Logistics</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Understanding your needs and suggestions from Experts of
                      the Market in many fields
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Manpower , Leaders and Employment --> */}
          <div className="slider" id="slider23" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content23">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={manpowerimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">
                      Manpower , Leaders and Employment
                    </h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>Right Tech , Right People, Growth Multiplied</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Media & Entertainment --> */}

          <div className="slider" id="slider13" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content13">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={Mediaimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Media & Entertainment</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Coproduce the finest experiences with our insights and
                      expertise{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Metals, Minerals & Mining --> */}

          {/* <div className="slider" id="slider14" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content14">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  <img src={Metalsimg} />
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Metals, Minerals & Mining</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Erudite researchers with different Strategies{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <!-- Mergers and Acquisitions --> */}

          <div className="slider" id="slider21" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content21">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={Mergersimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Mergers and Acquisitions</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>Grow exponentially with crossword Connects</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Oil & Gas --> */}

          <div className="slider" id="slider15" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content15">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={Oilimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Oil & Gas</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Waste Management and Maintenance Infrastructure
                      development
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Outsourcing & Offshoring --> */}

          <div className="slider" id="slider16" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content16">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={Outsourcingimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Outsourcing & Offshoring</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>Directions and support to spread your networks</p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Pharma, Lifesciences, Devices & Diagnostics --> */}

          <div className="slider" id="slider17" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content17">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={Pharmaimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">
                      Pharma, Lifesciences, Devices & Diagnostics
                    </h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Fruitful Strategies/Innovations and upright research to be
                      with evolving market
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Professional Services --> */}

          <div className="slider" id="slider18" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content18">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={Professionalimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Professional Services</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Solutions for All Professional Services under one roof
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Research and Development --> */}

          <div className="slider" id="slider7" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content7">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={Researchimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Research and Development</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Rooted Research across Sectors, Leading you towards
                      Innovation
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Products --> */}

          <div className="slider" id="slider19" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content19">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  <img src={productimg} />
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Products</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>Our Catalogue</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Projects --> */}

          <div className="slider" id="slider20" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content20">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  <img src={projectimg} />
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Projects</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Our Past Projects
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Real Estate --> */}

          {/* <div className="slider" id="slider19" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content19">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  <img src={Realimg} />
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Real Estate</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Customized Solutions for all your needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <!-- Retail --> */}

          {/* <div className="slider" id="slider20" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content20">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  <img src={Retailimg} />
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Retail</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Effective strategies and solutions to navigate Markets
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <!-- Tourism --> */}

          <div className="slider" id="slider12" style={{ marginTop: "100px" }}>
            <div className="slider__content" id="slider-content12">
              <div className="slider__images">
                <div
                  className="slider__images-item slider__images-item--active"
                  data-id="1"
                >
                  {/* <LazyLoad>  */}
                  <img src={Tourismimg} />
                  {/* </LazyLoad>  */}
                </div>
              </div>
              <div className="slider__text">
                <div
                  className="slider__text-item slider__text-item--active"
                  data-id="1"
                >
                  <div className="slider__text-item-head">
                    <h3 className="slidercolor1">Tourism</h3>
                  </div>
                  <div className="slider__text-item-info">
                    <p>
                      Tap in the Ever Growing Field of Tourism and Expand to
                      newer Markets
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 
          <div style={{ display: 'none' }}>
            <div className="slider" style={{ marginTop: '100px' }} >
              <div className="slider__content" >
                <div className="slider__images">
                  <div className="slider__images-item slider__images-item--active" data-id="1"><img src={healthcareimg} /></div>
                  <div className="slider__images-item" data-id="2"><img src={Aviationimg} /></div>
                  <div className="slider__images-item" data-id="3"><img src={educationimg} /></div>
                  <div className="slider__images-item" data-id="4"><img src={industryimg} /></div>
                  <div className="slider__images-item" data-id="5"><img src={infrastructure1img} /></div>
                  <div className="slider__images-item" data-id="6"><img src={Automobileimg} /></div>
                  <div className="slider__images-item" data-id="7"><img src={Researchimg} /></div>
                  <div className="slider__images-item" data-id="8"><img src={Electricalimg} /></div>
                  <div className="slider__images-item" data-id="9"><img src={Financialimg} /></div>
                  <div className="slider__images-item" data-id="10"><img src={FMCGimg} /></div>
                  <div className="slider__images-item" data-id="11"><img src={Logisticsimg} /></div>
                  <div className="slider__images-item" data-id="12"><img src={Tourismimg} /></div>
                  <div className="slider__images-item" data-id="13"><img src={Mediaimg} /></div>
                  <div className="slider__images-item" data-id="14"><img src={Metalsimg} /></div>
                  <div className="slider__images-item" data-id="15"><img src={Oilimg} /></div>
                  <div className="slider__images-item" data-id="16"><img src={Outsourcingimg} /></div>
                  <div className="slider__images-item" data-id="17"><img src={Pharmaimg} /></div>
                  <div className="slider__images-item" data-id="18"><img src={Professionalimg} /></div>
                  <div className="slider__images-item" data-id="19"><img src={Realimg} /></div>
                  <div className="slider__images-item" data-id="20"><img src={Retailimg} /></div>
                  <div className="slider__images-item" data-id="21"><img src={Mergersimg} /></div>

                </div>
                <div className="slider__text">
                  <div className="slider__text-item slider__text-item--active" data-id="1">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor1">Healthcare</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>In a rapidly evolving Healthcare Landscape, We offer Training and Skill Development Programmes.</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="2">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor2">Aviation and Defense</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>Explore how our services help you set up manufacturing units and acquire private air travel vehicles. </p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="3">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor3">Education</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>With our expert services, we help you create an atmosphere that promotes talent and nurtures creativity.</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="4">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor4">Industrial</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>Meet International Quality Standards and showcase your products across Markets. </p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="5">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor5">Infrastructure</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>End-to-End planning services for your new ventures, from experienced and expert Professionals.</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="6">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor6">Automobile & Auto Components</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>One of the fastest evolving markets in India, with newer technologies introduced almost daily.
</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="7">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor7"> Research and Development</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>One of our strongest suits, we offer market research services and help you make informed decisions and plan accordingly.</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="8">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor8">Electrical & Electronics</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>Set up Manufacturing Units, explore possibilities of Import and Export and more with our seasoned Professional Services.
</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="9">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor9">Financial services
</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>In Increasingly Unstable Markets, we help our clients manage and invest their resources efficiently, so as to harness the maximum ROI.
</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="10">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor10">FMCG</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>We assist clients in setting up chain stores, marketing initiatives and market research, Our expert services give you an edge over the Competition.
</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="11">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor11">Logistics</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>Globalisation has resulted in a big boom in the Logistics and Management Industry. Explore how you can harness the field and its offerings to its true potential.
</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="12">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor12">Tourism</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>Medical Tourism, Business Tourism and everything in Between - Get expert Solutions and Guidance navigating International Markets.
</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="13">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor13">Media & Entertainment
</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>Stay ahead of the Curve and explore trends in marketable ways, keeping in mind the goals and the principles of the Company. </p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="14">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor14">Metals, Minerals & Mining
</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>Explore the opportunities in the Raw Materials and Mining Industry with our Expertise and Support. </p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="15">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor15">Oil & Gas
</h3>


                    </div>
                    <div className="slider__text-item-info">
                      <p>Explore Opportunities in the Energy Sector, Explore newer markets and horizons with our expertise.
</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="16">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor16">Outsourcing & Offshoring
</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>With Increasing Globalisation, Explore Outsourcing and Offshoring Opportunities to reduce costs and improve turnaround times.</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="17">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor17">Pharma, Lifesciences, Devices & Diagnostics</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>Explore the potential in Life Sciences, Pharmaceuticals and Related Research for long term solutions.
”</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="18">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor18">Professional Services
</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>Access the best of Professional Services - Legal, Auditory and other Auxiliary Services with us.
”</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="19">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor19">Real Estate
</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>With our Extensive Network, Explore the Potential in Commercial and Private Real Estate.
</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="20">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor20">Retail</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>Tap the vast market of Customer Retail Goods, and set up Stores and Networks with support from us.

</p>
                    </div>
                  </div>
                  <div className="slider__text-item" data-id="21">
                    <div className="slider__text-item-head">
                      <h3 className="slidercolor21">Mergers and Acquisitions
</h3>
                    </div>
                    <div className="slider__text-item-info">
                      <p>Expand your Operations in Newer Markets and amplify your Brand Reach and Brand Value.

</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider__nav">
                <div className="slider__nav-arrows">
                  <div className="slider__nav-arrow slider__nav-arrow--left" id="left">to left</div>
                  <div className="slider__nav-arrow slider__nav-arrow--right" id="right">to right</div>
                </div>
                <div className="slider__nav-dots" id="slider-dots">
                  <div className="slider__nav-dot slider__nav-dot--active" data-id="1"></div>
                  <div className="slider__nav-dot" data-id="2"></div>
                  <div className="slider__nav-dot" data-id="3"></div>
                  <div className="slider__nav-dot" data-id="4"></div>
                  <div className="slider__nav-dot" data-id="5"></div>
                  <div className="slider__nav-dot" data-id="6"></div>
                  <div className="slider__nav-dot" data-id="7"></div>
                  <div className="slider__nav-dot" data-id="8"></div>
                  <div className="slider__nav-dot" data-id="9"></div>
                  <div className="slider__nav-dot" data-id="10"></div>
                  <div className="slider__nav-dot" data-id="11"></div>
                  <div className="slider__nav-dot" data-id="12"></div>
                  <div className="slider__nav-dot" data-id="13"></div>
                  <div className="slider__nav-dot" data-id="14"></div>
                  <div className="slider__nav-dot" data-id="15"></div>
                  <div className="slider__nav-dot" data-id="16"></div>
                  <div className="slider__nav-dot" data-id="17"></div>
                  <div className="slider__nav-dot" data-id="18"></div>
                  <div className="slider__nav-dot" data-id="19"></div>
                  <div className="slider__nav-dot" data-id="20"></div>
                  <div className="slider__nav-dot" data-id="21"></div>


                </div>
              </div>
            </div>
          </div> */}
        </OwlCarousel>
      </div>
      // </SliderLazy>
    );
  }
}
