import React, { useState, useEffect } from "react";
import Header from "../../layouts/Header/Header.js";
// import { useParams } from "react-router-dom";
// import { useCustomState } from "../../../state/state";
// import { useHistory } from "react-router";
import { Footer } from "../../layouts";
import { Button, Typography } from "@material-ui/core";
// import "../../widgets/Slider/slider.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../Blog/blogpage.css";
import firebase from "../../../utilities/Firebase";

export default () => {
 
  const [postData, setPostData] = useState({
    singlePost: [],
    allPost: [],
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    firebase
      .firestore()
      .collection("walstrat/root/Events")
      .get()
      .then((snapshot) => {
        let data = [];
        snapshot.forEach((doc) => {
          let postObj = doc.data();
          postObj.docid = doc.id;
          data.push(postObj);
          console.log("media page data => ", postObj);
        });
        setPostData({
          ...postData,
          singlePost: data[0],
          allPost: data,
        });
      });
  };
  const handlePostChange = (PostId) => {
    let singlePostData = postData.allPost.filter((post) => post.id === PostId);
    setPostData({
      ...postData,
      singlePost: singlePostData[0],
    });
  };

  return (
    <div>
      <div style={{ height: "100px" }}>
        <Header />
      </div>
      <div className="row" id="singleMedia" style={{ margin: "50px" }}>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "xx-large",
          }}
        >
          {"Welcome to Event Detail Page"}
        </span>
        <div >
          {postData.singlePost.image && (
            <OwlCarousel
              items={1}
              className="owl-theme"
              style={{ marginBottom: "30px" }}
            >
            <div style={{ margin: "0 auto" }}>
              <img
                className="radiuszero widthImg"
                src={postData.singlePost.image}
                alt={postData.singlePost.eventName + "'s Image"}
                height="500px"
              />
            </div>
            </OwlCarousel>
          )}
          <Typography
            variant={postData.singlePost.image ? "h6" : "h5"}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {"publised on " +
              new Date(postData.singlePost.eventDate).toLocaleDateString(
                "en-IN"
              )}
          </Typography>
        </div>

        <div className="col-lg-12">
          <h2 style={{ fontSize: "40px" }}>{postData.singlePost.eventName}</h2>
        </div>
        <div className="col-lg-12">
          <p>{postData.singlePost.eventDetails}</p>
        </div>
        <br></br>
        <hr></hr>
        <span
          style={{ color: "blue", justifyContent: "center", display: "flex" }}
        >
          Read More Excited Media Events
        </span>
        {postData.allPost.length > 0 && (
          <OwlCarousel
            className="owl-theme"
            items={3}
            responsiveClass={true}
            id="owlBasicBar"
            loop={true}
            nav={false}
            autoplay={true}
            rewind={true}
            dots={true}
            responsiveClass={true}
            responsive={{
              0: {
                items: 1,
                loop: true,
              },
              600: {
                items: 1,
                loop: true,
              },
              700: {
                items: 1,
                loop: true,
              },
              1000: {
                items: 3,
                loop: true,
              },
            }}
          >
            {console.log("dsfsfgfsg", postData.allPost)}
            {postData.allPost.map((blog) => (
              // return (
                <div className="card card--small card--masonry card--news">
                <div className="card__image">
                  {/* <LazyLoad>
                    <Zoom> */}
                      <img src={blog.image} height="330px" width="253px" alt="" />
                    {/* </Zoom>
                  </LazyLoad> */}
                </div>
                <div className="card__content card__content--full">
                  <div className="card__date">
                    {new Date(blog.eventDate).toLocaleDateString(
                      "en-IN"
                    )}
                  </div>
                  {/* <LazyLoad> */}
                    <h3 className="card__heading">{blog.eventName}</h3>
                  {/* </LazyLoad>
                  <LazyLoad> */}
                    <div className="card__text">{blog.eventDetails}</div>
                  {/* </LazyLoad> */}
                  <div className="card__bottom">
                    {/* <LazyLoad> */}
                      <a className="card__link" href={"#singleMedia"} onClick={() => handlePostChange(blog.id)}>
                        Read more
                        <svg width="20" height="20"></svg>
                      </a>
                    {/* </LazyLoad> */}
                  </div>
                </div>
              </div>
              //     <Button className="readMore" variant="contained" >
              //     Read More
              //   </Button>
              //   </div>
              // </div>
              // )
            ))}
          </OwlCarousel>
        )}
      </div>
      <Footer />
    </div>
  );
};

// eventDate: "2021-05-04"
// eventDetails: "Description 3"
// eventName: "Media event 3"
// id: "event_NaN"
// image
// {image: "https://firebasestorage.googleapis.com/v0/b/melzo-…=media&token=201a1c01-cd1b-4758-8c50-5c4e57edaa93", id: "event_NaN", eventDate: "2021-05-04", eventDetails: "Description", eventName: "Media event 1"}
