/* eslint-disable */

import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
  Button,
  TextField,
  DialogContentText,
  Fab,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import CancelIcon from "@material-ui/icons/Cancel";
import AlertDialog from "./AlertDialog";
import SendIcon from "@material-ui/icons/Send";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { ImageSearch } from "@material-ui/icons";
import ProductDialog from "./ProductDialog";
import enquireicon from "../../../assets/placeholders/enquire.png";
// import ImageZoom from "js-image-zoom";
const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function ProductImageDialog(props, {}) {
  // var options = {
  //   width: 400,
  //   height: 250,
  //   zoomWidth: 500,
  //   // scale: 1.5,
  //   zoomLensStyle: {
  //     width: 50,
  //     heigth: 50,
  //     // backgroundColor : 'red'
  //   },
  //   zoomStyle : {
  //     opacity : 0.5,
  //   },
  //   // zoomPosition : 'right',
  //   offset: { vertical: 0, horizontal: 10 },
  // };
  // ImageZoom(document.getElementById("imageZoom"), options);
  const { open, Product, onClose } = props;
  const classes = useStyles();
  const [alertDialog, setAlertDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const setAlertDialogfunc = () => {
    setAlertDialog({
      ...alertDialog,
      isOpen: false,
      title: "",
      subTitle: "",
    });
  };
  const [productData, setProduct] = useState({
    productName: "",
    productDescription: "",
    productImage: [],
  });

  const [productImage, setProductImage] = useState({
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    image5: "",
    image6: "",
  });

  const [imageSelect, setImageSelect] = useState(1);

  const [meetingSpin, setmeetingSpin] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [mailerror, setMailError] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [customer, setCustomer] = useState({
    customerFName: "",
    customerLName: "",
    customerEmail: "",
    customerMessage: "",
  });

  useEffect(() => {
    console.log("product-imageDialog", Product[0]);
    setProduct({
      productName: Product[0] ? Product[0].name : "cdv ",
      productDescription: Product[0]
        ? Product[0].description
        : "demo description ",
    });
    setProductImage({
      image1: Product[0]
        ? Product[0].imagesCollection[0]
          ? Product[0].imagesCollection[0].url
          : ""
        : "",
      image2: Product[0]
        ? Product[0].imagesCollection[1]
          ? Product[0].imagesCollection[1].url
          : ""
        : "",
      image3: Product[0]
        ? Product[0].imagesCollection[2]
          ? Product[0].imagesCollection[2].url
          : ""
        : "",
      image4: Product[0]
        ? Product[0].imagesCollection[3]
          ? Product[0].imagesCollection[3].url
          : ""
        : "",
      image5: Product[0]
        ? Product[0].imagesCollection[4]
          ? Product[0].imagesCollection[4].url
          : ""
        : "",
      image6: Product[0]
        ? Product[0].imagesCollection[0]
          ? Product[0].imagesCollection[0].url
          : ""
        : "",
    });
    setImageSelect(1);
  }, [Product]);

  const handleImageChange = (index) => {
    let imagelink = productImage["image" + index];
    setProductImage({
      ...productImage,
      image6: imagelink,
    });
    setImageSelect(index);
  };

  // const emailcheck = () => {
  //   if (customer.customerEmail === "") {
  //     setErrorText(null);
  //   } else {
  //     if (ValidateEmail(customer.customerEmail)) {
  //       setErrorText(null);
  //       setMailError(false);
  //     } else {
  //       setMailError(true);
  //       setErrorText("Please enter valid email");
  //     }
  //   }
  // };

  // function ValidateEmail(mail) {
  //   if (
  //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
  //       mail
  //     )
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }
  // const handleCustomerChange = (event) => {
  //   setCustomer({
  //     ...customer,
  //     [event.target.name]: event.target.value,
  //   });
  //   console.log("customerDetails", customer);
  // };
  // const handleCloseProductInquiry = () => {
  //   if (customer.customerFName === "") {
  //     setAlertDialog({
  //       isOpen: true,
  //       title: "Please enter your name",
  //     });
  //   } else if (customer.customerEmail === "") {
  //     setAlertDialog({
  //       isOpen: true,
  //       title: "Please enter your email",
  //     });
  //   } else if (customer.customerMessage === "") {
  //     setAlertDialog({
  //       isOpen: true,
  //       title: "Please enter your query",
  //     });
  //   } else if (mailerror) {
  //     setAlertDialog({
  //       isOpen: true,
  //       title: "Please enter valid mail",
  //     });
  //   } else {
  //     // sendEmail(
  //     //   customer.customerFName,
  //     //   customer.customerLName,
  //     //   customer.customerEmail,
  //     //   customer.customerMessage,
  //     //   productData.productName,
  //     //   );
  //     props.onClose();
  //     setCustomer({
  //       ...customer,
  //       customerFName: "",
  //       customerLName: "",
  //       customerEmail: "",
  //       customerMessage: "",
  //     });
  //   }
  // };

  const handleClose = () => {
    // setCustomer({
    //   ...customer,
    //   customerFName: "",
    //   customerLName: "",
    //   customerEmail: "",
    //   customerMessage: "",
    // });
    props.onClose();
  };

  const handleCloseBuyNow = () => {
    window.open("http://eurolatex.in/", "_blank");
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <h1 className="titleschedule">{productData.productName}</h1>
        </DialogTitle>
        <DialogContent dividers>
          {/* <DialogContentText>{productData.productName}</DialogContentText> */}
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <div id="imageZoom" style={{ borderColor: "black" }}>
                <Avatar
                  variant="square"
                  className={classes.square}
                  style={{
                    width: "250px",
                    height: "250px",
                    border: "1px solid black",
                  }}
                  src={productImage.image6}
                />
              </div>
              <Grid item md={12} xs={12}>
                <div
                  id="templates"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "5px",
                    gap: "10px",
                  }}
                >
                  {productImage.image1 !== "" && (
                    <Button
                      style={
                        imageSelect == 1
                          ? {
                              border: "2px",
                              borderStyle: "solid",
                              borderColor: "#36cca5",
                            }
                          : {}
                      }
                      onClick={() => handleImageChange("1")}
                    >
                      <Avatar
                        variant="square"
                        className={classes.square}
                        style={{
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                        }}
                        src={productImage.image1}
                      />
                    </Button>
                  )}
                  {productImage.image2 !== "" && (
                    <Button
                      style={
                        imageSelect == 2
                          ? {
                              border: "2px",
                              borderStyle: "solid",
                              borderColor: "#36cca5",
                            }
                          : {}
                      }
                      onClick={() => handleImageChange("2")}
                    >
                      <Avatar
                        variant="square"
                        className={classes.square}
                        style={{
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                        }}
                        src={productImage.image2}
                      />
                    </Button>
                  )}
                  {productImage.image3 !== "" && (
                    <Button
                      style={
                        imageSelect == 3
                          ? {
                              border: "2px",
                              borderStyle: "solid",
                              borderColor: "#36cca5",
                            }
                          : {}
                      }
                      onClick={() => handleImageChange("3")}
                    >
                      <Avatar
                        variant="square"
                        className={classes.square}
                        style={{
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                        }}
                        src={productImage.image3}
                      />
                    </Button>
                  )}
                  {productImage.image4 !== "" && (
                    <Button
                      style={
                        imageSelect == 4
                          ? {
                              border: "2px",
                              borderStyle: "solid",
                              borderColor: "#36cca5",
                            }
                          : {}
                      }
                      onClick={() => handleImageChange("4")}
                    >
                      <Avatar
                        variant="square"
                        className={classes.square}
                        style={{
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                        }}
                        src={productImage.image4}
                      />
                    </Button>
                  )}
                  {productImage.image5 !== "" && (
                    <Button
                      style={
                        imageSelect == 5
                          ? {
                              border: "2px",
                              borderStyle: "solid",
                              borderColor: "#36cca5",
                            }
                          : {}
                      }
                      onClick={() => handleImageChange("5")}
                    >
                      <Avatar
                        variant="square"
                        className={classes.square}
                        style={{
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                        }}
                        src={productImage.image5}
                      />
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              {/* <Typography
                variant="h5"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {"Name : " + productData.productName}
              </Typography>
              <br></br> */}
              <Typography
                variant="h5"
                style={{ display: "flex", flexWrap: "wrap", fontFamily: "Sitka" }}
              >
                {"Description : " + productData.productDescription}
              </Typography>
              <br></br>
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextField
                name="customerFName"
                onChange={handleCustomerChange}
                value={customer.customerFName}
                variant="outlined"
                label={"Enter your first name"}
                type="text"
                fullWidth
                id="customerFName"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="customerLName"
                onChange={handleCustomerChange}
                value={customer.customerLName}
                variant="outlined"
                label={"Enter your last name"}
                type="text"
                fullWidth
                id="customerLName"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={mailerror}
                helperText={errorText}
                onBlur={emailcheck}
                name="customerEmail"
                onChange={handleCustomerChange}
                required
                value={customer.customerEmail}
                variant="outlined"
                autoFocus
                id="customerEmail"
                label={"Enter your Email"}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="customerMessage"
                onChange={handleCustomerChange}
                value={customer.customerMessage}
                variant="outlined"
                label={"Enter your querry"}
                type="text"
                fullWidth
                id="customerMessage"
              />
            </Grid> */}
            {/* <Grid item md={6} xs={12}>
              <TextField
                name="customerFName"
                onChange={handleCustomerChange}
                required
                value={customer.customerFName}
                variant="outlined"
                autoFocus
                id="number"
                label={"customerFName"}
                type="number"
                fullWidth
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className="btn-glow btn-hover-shine fontschedule"
            style={{ fontFamily: "Sitka" }}
          >
            <CancelIcon style={{ marginRight: "5px" }} />
            Cancel
          </Button>
          <Button
            onClick={() => setOpenProduct(true)}
            style={{ margin: "5px" }}
            variant="contained"
            className="btn-glow btn-hover-shine fontschedule"
            style={{ fontFamily: "Sitka" }}
          >
            <img src= {enquireicon} style={{width: "15px", height: "17px", marginRight: "8px"}} />
            Enquire
          </Button>
          {meetingSpin ? (
            <CircularProgress
              color="secondary"
              style={{ margin: "5px" }}
              size={20}
            />
          ) : (
            ""
          )}
          {
            <Button
              onClick={handleCloseBuyNow}
              disabled={meetingSpin}
              className="btn-glow btn-hover-shine fontschedule"
              style={{ fontFamily: "Sitka" }}
            >
              <ShoppingCartIcon style={{ marginRight: "5px" }} /> Buy Now
            </Button>
          }
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertDialog={alertDialog}
        setAlertDialog={setAlertDialogfunc}
      />
      <ProductDialog
        open={openProduct}
        Product={Product}
        onClose={() => setOpenProduct(false)}
      />
    </React.Fragment>
  );
}

function sendEmail(
  customerFName,
  customerLName,
  customerEmail,
  customerMsg,
  product
) {
  var customerName = customerFName + " " + customerLName;
  const regMessage =
    "Dear Admin," +
    "<br><br/>" +
    `<p> Hello Admin, you get this email because one of your customer inquired for your products. </p>` +
    `<br></br>` +
    `<p> Details are as follow :: </p>` +
    `<br></br><br></br>` +
    `<table style="width:100%">
    <tr>
      <th>Customer Name</th>
      <th>Customer Email</th>
      <th>Inquiry For</th>
      <th>Inquiry Message</th>
    </tr>
    <tr>
      <td>${customerName}</td>
      <td>${customerEmail}</td>
      <td>${product}</td>
      <td>${customerMsg}</td>
    </tr>
  </table>`;
  // const regMessage =
  //   "Dear Dr./Prof. " +
  //   regName +
  //   "," +
  //   "<br/><br/>" +
  //   `<p>Thank you for registering with us, for Merz Aesthetics Anatomy E-workshop.</p>

  // <p>To join the event please visit <a href="https://merzanatomyworkshop.com">https://merzanatomyworkshop.com</a></p>
  //  <p>On 9th & 10th April 2021. <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20210409T120000Z%2F20210410T173000Z&details=Experience%20the%20most%20informative%20e-Workshop%20on%20advanced%20anatomy%20and%20live%20injection%20course%20in%20UAE.%20%0AJoin%20us%20to%20enhance%20your%20knowledge%20and%20understand%20the%20intricacies%20of%20the%20Human%20Anatomy%20and%20its%20enhancement.&location=Online&text=Merz%20Aesthetics%20Anatomy%20E-workshop" title="Save Event in my Calendar" >Add to Calendar</a></p>
  // <br/>
  // <p>As per your registration form, your login credentials are - </p>
  // <p>Login ID : <strong>${regEmail}</strong></p>
  // <p>Password : <strong>${regPassword}</strong></p>
  // <br/>
  // <p> Join Meeting : <a href=${regPassword}>${regPassword}</a></p>
  // <br/>
  // <p>Thank you once again and have a wonderful day! </p>
  // <br/>
  // <b>If you have any queries please reply to this mail.</b>
  // <br/>
  // <p>Thank you,</p>
  // <br/>
  // <p>Merz Aesthtics Anatomy E-workshop</p>
  // <br/>
  // <img src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/merz/merz-logo.jpeg" alt="Merz Logo" style="height: 40px">
  // <br/>
  // <p  style="font-size: 10px;">Disclaimer : Please note that if you submit information to Merz all materials submitted by electronic or physical communication will be deemed non confidential. Do not submit any information or other materials that you consider to be confidential or proprietary. While we have no intention to publicly disclose any details of your proposal, we may refer to select experts within or outside the Merz network for evaluation.</p>
  // <br/>

  // <br/>`;
  // console.log("Email:: ", tempEmail);

  // var obj = {
  //   useremail: localStorage.getItem("email"),
  //   message: regMessage,
  //   subject: `Inquiry for ${product} by ${customerEmail} `,
  //   SiteType: expoName,
  // };

  var obj = {
    useremail: "admin mail",
    message: regMessage,
    subject: `Inquiry for ${product} by ${customerEmail} `,
    SiteType: "productInquiry",
  };

  console.log("Mail data => ", regMessage);
  //http://localhost:5000/sendtoemail-3610d/us-central1/expoRegistration
  // axios
  //   .post(
  //     "https://us-central1-sendtoemail-3610d.cloudfunctions.net/anglesSendemail",
  //     obj,
  //     {
  //       headers: {
  //         // 'x-access-token': token
  //       },
  //     }
  //   )
  //   .then(function () {
  //     // window.location.href = 'website.html'
  //     console.log("Done", regEmail);
  //     // IncrementVisitorCounter();
  //   })
  //   .catch((error) => {
  //     console.log("Not DOne", error);
  //   });
}
