import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import "./BlogList.css";
import "../../widgets/Slider/slider.css";

import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Button } from "../../elements";
import Typography from "@material-ui/core/Typography";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  control: {
    padding: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function BlogCards(props) {
  const classes = useStyles();

  return (
    <OwlCarousel className="owl-theme" margin={10} autoplay={1000} >
      {/* <Grid container spacing={2}> */}
        {props.posts.map((post, index) => (
          // <Grid key={index} item xs={12}>
            <Card style={{ borderColor: "red" }}>
              <CardContent>
                <Typography variant="h4">
                  {"Blog Title : " + post.title}
                </Typography>
                <br />
                <p className="extra">
                  {"written by : " + post.author} <br />
                  <br />
                  {"publish on : " +
                    new Date(post.posting_date).toLocaleDateString("en-US")}
                </p>
                <br />
                <p variant="body2" component="p">
                  {"blog about : " + post.short}
                </p>
              </CardContent>

              {/* <CardActions>
                  <Button to={"/blog/" + post.id} hoverType="solid-gray-tb">
                    Read More
                  </Button>
                </CardActions> */}
            </Card>
          // </Grid>
        ))}
      {/* </Grid> */}
    </OwlCarousel>
  );
}
