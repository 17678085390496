import React, { Component } from 'react'
import Particles from 'react-particles-js';
import aviationicon from "../../../assets/placeholders/icon/plane.png";

export default class Aviation extends Component {
    render() {
        return (
            <div>
				<img src={aviationicon} className="iconmove"/>
				{/* <img className="parti" src="https://aepc-images.s3.ap-south-1.amazonaws.com/image/public/image2d/walstrat/helicopter.png"></img> */}
                	{/* <Particles  className="parti"
    params={{
	    "particles": {
	        "number": {
	            "value": 8,
	            "density": {
	                "enable": true,
	                "value_area": 800
	            }
	        },
	        "line_linked": {
	            "enable": false
	        },
	        "move": {
	            "speed": 3,
	            "out_mode": "out"
	        },
	        "shape": {
	            "type": [
	                "image",
	                "circle"
	            ],
	            "image": [
	                {
	                    "src": 'https://aepc-images.s3.ap-south-1.amazonaws.com/image/public/image2d/walstrat/helicopter.png',
	                    "width": 100
					},
				
                   
				],
				"image": [
	                {
	                    "src":aviationicon,
	                    "width": 100
					},
				
                   
				],
				
			},

	        "color": {
	            "value": "#CCC"
	        },
	        "size": {
	            "value": 30,
	            "random": false,
	            "anim": {
	                "enable": true,
	                "speed": 10,
	                "size_min": 10,
	                "sync": false
	            }
	        }
	    },
	    "retina_detect": false
	}} /> */}
            </div>
        )
    }
}
