import React, { useState, useEffect } from "react";
import CVofRDayakar from "../../../assets/pdf/CVofRDayakar.pdf";
const width = 600;

const Maquree = ({ tileData }) => {
  const [pos, setPos] = useState(0);
  const [run, setRun] = useState(true);
  const scrollEff = () => {
    if (run) setPos(pos < width ? pos + 1 : -width + 480);
  };

  useEffect(() => {
    const tm = setTimeout(scrollEff, 10);
    return () => clearTimeout(tm);
  }, [pos]);

  const onMouseEnter = (e) => {
    setRun(false);
  };

  const onMouseLeave = (e) => {
    setRun(true);
    setPos(pos + 1); // to trigger useEffect
  };

  const styles = {
    position: "relative",
    fontSize: "1em",
    bottom: pos + "px",
    margin: "5px",
  };

  return (
    <div style={{ height: "200px", overflow: "hidden" }}>
      <span
        style={{ display: "flex", justifyContent: "center" }}
        // style={styles}
        // onMouseEnter={onMouseEnter}
        // onMouseLeave={onMouseLeave}
      >
        <a
          href={CVofRDayakar}
          target="_blank"
          style={{ color: "black", fontSize: "20px", margin: "50px" }}
        >
          Former Ambassador Shri. R.Dayakar
        </a>
      </span>
    </div>
  );
};

export default Maquree;
