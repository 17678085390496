import React, { useState } from "react";
import styles from "./Sidebar.module.scss";
import { useCustomState } from "../../../state/state";
import { Button, Logo } from "../../elements";
import { Backdrop } from "../../ui";
import THEME from "../../../state/theme";
import Logoslider from "../../elements/Logo/Logoslider";
import { Link } from 'react-scroll';
import closebutton from '../../../assets/placeholders/cancel.png';
import { useHistory } from "react-router";

export default ({ data = [] }) => {
  const [state, actions] = useCustomState();
  const [submenu, setSubmenu] = useState(null);
  const history = useHistory()

  const hover = {
    color: THEME.color,
  };

  const toogleSubmenu = (index) => {
    index === submenu ? setSubmenu(null) : setSubmenu(index);
  };

  const toogleSidebar = () => {
    actions.toogleSidebar();
    state.backdrop && actions.toogleBackdrop();
  };

  function gotoProducts() {
    window.location.href = "/products";
  }
  const toogleModal = () => {
    actions.toogleModal();
    actions.toogleSidebar();
  };

  const menu = data.map((item, index) => {
    if (!item.children) {
      return (
        <li key={index}>
          <Link click={toogleSidebar} url={item.url} hoverStyle={hover}>
            {item.name}
          </Link>
        </li>
      );
    } else {
      return (
        <li key={index}>
          <span>
            <Link url="#" hoverStyle={hover} click={() => toogleSubmenu(index)}>
              {item.name}
              <i
                className={
                  submenu === index ? "las la-angle-up" : "las la-angle-down"
                }
                onClick={() => toogleSubmenu(index)}
              />
            </Link>
          </span>
          <ul
            style={{
              height:
                submenu === index ? 30 * item.children.length + "px" : "0",
            }}
          >
            {item.children.map((subitem, subindex) => (
              <li key={subindex}>
                <Link
                  click={toogleSidebar}
                  url={subitem.url}
                  hoverStyle={hover}
                >
                  {subitem.name}
                </Link>
              </li>
            ))}
          </ul>

        </li>
      );
    }
  });

  return (
    <div className={[styles.wrapper, state.sidebar && styles.show].join(" ")}>
      <div className={styles.sidebar}>
        <div className={styles.btn_close} onClick={toogleSidebar}>
          <Button
            // after="&#xf00d;"
            type="solid-white-tb"
            hoverType="solid-gray-tb"
          >
            <img src={closebutton}></img>
          </Button>
        </div>
        <div className={styles.logo}>
          <Logoslider />
        </div>

        {/* <ul className={styles.menu}>{menu}</ul> */}


        <ul className={styles.menu}>
          <li >
            <Link
              activeClass="active" to="home" spy={true} smooth={true} duration={1000}
              className="cursorpointer" onClick={() => { history.push("/"); }}>
              Home        </Link>
            {/* <a href="#home" className="smoothscroll" onClick={toogleSidebar} >Home</a> */}
          </li>

          
          <li>
            <Link activeClass="active" to="about" spy={true} smooth={true} duration={1000} onClick={toogleSidebar} className="cursorpointer">

              About        </Link>
            {/* <a href="#about" className="smoothscroll" onClick={toogleSidebar} >About us</a> */}
          </li>
          <li>
            <a
              // activeClass="active"
              // to="services"
              // spy={true}
              // smooth={true}
              // duration={1000}
              className="cursorpointer"
              onClick={() => {
                history.push("/");
                setTimeout(() => {
                  let productEl = document.getElementById('services')
                  if (productEl) {
                    productEl.scrollIntoView({
                      behavior: 'smooth'
                    });
                  }

                }, 500)

              }}

            >
              Our Expertise{" "}
            </a>
            <ul>
              <li>
                <a

                  className="cursorpointer"
                  onClick={() => {
                    history.push("/");
                    setTimeout(() => {
                      let productEl = document.getElementById('services')
                      if (productEl) {
                        productEl.scrollIntoView({
                          behavior: 'smooth'
                        });
                      }

                    }, 500)

                  }}

                >
                 Services{" "}
                </a>

              </li>
              <li>
                <a

                  className="cursorpointer"
                  onClick={gotoProducts}

                >
                  Products{" "}
                </a>

              </li>
            </ul>
            {/* <a href="#services" className="smoothscroll">Services</a> */}
          </li>
          <li>
            <a
              // activeClass="active"
              // to="blogpage"
              // spy={true}
              // smooth={true}
              // duration={1000}
              className="cursorpointer"
              onClick={() => {
                history.push("/");
                setTimeout(() => {
                  let productEl = document.getElementById('blogpage')
                  if (productEl) {
                    productEl.scrollIntoView({
                      behavior: 'smooth'
                    });
                  }

                }, 500)
              }}
            >
              Resources{" "}
            </a>
            <ul>
              <li>
                <a

                  className="cursorpointer"
                  onClick={() => {
                    history.push("/");
                    setTimeout(() => {
                      let productEl = document.getElementById('blogpage')
                      if (productEl) {
                        productEl.scrollIntoView({
                          behavior: 'smooth'
                        });
                      }

                    }, 500)

                  }}

                >
                  Blog{" "}
                </a>

              </li>
              <li>
                <a

                  className="cursorpointer"
                  onClick={() => {
                    history.push("/");
                    setTimeout(() => {
                      let productEl = document.getElementById('media')
                      if (productEl) {
                        productEl.scrollIntoView({
                          behavior: 'smooth'
                        });
                      }

                    }, 500)

                  }}

                >
                  Media{" "}
                </a>

              </li>
            </ul>
            {/* <a href="#blog" className="smoothscroll">Blog</a> */}
          </li>
          {/* <li>
            <Link activeClass="active" to="products" spy={true} smooth={true} duration={1000} onClick={gotoProducts} className="cursorpointer">

              Products       </Link>
            <a href="#blog" className="smoothscroll" onClick={toogleSidebar}>Blog</a>
          </li> */}
          <li>
            <Link activeClass="active" to="contact" spy={true} smooth={true} duration={1000} onClick={toogleSidebar} className="cursorpointer">

              Contact        </Link>
            {/* <a href="#contact" className="smoothscroll" onClick={toogleSidebar}>Contact</a> */}

          </li>
          {/* <li>
            <a
              activeClass="active"
              // to="services"
              spy={true}
              smooth={true}
              duration={1000}
              className="cursorpointer"
              href="https://unite.melzo.com/"
              target="_blank"            >
              Melzo Unite
            </a>
          </li> */}
        </ul>
        {/* <div className={styles.btn_contacts}>
          <Button hoverType="solid-gray-tb" click={toogleModal}>
            Schedule a meeting
          </Button>
        </div> */}
      </div>
      {/* <Backdrop /> */}
    </div>
  );
};
