import React, { Component } from "react";
import "./blogpage.css";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import blogimg1 from "../../../assets/placeholders/4.jpg";
import blogimg2 from "../../../assets/placeholders/3.jpg";
import blogimg3 from "../../../assets/placeholders/consultancy.jpg";
import blogimg4 from "../../../assets/placeholders/industrial.jpg";
import blogimg5 from "../../../assets/placeholders/3.jpg";
import blogimg6 from "../../../assets/placeholders/3.jpg";
import $ from "jquery";
import firebase from "../../../utilities/Firebase";
// import LazyLoad from "react-lazy-load";

export default class HomeTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      owlCarousel: {},
      tileData: [],
    };
  }

  componentDidMount() {
    this.setState({
      tileData: [
        {
          pageImage: blogimg1,
          pageName: "Blog",
          pageDetail: "Visit to view our Blog",
          pageUrl: "blog/post_7",
          pageNote: "Click here to explore the blog world.!"
        },
        {
          pageImage: blogimg2,
          pageName: "Media",
          pageDetail: "Visit to view our Media",
          pageUrl: "mediaPage",
          pageNote: "Click here to explore the past media event covered.!"
        },
        {
          pageImage: blogimg3,
          pageName: "Product",
          pageDetail: "Visit to view our latest product",
          pageUrl: "products",
          pageNote: "Click here to explore the latest collection of products.!"
        }
        // ,
        // {
        //   pageImage: blogimg4,
        //   pageName: "Projects",
        //   pageDetail: "Visit to view our Project",
        //   pageUrl: "blog/post_7",
        //   pageNote: "Click here to explore the projects.!"
        // },
      ],
    });
  }

  render() {
    return (
      <div id="moreResources">
        <section className="news-one news-one__page">
          <div className="container" id="blog">
            <div className="layout-header">
              <Flip top>
                <span className="subtitle">More Resources</span>
              </Flip>
            </div>
            <div className="row">
              {this.state.tileData.length > 0 && (
                <OwlCarousel
                  className="owl-theme"
                  items={3}
                  id="owlBasicBar"
                  loop={false}
                  nav={true}
                  autoplay={true}
                  rewind={true}
                  dots={true}
                  responsiveClass={true}
                  responsive={{
                    0: {
                      items: 1,
                      loop: false,
                    },
                    600: {
                      items: 1,
                      loop: false,
                    },
                    700: {
                      items: 2,
                      loop: false,
                    },
                    1000: {
                      items: 3,
                      loop: false,
                    },
                  }}
                >
                  {this.state.tileData.map((tile, index) => (
                    <Fade left key={index}>
                      <div className="news-one__single">
                        <div className="news-one__img-box">
                          <div
                            className="news-one-img"
                            style={{ height: "200px" }}
                          >
                            <img src={tile.pageImage} alt="" />
                          </div>
                        </div>
                        <div
                          className="news-one__content"
                          style={{ height: "200px" }}
                        >
                          <h3 className="news-one__title">
                            <span>{tile.pageName}</span>
                          </h3>
                          <p className="news-one__text">{tile.pageDetail}</p>
                          <a
                            href={"/" + `${tile.pageUrl}`}
                            className="news-one__btn"
                          >
                            {tile.pageNote}
                          </a>
                        </div>
                      </div>
                    </Fade>
                  ))}
                </OwlCarousel>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
