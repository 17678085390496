/* eslint-disable */
import React, { Component } from "react";
import "../src/GoogleTranslate.css";

// import "../webassets/img/mainnavigation.css";
// className="sb-font-weight-700 sb-title-color sb-breaking-news__section2-menu" 


class GoogleTranslate extends Component {
    render() {
        return (
            <div id="google_translate_element" />
        )
    }

}
export default GoogleTranslate