import React,{useState} from "react";
import styles from "./AddressBox.module.scss";
import useHover from "../../../utilities/useHover";
import THEME from "../../../state/theme";
import melzounite from "../../../assets/placeholders/uinte.png"
import MeetingDialog from "../../widgets/Contacts2/MeetingDialog";


export default ({ icon, title, text }) => {
  const [ref, isHover] = useHover();
  const [meeting, setMeeting] = useState(false);

  const schedulemeet=()=>{
    setMeeting(true)
  }
  const handleClickCloseMeeting = () => {
    setMeeting(false);
  };
  return (
    <div>
      <div
        ref={ref}
        className={styles.address_box}
        style={{ background: isHover && THEME.color }}
      >
        {title == "24x7 Buyer Seller Meet" ? <img src={melzounite} width="35px" /> :
        <i className={icon} />}
        <div>
          <h3 style={{textDecoration : "underline"}}>{title == "24x7 Buyer Seller Meet" ? <a style={{color: "white", cursor : "pointer", textDecoration : "underline"}} onClick={()=> schedulemeet()} target="_blank">{title}</a> : title === "Call Us" ? <a style={{color: "white", textDecoration : "underline"}} href="tel:+919910169994" >{title}</a> : title === "Mail Us" ? <a style={{color: "white", textDecoration : "underline"}} href="mailto:walstrat999@gmail.com" target="_blank">{title}</a> : title }</h3>
          <span>{text}</span>
        </div>
      </div>
      {<MeetingDialog open={meeting} onClose={()=> handleClickCloseMeeting()} type={"melzoUnite"} />}
    </div>
  );
};

