/* eslint-disable no-alert */
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const useFileHandler = (initState) => {
  const [imageFile, setImageFile] = useState(initState);
  const [isFileLoading, setFileLoading] = useState(false);
  const [imagecountalert, setImagecountalert] = useState(false);

  const removeImage = ({ id, name }) => {
    const items = imageFile[name].filter((item) => item.id !== id);

    setImageFile({
      ...imageFile,
      [name]: items,
    });
  };

  const onFileChange = (event, { name, type }, imagecount) => {
    const val = event.target.value;
    const img = event.target.files[0];
    const size = img.size / 1024 / 1024;
    const regex = /(\.jpg|\.jpeg|\.png)$/i;

    setFileLoading(true);
    if (!regex.exec(val)) {
      alert("File type must be JPEG or PNG", "error");
      setFileLoading(false);
    } else if (size > 0.5) {
      alert(
        "File size exceeded 500kb, consider optimizing your image",
        "error"
      );
      setFileLoading(false);
    } else if (type === "multiple") {
      console.log(" file length =>", event.target.files.length);
      console.log(" file  =>", event.target.files);
      if (event.target.files.length < 6 - imagecount) {
        Array.from(event.target.files).forEach((file) => {
          const reader = new FileReader();
          reader.addEventListener("load", (e) => {
            setImageFile((oldFiles) => ({
              ...oldFiles,
              [name]: [
                ...oldFiles[name],
                { file, url: e.target.result, id: uuidv4() },
              ],
            }));
          });
          reader.readAsDataURL(file);
        });
        // For Image upload
        setFileLoading(false);
        setImagecountalert(false);
      } else {
        setFileLoading(false);
        setImagecountalert(true);
        // alert("Add upto 5 files.!!")
      }
    } else {
      // type is single
      const reader = new FileReader();
      reader.addEventListener("load", (e) => {
        setImageFile({
          ...imageFile,
          [name]: { file: img, url: e.target.result },
        });
        setFileLoading(false);
      });
      reader.readAsDataURL(img);
    }
  };

  return {
    imageFile,
    setImageFile,
    isFileLoading,
    onFileChange,
    removeImage,
    imagecountalert,
    setImagecountalert,
  };
};

export default useFileHandler;
