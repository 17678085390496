import React, { Component } from "react";
import "./blogpage.css";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import blogimg1 from "../../../assets/placeholders/4.jpg";
import blogimg2 from "../../../assets/placeholders/3.jpg";
import blogimg3 from "../../../assets/placeholders/consultancy.jpg";
import blogimg4 from "../../../assets/placeholders/industrial.jpg";
import blogimg5 from "../../../assets/placeholders/3.jpg";
import blogimg6 from "../../../assets/placeholders/3.jpg";
import AdvisorImg from "../../../assets/placeholders/walstart_advisor.jpg"
import $ from "jquery";
import firebase from "../../../utilities/Firebase";
import { black } from "material-ui/styles/colors";
import Maquree from "./Marquee";
import "./projects.css";
import { Typography } from "@material-ui/core";
// import LazyLoad from "react-lazy-load";

// const width = 100;
export default class ThinkTank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      owlCarousel: {},
      tileData: [],
    };
  }

  componentDidMount() {
    this.setState({
      tileData: [
        {
          pageName:
            "Automatic Dipping Plant for manufacturing Handgloves, condoms, Balloons etc. ",
        },
        {
          pageName: "Plants to manufacture Elastic Rubber Threads.",
        },
        {
          pageName:
            "Automatic Mattress and pillows manufacturing plant from Rubber Latex.",
          subLink: [
            "Plants to manufacture Rubberised Coir Products like Mattresses, sofa Cushions, carpet underlays, ac filters, pots etc.",
          ],
        },
        {
          pageName: "Plants for Rubber Slip on Footwear.",
        },
        {
          pageName:
            "Plants for Rubber Tyres ,Tubes and Flaps for Car, 2 wheelers and Bicycles etc.",
        },
        {
          pageName: "Plants for Moulded Rubber products.",
        },
        {
          pageName: "Plants for Battery Containers.",
        },
        {
          pageName: "Plants for Retread Rubber and Reclaim Rubber.",
        },
        {
          pageName: "Plants for Rubber V Belts etc.",
        },
        {
          pageName: "Automatic Peanut and other Dry Fruits Roasting Plants.",
        },
        {
          pageName: "Food Processing Plants.",
        },
        {
          pageName: "Tin Containers Manufacturing and Printing Plants",
          subLink: [
            "Agricultural Machinery For different applications .",
            "Latex continuous foaming Plant",
            "Waste petroleum to products technology plant",
            "Laser Machines",
            "Robotic plants",
            "Oxygen plants",
          ],
        },
        // {
        //   pageName: " ",
        // },
        // {
        //   pageName: " ",
        // },
        // {
        //   pageName: " ",
        // },
        // {
        //   pageName: " ",
        // },
      ],
    });
  }

  render() {
    return (
      <div id="projectpage">
        <section className="news-one news-one__page ">
          <div className="container" id="blog">
            <div className="layout-header">
              <Flip top>
                <span className="subtitle">Think Tank</span>
                <Typography variant="body1">Advisor</Typography>
              </Flip>

              <img src={AdvisorImg} height="150px" width="150px" style={{margin: "5px", borderRadius: "5px"}} ></img>
            </div>
            
            <div className="row">
              <div style={{ height: "100px" }}>
                <Maquree tileData={this.state.tileData} />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
