import React, { Component } from 'react';
//import video from '../../../assets/placeholders/aviation.m4v';
import './servicepage.css'
import { Player } from 'video-react';
import Header from '../../layouts/Header/Header';
import logo from '../../../assets/placeholders/WALSTRAT LOGO-.png';
import img1 from '../../../assets/placeholders/healthcare.jpg';

export default class Healthcarepage extends Component {

    goTOBack = () => {
        window.location.href = '/'
    }

    render() {


        return (
            <div>
                {/* <Header /> */}
                {/* <div className="bgcolor">
                <h1 className="title">{localStorage.getItem('title')}</h1>

                    
                </div> */}
                {/* <div style={{ background: localStorage.getItem('colorCode') }} className="headerservices">
                    <h1 className="titlepage3">
                        {localStorage.getItem('title')}
                    </h1>
                </div> */}
                <div className="overlayvideo" style={{  background: 'rgba(3, 17, 99,0.4)'}}>

                </div>
                <div className="serviceheader">
                    <img src={logo} className="widthlogoslider positionlogo" onClick={this.goTOBack}></img>
                    <button onClick={this.goTOBack} className="backtohomebtn">Back to Home</button>

                </div>
                <div className="image_bg">
                    <a-scene style={{width:'100%',height:'100%'}}>
                        <a-assets>
                            <img id="sky" src={img1}/>
                        </a-assets>
                            <a-sky src="#sky" animation="property: rotation; easing:linear; to: 0 360 0; loop: true; dur: 30000">
                            </a-sky>
                        </a-scene>
                </div>
                    {/* <div id="video-viewport">
                        <video autoPlay loop muted>
                            <source src={localStorage.getItem('src')} type="video/mp4" />
                        </video>

                    </div> */}
                    <div className="positiontext">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="titlepage3" style={{color:'#fff'}}>
                                        {localStorage.getItem('title')}
                                    </h1>
                                    <p className="description" style={{ color: '#fff' }}>
                                        {/* With our expert services, we help you create an atmosphere that promotes talent and nurtures creativity. */}
                                        {localStorage.getItem('discription1')}

                                    </p>
                                    <p className="description" style={{ color: '#fff' }}>
                                        {/* We can help in advising and leading educational initiatives focused on delivering results to their enterprise. With the changing competencies needed by businesses to sustain growth, we can help in developing skill sets and educational institutions aligned towards changing needs. */}
                                        {localStorage.getItem('discription2')}
                                    </p>
                                    <p className="description" style={{ color: '#fff' }}>
                                        {/* We combine primary research with advanced analytics and strategy consulting to help clients realize growth opportunities in new sectors and geographies, including untapped developing countries. */}
                                        {localStorage.getItem('discription3')}
                                    </p>
                                    <p className="description" style={{ color: '#fff' }}>
                                        {localStorage.getItem('discription4')}
                                        {/* Curriculum Tie-Ups in form of Merging Various Syllabi, Tying up with Foreign Universities, Facilitating Student Exchange Programs, Helping interested students apply to Indian Institutions, etc. */}
                                    </p>
                                    <p className="description" style={{ color: '#fff' }}>
                                        {localStorage.getItem('discription5')}
                                        {/* We’re trying to pave a smooth path for future achievers. Developing Infrastructure for bright personalities, we work as committed facilitators who instigate in developing the right curriculum and help foster positive attributes coupled with enthusiasm and focused endeavour.                             */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
