import React, { useEffect, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import {
  Home,
  HomeParalax,
  HomeVideo,
  About,
  Services,
  Portfolio,
  Contacts,
  Blog,
  PostSingle,
  BlogSingle,
  ServiceSingle,
  MemberCard,
  PortfolioSingle,
} from "./components/pages";
import { Header, Sidebar, Footer } from "./components/layouts";
import { ModalForm, ModalVideo, RequestForm } from "./components/ui";
import { Spinner } from "./components/elements";
import { useCustomState } from "./state/state";

// import Contruction from "./components/pages/Contruction/Contruction";
import CreateBlog from "./components/CreateBlog";
import AdminLogin from "./AdminLogin";
import Servicepage from './components/widgets/Services2/servicepage';
import Servicepage2 from './components/widgets/Services2/servicepage2';
import Servicepage3 from './components/widgets/Services2/servicepage3';
import Tourismpage from './components/widgets/Services2/tourismpage';
import Retailpage from './components/widgets/Services2/retailpage';
import Healthcarepage from './components/widgets/Services2/healthcarepage';
import Products from "./components/widgets/Products/Products";
import Product from "./components/pages/Products/Product";
import AdminPanel from "./components/mediaEvent";
import ProductForm from "./components/add-product";
import ProductsNew from "./components/widgets/Products/ProductsNew";
import BlogPage from "./components/pages/Blog/BlogPage";
import MediaEvent from "./components/media-event";
import Aboutfullpage from "./components/pages/About/aboutfullpage";

import MediaSingle from "./components/pages/BlogSingle/MediaSingle";
import EnquiryDetail from "./components/EnquiryDetails";
import AdminMain from "./components/admin/main";
import MediaEvent2 from "./components/media-eventCopy";
import Projects from "./components/pages/Blog/Projects"
// import BlogPageNew from "./components/pages/BlogSingle/BlogSingle";

export default () => {
  const [state, actions] = useCustomState();

  useEffect(() => {
    actions.fetch();
  }, []);

  let app = state.error ? <p>Can't load page</p> : <Spinner fluid />;

  if (state.data) {
    app = (
      <Fragment>
        <Sidebar data={state.data.menu} />
        {/*  */}
        {/* <Header data={state.data.menu} /> */}
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="header" exact component={Header} />

          {/* <Route path="/home-paralax" exact component={HomeParalax} /> */}
          {/* <Route path="/home-video" exact component={HomeVideo} /> */}
          <Route path="/about-us" exact component={About} />
          <Route path="/aboutpage" exact component={Aboutfullpage} />
          <Route path="/services" exact component={Services} />
          {/* <Route path="/portfolio" exact component={Portfolio} /> */}
          <Route path="/contacts" exact component={Contacts} />
          <Route path="/servicepage" exact component={Servicepage} />
          <Route path="/servicepage2" exact component={Servicepage2} />
          <Route path="/servicepage3" exact component={Servicepage3} />
          <Route path="/tourismpage" exact component={Tourismpage} />
          <Route path="/retailpage" exact component={Retailpage} />
          <Route path="/healthcarepage" exact component={Healthcarepage} />
          {/* <Route path="/blogsection" exact component={BlogPage} /> */}
          <Route path="/blog/:post_id" exact component={BlogSingle} />
          <Route path="/mediaPage" exact component={MediaSingle} />
          <Route path="/projects" exact component={Projects} />
          
          <Route path="/products" exact component={ProductsNew} />
          <Route path="/services/:service_id" exact component={ServiceSingle} />
          {/* <Route path="/create-blog" exact component={CreateBlog} /> */}
          {/* <Route path="/media-event" exact component={MediaEvent2} /> */}
          {/* <Route path="/create-blog" exact component={AdminPanel} /> */}
          <Route path="/admin" exact component={AdminMain} />
          <Route path="/addproducts" exact component={ProductForm} />
          <Route path="/productsNew" exact component={ProductsNew} />
          <Route path="/enquiryDashboard" exact component={EnquiryDetail} />

          <Route path="/blog/cats/:category" exact>
            <Blog sidebar="left" layout="grid" />
          </Route>

          <Route path="/blog/search/:query" exact>
            <Blog sidebar="left" layout="grid" />
          </Route>

          <Route path="/blogs" exact>
            <Blog sidebar="left" layout="grid" title="Blog" />
          </Route>

          <Route path="/blogpagenew" exact component={BlogSingle} />
          <Route render={() => <Redirect to={{pathname: "/"}} />} />
        </Switch>
      </Fragment>
    );
  }

  return <Fragment>{app}</Fragment>;
};

/*

<Route path="/blogs" exact>
            <Blog
              sidebar="right"
              layout="grid"
              title="Blog grid right sidebar"
            />
          </Route>

        <Route path="/blog-grid-without-sidebar" exact>
            <Blog sidebar="none" layout="grid" title="Blog grid no sidebar" />
          </Route>

          <Route path="/blog-list-left-sidebar" exact>
            <Blog sidebar="left" layout="list" title="Blog list left sidebar" />
          </Route>

        <Route path="/blog-list-right-sidebar" exact>
            <Blog
              sidebar="right"
              layout="list"
              title="Blog list right sidebar"
            />
          </Route>
        <Footer />

        <Route path="/blog/user/:author" exact>
            <Blog sidebar="left" layout="grid" />
          </Route>
          <Route path="/blog/date/:posting_date" exact>
            <Blog sidebar="left" layout="grid" />
          </Route>
          <Route MediaEvent
            path="/portfolio/:project_id"
            exact
            component={PortfolioSingle}
          />

          <Route path="/team/:member_id" exact component={MemberCard} />

          <ModalForm />
        <ModalVideo />
        <RequestForm />


*/