import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Header from "../../layouts/Header/Header.js";
import { Footer } from "../../layouts";
import firebase from "../../../utilities/Firebase";
import { Button, Typography } from "@material-ui/core";
import ProductDialog from "./ProductDialog.js";
import ProductImageDialog from "./Product-imageDialog";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import enquireicon from "../../../assets/placeholders/enquire.png";
import producticon from "../../../assets/placeholders/product-description.png";

let dataArray = [];
export default class ProductsNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      openProduct: false,
      productData: [],
      openProductImage: false,
    };
  }

  componentDidMount() {
    let product = firebase
      .firestore()
      .collection("walstrat")
      .doc("root")
      .collection("Products");
    product
      .where("user", "==", "admin@walstrat.com")
      .onSnapshot((querySnapshot) => {
        dataArray = [];
        querySnapshot.forEach((doc) => {
          const tempData = doc.data();
          // console.log('tempData ==>',doc.id)
          dataArray.push({
            productId: doc.id,
            name: tempData.name,
            description: tempData.description,
            dateAdded: tempData.dateAdded,
            imageCollection: tempData.imageCollection,
            image: tempData.image,
          });
        });
        console.log("dataarray =>", dataArray);
        this.setState({
          products: dataArray,
        });
      });
  }

  handleClickCloseProduct = () => {
    this.setState({
      openProduct: false,
      openProductImage: false,
    });
  };

  productInfo = (pId, pName, pDesc, pImage, pImagecollection, type) => {
    console.log("p data => ", pId, pName, pDesc, pImage, pImagecollection);
    let promies = new Promise((res, rej) => {
      this.setState({
        productData: [
          {
            id: pId,
            name: pName,
            description: pDesc,
            image: pImage,
            imagesCollection: pImagecollection,
          },
        ],
      });
      res(true);
    });
    promies.then((res) => {
      console.log("Product data promies", this.state.productData);
      this.setState({
        openProduct: type == "inquiry" && true,
        openProductImage: type == "image" && true,
      });
    });
  };

  render() {
    return (
      <div>
        <Header></Header>
        <section
          className="services-area section-padding"
          style={{ marginBottom: "30px" }}
        >
          <div className="container">
            <div className="layout-header layputpadding" id="products">
              <span className="subtitle">Our Products</span>
            </div>
          </div>
          <Grid container spacing={3} style={{ margin: "0px" , display : "flex", justifyContent : "center"}}>
            {this.state.products.map((product) => {
              return (
                <div style={{ margin: "15px" }}>
                  {/* <Grid container spacing={3}>
                    <Grid item> */}
                      <Paper style={{ padding: "2px", display: "flex" }}>
                        <Grid container spacing={3} style={{width : "300px"}}>
                          <Grid item md={12} xs={12}>
                            <img
                              onClick={() =>
                                this.productInfo(
                                  product.productId,
                                  product.name,
                                  product.description,
                                  product.image,
                                  product.imageCollection,
                                  "image"
                                )
                              }
                              style={{
                                width: "100px",
                                height: "100px",
                                margin: "0 auto",
                                cursor: "pointer",
                                display: "flex",
                              }}
                              className="Img radiuszero widthImg"
                              src={product.image}
                              alt={product.name}
                            />
                          </Grid>
                          <Grid
                            item
                            md={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              height : "70px"
                            }}
                          >
                            <br></br>
                            <Typography variant="h6" style={{ fontFamily: "Sitka" , fontWeight: "600" }}>{product.name}</Typography>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",

                            }}
                          >
                            <Button variant="contained" color="primary" style={{ margin: "5px", fontFamily: "Sitka" }} onClick={()=> (window.open("http://eurolatex.in/",'_blank'))}>
                              <ShoppingCartIcon
                                style={{ marginRight: "5px" }}
                              />{" "}
                              Buy Now
                            </Button>
                            <Button
                              onClick={() =>
                                this.productInfo(
                                  product.productId,
                                  product.name,
                                  product.description,
                                  product.image,
                                  product.imageCollection,
                                  "image"
                                )
                              }
                              style={{ margin: "5px", fontFamily: "Sitka" }}
                              variant="contained"
                              color="primary"
                            >
                              <img src={producticon} style={{ width: "14px", height: "12px", marginRight: "5px", marginBottom: "2px"}} />
                              View Product
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    {/* </Grid>
                  </Grid> */}
                </div>
              );
            })}
          </Grid>
        </section>
        {/* <ProductDialog
          open={this.state.openProduct}
          Product={this.state.productData}
          onClose={this.handleClickCloseProduct}
        /> */}

        <ProductImageDialog
          open={this.state.openProductImage}
          Product={this.state.productData}
          onClose={this.handleClickCloseProduct}
        />
        <div style={{ width: "100%", position: "fixed", bottom: "0" }}>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}
