import React, { Fragment } from "react";
import {
  Features,
  Features2,
  Subscribe,
  Services,
  Team,
  Services3,
  Contacts,
  Slider,
  Reviews,
  Facts,
} from "../../widgets";

import { useCustomState } from "../../../state/state";
import About from "../About/About";
import Services2 from "../../widgets/Services2/Services2";
import Box from "../box/Box";
import Contacts2 from "../../widgets/Contacts2/Contacts2";
import { Footer, Header } from "../../layouts";
import Servicepage3 from "../../widgets/Services2/servicepage3";
import Products from "../../widgets/Products/Products";
import BlogPage from "../Blog/BlogPage";
import Media from "../Media/Media";
import HomeTile from "../Blog/HomeTile";
import ThinkTank from "../Blog/ThinkTank";
import LazyLoad from "react-lazy-load";

export default () => {
  const state = useCustomState()[0];

  return (
    <Fragment>
      <Header data={state.data.menu} />

      <Slider data={state.data.posts} />
      <About />
      <Services2 data={state.data.services} />
      <Box />
      {/* <BlogPage /> */}
      <BlogPage></BlogPage>
      <Media />
      <ThinkTank></ThinkTank>
      {/* <HomeTile></HomeTile> */}
      <Contacts2 />
      <Footer />
      {/* <Blog /> */}
    </Fragment>
  );
};
