import React, { Component } from "react";
import Editor from "react-medium-editor";
import "medium-editor/dist/css/medium-editor.css";
import "medium-editor/dist/css/themes/default.css";
import BlogCards from "../components/pages/Blog/BlogList";
import { Table } from "react-bootstrap";
// import MUIRichTextEditor from "mui-rte";
// import TableChartIcon from "@material-ui/icons/TableChartIcon";
import { TextField } from "@material-ui/core";
// import Editor from "react-medium-editor-2";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
// import firebase from "firebase";
import firebase from "../utilities/Firebase";
// import { Row, Col } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { EditorState } from "draft-js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import logo from "../assets/placeholders/WALSTRAT LOGO-.png";
import "../css/adminblog.css";
// import adminvideo from '../assets/placeholders/adminvideo.mp4';
import Videojs from "./videojs";
import videoJsOptions from "./videojs-option";
import AdminFooter from "./layouts/Footer/adminFooter";

const db = firebase.firestore();
const collection = db.collection("walstrat/root/Posts");
const storage = firebase.storage();
// const drafts = db.collection("walstrat/Posts/Drafts");
export default class CreateBlog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      short: "",
      id: 1,
      author: "",
      docid: "",
      category_id: "",
      full: "",
      published: false,
      edit: false,
      postArray: [],
      image: null,
      url: "",
      isLogged: null,
      progress: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleImage = this.handleImage.bind(this);
  }
  handleImage = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
    }
  };
  handleUpload = () => {
    const { image } = this.state;
    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error) => {
        // error function ....
        console.log(error);
      },
      () => {
        storage
          .ref("images")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            // firebase
            //   .firestore()
            //   .collection("walstrat/")
            //   .add({
            //     imgURL: url,
            //   })
            //   .then(() => {
            //     this.setState({ url: "" });
            //   });
            this.setState({ url: url });
          });
      }
    );
  };
  componentDidMount() {
    if (localStorage.getItem("Isloggedin")) {
      collection.orderBy("id").onSnapshot((querySnapshot) => {
        const posts = [];
        querySnapshot.forEach((doc) => {
          let postObj = doc.data();
          postObj.docid = doc.id;
          posts.push(postObj);
          console.log("doc data", doc.data());
        });
        setTimeout(() => {
          if (posts.length > 0) {
            this.setState(
              {
                postArray: posts,
                id: parseInt(posts[posts.length - 1].id.split("post_")[1]) + 1,
              },

              () => console.log(posts)
            );
          } else {
            this.setState({
              postArray: posts,
              id: 1,
            });
          }
        }, 100);
      });
    } else {
      window.location.href = "/admin";
    }
  }

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  publish = () => {
    if (this.validate()) {
      console.log(this.state);
      let post = {
        id: "post_" + this.state.id.toString(),
        title: this.state.title,
        short: this.state.short,
        full: this.state.full,
        posting_date: new Date().getTime(),
        updating_date: new Date().getTime(),
        published: true,
        category_id: this.state.category_id,
        author: this.state.author,
        image: this.state.url,
      };
      this.pushToDB(post);
    }
  };

  saveAsDraft = () => {
    if (this.validate()) {
      let draft = {
        id: "post_" + this.state.id.toString(),
        title: this.state.title,
        short: this.state.short,
        full: this.state.full,
        posting_date: new Date().getTime(),
        updating_date: new Date().getTime(),
        published: false,
        category_id: this.state.category_id,
        author: this.state.author,
        image: this.state.url,
      };
      this.pushToDB(draft);
    }
  };

  pushToDB = (post) => {
    collection.add(post).then(
      () => {
        console.log("Data from DB ", post);
        console.table(this.state);
        this.cleanInput();
      }
      // () => {
      // }
    );
  };

  validate = () => {
    if (this.state.title === "") {
      alert("Please write title.");
      return false;
    } else if (this.state.full === "") {
      alert("Please write content!");
      return false;
    }
    // else if (this.state.post.length < 50) {
    //   alert("Please write more content !");
    //   return false;
    // }
    else if (this.state.author === "") {
      alert("Please enter your name.");
    } else if (this.state.category_id === "") {
      alert("Please select your post category.");
    } else {
      return true;
    }
  };

  editData = (key) => {
    this.setState({ edit: true });
    this.state.postArray.map((post) => {
      if (post.id === key) {
        this.setState(
          {
            id: parseInt(key.split("post_")[1]),
            title: post.title,
            short: post.short,
            author: post.author,
            category_id: post.category_id,
            full: post.full,
            posting_date: post.posting_date,
            updating_date: new Date().getTime(),
            docid: post.docid,
          },
          () => {
            console.log("edit data ", this.state);
          }
        );
      }
    });
  };

  deleteData = (key) => {
    collection
      .doc(key)
      .delete()
      .then(function () {
        console.log("Post successfully deleted!");
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  };

  update = () => {
    if (this.state.docid === "") {
      alert("Post not found");
      return;
    } else {
      collection
        .doc(this.state.docid)
        .set({
          id: "post_" + this.state.id.toString(),
          title: this.state.title,
          short: this.state.short,
          author: this.state.author,
          category_id: this.state.category_id,
          full: this.state.full,
          posting_date: this.state.posting_date,
          updating_date: new Date().getTime(),
        })
        .then(() => {
          this.setState({ edit: false }, () => this.cleanInput());
        });
    }
  };

  cleanInput = () => {
    this.setState({
      full: "",
      title: "",
      category_id: "",
      short: "",
      author: "",
      url: "",
      image: null,
    });
  };

  goTOLogin = () => {
    localStorage.clear();
    window.location.href = "/admin";
  };

  render() {
    return (
      <div>
        <div className="padding-blog">
          <div class="header-mainadmin">
            <h1 className="adminlogintext">Create Blog</h1>

            <div class="main-icon">
              <span class="fa fa-eercast"></span>
            </div>
            <div class="header-left-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <div class="icon1">
                      <input
                        fullWidth={true}
                        id="title"
                        placeholder="Title"
                        name="title"
                        value={this.state.title}
                        variant="outlined"
                        onChange={this.handleChange}
                        className="inputadmin"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div class="icon1">
                      <input
                        id="short"
                        placeholder="Short"
                        name="short"
                        value={this.state.short}
                        variant="outlined"
                        fullWidth={true}
                        onChange={this.handleChange}
                        className="inputadmin"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <div class="box" style={{ width: "100%" }}>
                      <select
                        id="category_id"
                        name="category_id"
                        value={this.state.category_id}
                        onChange={this.handleChange}
                      >
                        <option value="">Category ID</option>
                        <option value="0">Product</option>
                        <option value="1">Project</option>
                        <option value="2">Article</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div class="icon1">
                      <input
                        id="author"
                        placeholder="Written By"
                        name="author"
                        value={this.state.author}
                        variant="outlined"
                        fullWidth={true}
                        onChange={this.handleChange}
                        className="inputadmin"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <div class="icon1">
                      {!this.state.edit && (
                        <div>
                          <input
                            type="file"
                            onChange={this.handleImage}
                            style={{ width: "100%" }}
                            className="inputadmin"
                          />

                          <div className="flexchoose">
                            <progress value={this.state.progress} max="100" />

                            <button
                              onClick={this.handleUpload}
                              className="backtohomebtn"
                            >
                              Upload
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div class="icon1">
                      <textarea
                        id="full"
                        placeholder="Enter blog data"
                        multiline
                        rows={3}
                        name="full"
                        value={this.state.full}
                        fullWidth={true}
                        variant="outlined"
                        onChange={this.handleChange}
                        className="inputadmin"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-3 col-lg-3">
                    {this.state.edit ? (
                      <button
                        variant="contained"
                        color="primary"
                        onClick={this.update}
                        className="backtohomebtn"
                      >
                        Update
                      </button>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <button
                          variant="contained"
                          color="primary"
                          onClick={this.publish}
                          className="backtohomebtn marginrightpublish"
                        >
                          Publish
                        </button>
                        <button
                          variant="contained"
                          color="primary"
                          onClick={this.saveAsDraft}
                          className="backtohomebtn"
                        >
                          Draft
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Container
          fluid
          style={{
            paddingTop: "50px",
          }}
        >
          <BlogCards posts={this.state.postArray} />
        </Container>
      </div>
    );
  }
}
