/* eslint-disable  */
/* eslint-disable  */
/* eslint-disable import/first */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React from "react";
// import AlertDialog from "./AlertDialog";s
import firebase from "../../utilities/Firebase";
// import ProductList from "./product-list";
// import OrderLogs from "./order-logs";
// import StoreConfig from "./StoreConfig";
// import ExpoList from "./ExpoList";
// import StallConfig from "./StallConfig";
// import MelaAdmin from "./MelaAdmin";
// import MyProfile from "./MyProfile";
import TopBar from "./topbar";
import Sidebar from "./sidebar";
import "./adminmain.css";
// import Loader from "../viewer/vr/loader";
// import { UserContext } from "../Context";
// import UserList from '../admin/userlist';
import Dashboard from "./Dashboard";
import CreateBlog from "../CreateBlog";
import MediaEvent from "../media-event";
import ProductForm from "../add-product";
import EnquiryDetail from "../EnquiryDetails";
import AdminLogin from "../../AdminLogin";
import CreateBlog2 from "../CreateBlogCopy";
import MediaEvent2 from "../media-eventCopy";
// import UserLogs from "./ecommerce-user-logs";
// import ViwerUserLogs from "./ViewerUserLogs";
// import StallTemplate from "./StallTemplate";
// import StallList from "./StallList";
// import ExpoVisitorLogs from "./expo-userList";
// import ExpoVisitorImage from "./expo-userImageList";
// import ExpoRegisterVisitor from "./expo-registerUser";
// import ProjectViewer from "./ProjectView";

// import { userCredential } from '../user_credential'

class AdminMain extends React.Component {
  //   static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      isMobileNavOpen: false,
      pageName: "Dashboard",
      authStateSpin: false,
      user: { loggedIn: false },
      //   profileUrl: "",
      alertDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
    };
  }
  componentDidMount() {
    let userLoginStatus = localStorage.getItem("Isloggedin");
    this.setState({
      authStateSpin: userLoginStatus,
      user: { loggedIn: userLoginStatus },
    });
  }

  sideOptionSelected = (pageNameToLoad) => {
    this.setState({
      pageName: pageNameToLoad,
    });
  };

  render() {
    const pageName = this.state.pageName;
    return (
      <div>
        {this.state.authStateSpin ? (
          <div>
            <div
              style={
                ({ backgroundColor: "" },
                { display: "flex" },
                { height: 100 + "%" },
                { overflow: "hidden" },
                { width: 100 + "%" })
              }
            >
              <TopBar
                onMobileNavOpen={() =>
                  this.setState({
                    isMobileNavOpen: true,
                  })
                }
                sideOptionSelected={(value) => {
                  this.sideOptionSelected(value);
                }}
                //   userId={this.state.userId}
                //   isEcommarce={this.state.noorConfig.indexOf('Ecommerce')}
              />
              <Sidebar
                sideOptionSelected={(value) => this.sideOptionSelected(value)}
                onMobileClose={() => this.setState({ isMobileNavOpen: false })}
                openMobile={this.state.isMobileNavOpen}
                //   profileUrl={this.state.profileUrl}
              />
              <div className="margintopleft">
                <div>
                  {/* {console.log("user role :=>", localStorage.getItem('userRole'))} */}

                  {/* {this.state.pageName === "User Settings" && <UserSettings />} */}

                  {this.state.pageName === "Dashboard" && <Dashboard />}

                  {this.state.pageName === "blogs" && <CreateBlog2 />}

                  {this.state.pageName === "media" && <MediaEvent2 />}

                  {this.state.pageName === "products" && <ProductForm />}

                  {this.state.pageName === "Enquiry" && <EnquiryDetail />}

                  {/* {this.state.pageName === "Store Config" && <StoreConfig />} */}

                  {/* {this.state.pageName === "settings" && <AdminSetting />} */}

                  {/* {this.state.pageName === "UserList" && <UserList />}

                    {this.state.pageName === "ExpoVisitorLogs" && <ExpoVisitorLogs />}
                    {this.state.pageName === "ExpoVisitorImage" && <ExpoVisitorImage />}


                    {this.state.pageName === "ExpoRegisterVisitor" && <ExpoRegisterVisitor />}

                    {this.state.pageName === "StallList" && <StallList />}

                    {this.state.pageName === "StallTemplate" && <StallTemplate />}
                    {this.state.pageName === "Stall Config" && <StallConfig />}
                    {this.state.pageName === "Mela Admin" && <MelaAdmin />}
                    {this.state.pageName === "Project Viewer" && <ProjectViewer />}

                    {this.state.pageName === "Expo List" && <ExpoList loginData={this.state.loginData}  />}

                    {this.state.pageName === "My Profile" && <MyProfile />} */}
                </div>
              </div>
            </div>

            {/* <AlertDialog
                alertDialog={this.state.alertDialog}
                setAlertDialog={this.setAlertDialogfunc}
              /> */}
          </div>
        ) : (
          <AdminLogin />
        )}
      </div>
    );
  }
}

export default AdminMain;
