import React, { Component } from "react";
import "./box.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useHistory } from "react-router";
import projecticon from "../../../assets/placeholders/box-icon/project.png";
import producticon from "../../../assets/placeholders/box-icon/product logo.png";
import blogicon from "../../../assets/placeholders/box-icon/blog.png";
import movieicon from "../../../assets/placeholders/box-icon/movie.png";

const Box = () => {
  //   render() {
  const history = useHistory();

  const oniframe = () => {
    document.getElementById("Overlay").style.display = "block";
    document.getElementById("Videoid").style.display = "block";
    document.getElementById("Videoid").play();
  };

  const offvideo = () => {
    document.getElementById("Overlay").style.display = "none";
    // document.getElementById("Videoid").src = '';
    document.getElementById("Videoid").style.display = "block";
    document.getElementById("Videoid").pause();
  };

  return (
    <div>
      {/* <!--How We Works Start--> */}
      <section className="how-we-works">
        <div className="container">
          {/* <div className="section-title text-center">
                    <span className="section-title__tagline">3 easy steps</span>
                    <h2 className="section-title__title">How we works</h2>
                </div> */}
          <div className="row">
            <div className="col-xl-12">
              <ul className="list-unstyled how-we-works__single-list">
                <OwlCarousel
                  className="owl-theme"
                  items={4}
                  responsiveClass={true}
                  id="owlBasicBar"
                  loop={true}
                  nav={false}
                  autoplay={false}
                  rewind={true}
                  dots={true}
                  mouseDrag={false}
                  responsive={{
                    0: {
                      items: 1,
                      loop: false,
                    },
                    600: {
                      items: 1,
                      loop: false,
                    },
                    700: {
                      items: 2,
                      loop: false,
                    },
                    1000: {
                      items: 4,
                      loop: true,
                    },
                  }}
                >
                  {/* <!--How We Works Single--> */}
                  <li className="how-we-works__single">
                    <div className="how-we-works__icon-box">
                      <div
                        className="how-we-works__icon"
                        onClick={() => {
                          window.open("/projects", "_blank");
                        }}
                      >
                        <img
                          src={projecticon}
                          alt="projects"
                          className="how-we-works__icon_Image"
                        />
                        <span className="icon-team"></span>
                        {/* <div className="how-we-works__count">

                                        </div> */}
                      </div>
                    </div>
                    <h3
                      className="how-we-works__title"
                      onClick={() => {
                        window.open("/projects", "_blank");
                      }}
                    >
                      Projects
                    </h3>
                    <p className="how-we-works__text">
                    A showcase of our previous projects
                    </p>
                  </li>
                  {/* <!--How We Works Single--> */}
                  <li className="how-we-works__single">
                    <div className="how-we-works__icon-box">
                      <div
                        className="how-we-works__icon"
                        onClick={() => {
                          window.open("/products", "_blank");
                        }}
                      >
                        <span className="icon-checklist"></span>

                        <img
                          src={producticon}
                          alt="Products"
                          className="how-we-works__icon_Image"
                        />

                        {/* <div className="how-we-works__count">

                                        </div> */}
                      </div>
                    </div>
                    <h3
                      className="how-we-works__title"
                      onClick={() => {
                        window.open("/products", "_blank");
                      }}
                    >
                      Products
                    </h3>
                    <p className="how-we-works__text">
                    Check out our latest products 
                    </p>
                  </li>
                  <li className="how-we-works__single">
                    <div className="how-we-works__icon-box">
                      <div
                        className="how-we-works__icon"
                        onClick={() => {
                          history.push("/");
                          setTimeout(() => {
                            console.log("here in box");
                            let productEl = document.getElementById("blogpage");
                            if (productEl) {
                              productEl.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }, 500);
                        }}
                      >
                        <span className="icon-outsourcing"></span>
                        <img
                          src={blogicon}
                          alt="Blog/Media"
                          className="how-we-works__icon_Image"
                        />

                        {/* <div className="how-we-works__count">

                                        </div> */}
                      </div>
                    </div>
                    <h3
                      className="how-we-works__title"
                      onClick={() => {
                        history.push("/");
                        setTimeout(() => {
                          console.log("here in box");
                          let productEl = document.getElementById("blogpage");
                          if (productEl) {
                            productEl.scrollIntoView({
                              behavior: "smooth",
                            });
                          }
                        }, 500);
                      }}
                    >
                      Blog/Media
                    </h3>
                    <p className="how-we-works__text">
                    A gallery of our recent events and blogs
                    </p>
                  </li>
                  <li className="how-we-works__single">
                    <div className="how-we-works__icon-box">
                      <div className="how-we-works__icon">
                        <span className="icon-outsourcing"></span>
                        <img
                          src={movieicon}
                          onClick={() => oniframe()}
                          alt="Movie"
                          className="how-we-works__icon_Image"
                        />

                        {/* <div className="how-we-works__count">

                                        </div> */}
                      </div>
                    </div>
                    <div className="overlay" id="Overlay">
                      <div className="closebtn" onClick={() => offvideo()}>
                        X
                      </div>
                      <div className="videoposition">
                        <video
                          loop
                          controls
                          id="Videoid"
                          className="videowidth"
                        >
                          <source
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/whywalstrat-c.mp4"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div>
                    <h3
                      className="how-we-works__title"
                      onClick={() => oniframe()}
                    >
                      Movie
                    </h3>
                    <p className="how-we-works__text">
                      Explore the power of AR/VR
                    </p>
                  </li>
                </OwlCarousel>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!--How We Works End-->  */}
    </div>
  );
};
// }

export default Box;
