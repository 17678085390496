// import React, { Fragment } from "react";
// import {
//   Header,
//   TextBlock,
//   Process,
//   Facts2,
//   Portfolio,
//   Partners,
// } from "../../widgets";
// import { useCustomState } from "../../../state/state";

// export default () => {
//   const state = useCustomState()[0];

//   return (

//     <Fragment >
//       {/* <Header img={state.data.header_bgs.about}>About company</Header> */}
//       <TextBlock  />
//       {/* <Process data={state.data.process} />
//       <Facts2 data={state.data.facts2} />
//       <Portfolio data={state.data.portfolio} />
//       <Partners data={state.data.partners} /> */}
//     </Fragment>
//   );
// };
import React, { Component } from "react";
import Flip from "react-reveal/Flip";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import "../About/About.css";
import styles from "../../widgets/TextBlock//TextBlock.module.scss";
import Layout from "../../layouts/Layout/Layout";
import THEME from "../../../state/theme";
import { Button } from "../../elements";
import { useCustomState } from "../../../state/state";
import aboutimg from "../../../assets/placeholders/us.jpg";
import "../../widgets/TextBlock/about.css";
//import walstartvideo from '../../../assets/placeholders/walstartvideo.mp4';
import walstartimg from "../../../assets/placeholders/videoimage.png";
import logo from "../../../assets/placeholders/WALSTRAT LOGO-.png";
import Videojs from "../../../components/videojs";
// import LazyLoad from "react-lazy-load";
// import videoJsOptions from '../../../components/videojs-option'
// const videoJsOptions = {
//   autoplay: true,
//   muted: false,
//   controls: true,
//   fluid: true,
//   loop: true,
// };

export default class About extends Component {
  oniframe = () => {
    document.getElementById("Overlay").style.display = "block";
    // document.getElementById("body").style.overflow = "hidden";
    // document.getElementById("Videoid").src = 'assets/images/medex/medexvideo.mp4';
    document.getElementById("Videoid").style.display = "block";
    document.getElementById("Videoid").play();
  };
  offvideo = () => {
    document.getElementById("Overlay").style.display = "none";
    // document.getElementById("Videoid").src = '';
    document.getElementById("Videoid").style.display = "block";
    document.getElementById("Videoid").pause();
  };
  aboutpage = () => {
    window.location.href = "/aboutpage";
  };
  render() {
    return (
      <div>
        <div className="container" id="about">
          <div className="row">
            <div className="col-md-6 col-12 col-lg-6">
              <article
                className={styles.wrapper}
                style={{ paddingBottom: "0" }}
                id=""
              >
                <div className={styles.info}>
                  {/* <LazyLoad> */}
                  <Flip top>
                    <span
                      className={styles.subtitle}
                      style={{ color: THEME.color }}
                    >
                      About WALSTRAT
                    </span>
                  </Flip>
                  {/* </LazyLoad> */}
                  <Fade left>
                    {/* <h2>We create opportunity for new markets &amp; industries</h2> */}
                    {/* <LazyLoad>
                      <Fade left> */}
                    <p className={styles.paragraph}>
                      WALSTRAT is short for Walnut Strategies. The name, chosen
                      after considerable deliberation, carries great
                      symbolism. Walnuts have always been equated with
                      wisdom, intelligence and inspiration. A lot of writers have
                      used walnuts as a metaphor for strength and enduring
                      hardships. Walnuts also mark new beginnings. Walnut
                      Srategies aims at providing it's clients the whole gamut
                      of strategic services in the entire range of trade and
                      investment activities as a one-stop-shop.
                    </p>
                    {/* </Fade>
                    </LazyLoad>  */}

                    {/* <LazyLoad>
                      <Fade left> */}
                    <p className={styles.paragraph}>
                      The company is founded in September 2020 by Mrs.Jyoti Kaur
                      who had held senior positions in governmental,
                      quasi-governmental and private enterprises covering varied
                      fields, all focussing on trade and investment promotion,
                      The stakeholders who worked with her vouchsafe for her
                      vision, hard work, result-oriented approach, integrity and
                      dedication to the tasks entrusted to her. Her expertise
                      in providing practicable inputs in policy formulation for
                      trade policies that are aimed at trade promotion is well
                      recognised. She has held key positions in India's Apparel
                      Export Promotion Council, Services Export Promotion
                      Council, Plastics Export Promotion Council, to cite a
                      few. She has led highly successful business delegations to
                      Africa, Middle East, Central Asia, China, etc
                    </p>
                    {/* </Fade>
                    </LazyLoad>  */}

                    {/* <LazyLoad>
                      <Fade left> */}
                    <p
                      className={styles.paragraph}
                      style={{ marginBottom: "2rem" }}
                    >
                      After serving in various prestigious
                      government, semi-govetnment and private entities, it was
                      time for her to move on as an independent consultant to
                      continue giving her expertise and benefits of her global
                      business contacts to her peers and friends and the
                      business community. With that in mind she took voluntary
                      retirement to launch a streamlined consultancy
                      organization with clear focus. Thus WALSTRAT was born, with
                      her at the helm.
                    </p>
                    <p className="readmoretext" onClick={this.aboutpage}>
                      Read more
                    </p>
                    {/* </Fade>
                    </LazyLoad>  */}
                  </Fade>
                  {/* <div className={styles.btn_box}>
                    <Button to="/blog/post_4" hoverType="solid-gray-tb">
                      Read more
            </Button>

                  </div> */}
                </div>
              </article>
            </div>
            <div className="col-md-6 col-12 col-lg-6">
              <div className="borderabout"></div>
              <Zoom>
                <div className="flip-cardabout">
                  <div className="flip-cardabout-inner">
                    <div className="flip-cardabout-front">
                      {/* <LazyLoad> */}
                      <img src={aboutimg} alt="Avatar" />
                      {/* </LazyLoad> */}
                    </div>
                    <div
                      className="flip-cardabout-back"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <LazyLoad> */}
                      <img src={logo} alt="Avatar" style={{ height: "auto" }} />
                      {/* </LazyLoad> */}
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
          </div>
          {/* video section start */}
          {/* <!--==================================================================== 
            Start Video Section
        =====================================================================--> */}

          <section className="video-section pt-185">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {/* <LazyLoad> */}
                  <img
                    onClick={this.oniframe}
                    src={walstartimg}
                    style={{ width: "100%", cursor: "pointer" }}
                  />
                  {/* </LazyLoad> */}
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-6 col-lg-6" style={{ alignSelf: 'center' }}>
                  <h2 className="whytoattendtext"> WALSTRAT</h2>

                </div> */}
          </section>
          <div className="overlay" id="Overlay" onclick="offvideo()">
            <div className="closebtn" onClick={this.offvideo}>
              X
            </div>
            <div className="videoposition">
              <video loop controls id="Videoid" className="videowidth">
                <source
                  src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/whywalstrat-c.mp4"
                  type="video/mp4"
                />
              </video>
              {/* <Videojs
                {...videoJsOptions}
                src={
                  "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/adminvideo-c.mp4"
                }
                id={"Videoid"}
              /> */}
            </div>
          </div>
        </div>
      </div>

      // </div >
    );
  }
}
