/* eslint-disable  */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import EditIcon from "@material-ui/icons/Edit";

import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Container,
  Zoom,
  Grid,
  Divider,
  TextField,
  makeStyles,
  Typography,
  withStyles,
  CardActionArea,
  AppBar,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";
import "medium-editor/dist/css/medium-editor.css";
import "medium-editor/dist/css/themes/default.css";
import Slide from "@material-ui/core/Slide";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import useFileHandler from "./useFileHandler";
import firebase from "../utilities/Firebase";
import Page from "./widgets/Contacts2/page";
import ImageLoader from "./ImageLoader";
import Videojs from "./videojs";
import videoJsOptions from "./videojs-option";
import logo from "../assets/placeholders/WALSTRAT LOGO-.png";
import "../css/adminblog.css";
import "./addProduct.css";
import { AdminFooter } from "./layouts";
import Tab from "@material-ui/core/Tab";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

const StyledTextField = withStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    width: 300,
    "& .MuiInputBase-root": {
      height: 60,
      "& input": {
        textAlign: "left",
        fontSize: "12px",
      },
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      borderWidth: "1px",
    },
    "& .PrivateTabIndicator-colorSecondary-21": {
      backgroundColor: "#fff",
    }
  },
  indicator: {
    backgroundColor: "#fff",
  },
}))(TextField);

let url = [];
const db = firebase.firestore();
const collection = db.collection("walstrat/root/Products");
const storage = firebase.storage();

const ProductForm = ({ isLoading }) => {
  const [state, setState] = React.useState({
    open: false,
  });
  const { open } = state;

  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [field, setField] = useState({
    name: { value: "" },
    description: { value: "" },
    imageUrl: { value: "" },
    imageCollection: { value: [] },
    isEdit: false,
    docId: "",
    dateAdded: "",
  });
  const [productData, setProductData] = React.useState({
    value: [{}],
  });
  const [tabValue, setTabValue] = useState("1");

  let data = [];
  useEffect(() => {
    collection.onSnapshot((querySnapshot) => {
      const posts = [];
      querySnapshot.forEach((doc) => {
        let postObj = doc.data();
        postObj.docid = doc.id;
        posts.push(postObj);
      });
      console.log("doc data", posts);
      handleChangeData(posts);
      data = posts;
    });
  }, []);

  const handleChangeData = (newValue) => {
    console.log("doc data", newValue);

    setProductData({
      ...productData,
      value: newValue,
    });
  };

  const {
    imageFile,
    isFileLoading,
    onFileChange,
    removeImage,
    imagecountalert,
    setImagecountalert,
  } = useFileHandler({
    image: {},
    imageCollection: field.imageCollection.value,
  });

  const sanitizeNumber = (num) => Number(num.toString().replace(/^0*/, ""));
  // const [imagecountalert,setImagecountalert] = useState(false);

  const onProductNameInput = (value, error) => {
    setField({ ...field, name: { value: value.target.value, error } });
  };

  const onProductDescriptionInput = (value, error) => {
    setField({ ...field, description: { value: value.target.value, error } });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleImageAlert = () => {
    setImagecountalert(false);
  };

  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const editData = (key) => {
    productData.value.map((product) => {
      if (product.docid === key) {
        console.log("edit =>", product.imageCollection);
        setField({
          ...field,
          name: { value: product.name },
          description: { value: product.description },
          imageUrl: { value: product.image },
          imageCollection: { value: product.imageCollection },
          docId: product.docid,
          isEdit: true,
          dateAdded: product.dateAdded,
        });
        handleChangeTab("", "1");
      }
    });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    setLoading(true);
    if (field.name.value === "") {
      setState({ open: true });
      setAlertMsg("Please enter product name");
      setLoading(false);
    } else if (
      !(
        field.imageCollection.value.length == 0 ||
        imageFile.imageCollection.length == 0
      )
    ) {
      setState({ open: true });
      setAlertMsg("Please upload product photos");
      setLoading(false);
    } else {
      const newProduct = {};
      Object.keys(field).forEach((i) => {
        newProduct[i] = field[i].value;
      });

      let filearray;
      const promises = [];

      if (imageFile.imageCollection.length > 0) {
        filearray = imageFile.imageCollection;
        filearray.map((file) => {
          promises.push(getAllProductUrl(file.file));
        });
      } else {
        filearray = field.imageCollection.value;
      }

      Promise.all(promises).then((res) => {
        console.log("urls", url);
        if (imageFile.imageCollection.length == 0) {
          url = field.imageCollection.value;
        }
        let obj = url[0];
        const ref = firebase
          .firestore()
          .collection("walstrat")
          .doc("root")
          .collection("Products");
        if (field.isEdit) {
          ref
            .doc(field.docId)
            .update({
              imageCollection: url,
              name: field.name.value,
              description: field.description.value,
              name_lower: field.name.value.toLowerCase(),
              dateAdded: field.dateAdded,
              dateUpdated: new Date().getTime(),
              user: localStorage.getItem("email"),
              image: obj.url,
            })
            .then((res) => {
              url = [];
              setField({
                name: { value: "" },
                description: { value: "" },
                imageUrl: { value: "" },
                imageCollection: { value: [] },
                isEdit: false,
                docId: "",
                dateAdded: "",
              });
              setLoading(false);
            });
        }
        ref
          .add({
            imageCollection: url,
            name: field.name.value,
            description: field.description.value,
            name_lower: field.name.value.toLowerCase(),
            dateAdded: new Date().getTime(),
            dateUpdated: new Date().getTime(),
            user: localStorage.getItem("email"),
            image: obj.url,
          })
          .then((res) => {
            url = [];
            setField({
              name: { value: "" },
              description: { value: "" },
              imageUrl: { value: "" },
              imageCollection: { value: [] },
            });
            imageFile.imageCollection = [];
            setLoading(false);
          });
      });
    }
  };

  const handleClear = () => {
    url = [];
    imageFile.imageCollection = []
    setField({
      name: { value: "" },
      description: { value: "" },
      imageUrl: { value: "" },
      imageCollection: { value: [] },
      isEdit: false,
      docId: "",
      dateAdded: "",
    });
  };

  const getAllProductUrl = (file) =>
    new Promise((res, rej) => {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      const guid = s4() + s4() + s4() + s4();
      if (file) {
        const uploadImages = firebase
          .storage()
          .ref()
          .child(file.name)
          .put(file);
        uploadImages.on(
          "state_changed",
          (snapShot) => {},
          (err) => {
            console.log(err);
          },
          () => {
            firebase

              .storage()
              .ref()
              .child(file.name)
              .getDownloadURL()

              .then((fireBaseUrl) => {
                url.push({
                  id: guid,
                  url: fireBaseUrl,
                });
                res("success");
              })
              .catch((err) => {
                console.log("err", err);
                rej("failure");
              });
          }
        );
      }
    });

  return (
    <div>
      <Page title="Enquiry Details">
        <Container style={{ marginTop: "110px" }}>
          <Box display="flex" style={{ alignItems: "center", margin: "10px" }}>
            <Box
              flexGrow={1}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <Typography variant="h5" style={{ fontFamily: "Sitka, serif" }}>
                {tabValue == 1
                  ? field.isEdit
                    ? "Edit Product Form"
                    : "Add Product Form"
                  : "Product List"}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Card>
            <CardContent>
              <TabContext value={tabValue}>
                <AppBar
                  position="static"
                  style={{
                    color: "white",
                    background: "linear-gradient(60deg, #031163, #05716C)",
                  }}
                >
                  <TabList onChange={handleChangeTab} aria-label="profiletab" TabIndicatorProps={{
                    style: {
                      backgroundColor: "#fff",
                    }
                  }}>
                    <Tab
                      label="Add Product"
                      value="1"
                      style={{ fontFamily: "Montserrat", fontSize: "medium" }}
                    />
                    <Tab
                      label="Product List"
                      value="2"
                      style={{ fontFamily: "Montserrat", fontSize: "medium" }}
                    />
                  </TabList>
                </AppBar>
                <TabPanel value="1">
                  <form className="product-form" onSubmit={onSubmitForm}>
                    <Card>
                      <div style={{ display: "flex" }}>
                        <div className="column">
                          <div className="d-flex">
                            <div className="product-form-field">
                              <CardContent>
                                <Grid container spacing={3}>
                                  <Grid item md={6} xs={12}>
                                    <StyledTextField
                                      fullWidth
                                      label="Product Name"
                                      name="name"
                                      onChange={onProductNameInput}
                                      variant="standard"
                                      isRequired
                                      maxLength={60}
                                      placeholder="Product Name"
                                      type="text"
                                      value={field.name.value}
                                    />
                                  </Grid>
                                  <Grid item md={6} xs={12}>
                                    <StyledTextField
                                      fullWidth
                                      label="Product Description"
                                      name="description"
                                      onChange={onProductDescriptionInput}
                                      variant="standard"
                                      isRequired
                                      multiline
                                      placeholder="Product Description"
                                      type="text"
                                      value={field.description.value}
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </div>
                            &nbsp;
                          </div>
                          <br />
                          <div className="product-form-field">
                            <CardContent>
                              <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                  <Typography>Image Collection</Typography>
                                  <input
                                    disabled={isLoading}
                                    hidden
                                    id="product-input-file-collection"
                                    multiple
                                    onChange={(e) =>
                                      onFileChange(
                                        e,
                                        {
                                          name: "imageCollection",
                                          type: "multiple",
                                        },
                                        imageFile.imageCollection.length
                                      )
                                    }
                                    readOnly={isLoading}
                                    type="file"
                                    accept="image/jpeg,image/png,image/jpg"
                                  />

                                  {!isFileLoading && (
                                    <label htmlFor="product-input-file-collection">
                                      Choose Images
                                    </label>
                                  )}
                                </Grid>
                              </Grid>
                            </CardContent>
                          </div>
                          <div className="product-form-collection">
                            <div>
                              {imageFile.imageCollection.length >= 1 &&
                                imageFile.imageCollection.map((image) => (
                                  <div
                                    className="product-form-collection-image"
                                    key={image.id}
                                  >
                                    <IconButton
                                      className="product-form-delete-image"
                                      style={{
                                        padding: "0px",
                                        margin: "0 auto",
                                      }}
                                      onClick={() =>
                                        removeImage({
                                          id: image.id,
                                          name: "imageCollection",
                                        })
                                      }
                                      title="Delete Image"
                                      type="button"
                                    >
                                      <CancelRoundedIcon />
                                    </IconButton>

                                    <img
                                      style={{
                                        margin: "-22px 0px 0px 0px",
                                        padding: "0px",
                                      }}
                                      src={image.url}
                                    />
                                  </div>
                                ))}

                              {!imageFile.imageCollection.length && (
                                <Box style={{ display: "flex" }}>
                                  {field.imageCollection.value.length
                                    ? field.imageCollection.value.map(
                                        (url, index) => {
                                          return (
                                            <Card
                                              key={index}
                                              style={{
                                                width: "110px",
                                                margin: "5px",
                                                borderRadius: "10px",
                                              }}
                                            >
                                              <CardActionArea
                                                style={{ cursor: "default" }}
                                              >
                                                <img
                                                  src={url.url}
                                                  width="100px"
                                                  height="100px"
                                                  alt="product"
                                                  style={{ margin: " 0px 5px" }}
                                                />
                                              </CardActionArea>
                                            </Card>
                                          );
                                        }
                                      )
                                    : ""}
                                </Box>
                              )}
                            </div>
                            {loading ? (
                              <CircularProgress
                                color="secondary"
                                style={{ margin: "5px" }}
                                size={20}
                              />
                            ) : (
                              ""
                            )}
                            <Button
                              variant="contained"
                              style={{
                                background: "linear-gradient(60deg, #031163, #05716C)",
                                marginLeft: "10px",
                                color: "#fff"
                              }}
                              disabled={loading}
                              type="submit"
                            >
                              {field.isEdit ? "Update Product" : "Save Product"}
                            </Button>
                            {field.isEdit && (
                              <Button
                                type="button"
                                variant="contained"
                                style={{
                                  backgroundColor: "linear-gradient(60deg, #031163, #05716C)",
                                  marginLeft: "10px",
                                  color: "#fff"
                                }}
                                onClick={handleClear}
                              >
                                {"Add New Product"}
                              </Button>
                            )}
                          </div>
                          <br />
                        </div>
                        <br />
                        <Snackbar
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          open={open}
                          TransitionComponent={TransitionUp}
                          autoHideDuration={6000}
                          onClose={handleClose}
                          typeof="alert"
                          action={
                            <React.Fragment>
                              <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </React.Fragment>
                          }
                        >
                          <Alert severity="error">{alertMsg}</Alert>
                        </Snackbar>
                        <Snackbar
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          open={imagecountalert}
                          TransitionComponent={TransitionUp}
                          autoHideDuration={6000}
                          onClose={handleImageAlert}
                          message="Please, Add upto 5 Images.!!"
                          action={
                            <React.Fragment>
                              <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleImageAlert}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </React.Fragment>
                          }
                        />
                      </div>
                    </Card>
                  </form>
                </TabPanel>
                <TabPanel value="2">
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      {(productData.value
                        ? productData.value.length > 0
                        : false) && (
                        // <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow style={{ backgroundColor: "lightgrey" }}>
                              <TableCell>Product Name</TableCell>
                              <TableCell align="left">
                                Product Description
                              </TableCell>
                              <TableCell align="left">
                                product Added On
                              </TableCell>
                              <TableCell align="left">Updated On</TableCell>
                              <TableCell align="left">
                                Added/Updated By
                              </TableCell>
                              <TableCell align="left">Edit</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {productData.value.map((data, index) => {
                              return (
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    {data.name}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.description
                                      ? data.description.length > 50
                                        ? data.description.slice(0, 50) + " ..."
                                        : data.description
                                      : ""}
                                  </TableCell>

                                  <TableCell align="left">
                                    {data.dateAdded
                                      ? new Date(
                                          data.dateAdded
                                        ).toLocaleDateString("en-IN")
                                      : " - "}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.dateUpdated
                                      ? new Date(
                                          data.dateUpdated
                                        ).toLocaleDateString("en-IN")
                                      : " - "}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {data.user}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <EditIcon
                                      color={"inherit"}
                                      titleAccess={"Click to Edit"}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => editData(data.docid)}
                                      variant="contained"
                                      size="sm"
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                        // </TableContainer>
                      )}
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </CardContent>
          </Card>
        </Container>
      </Page>
    </div>
  );
};

ProductForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  product: PropTypes.shape({
    name: PropTypes.string,
    brand: PropTypes.string,
    price: PropTypes.number,
    maxQuantity: PropTypes.number,
    stock: PropTypes.number,
    description: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    image: PropTypes.string,
    availableColors: PropTypes.arrayOf(PropTypes.string),
    imageCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default ProductForm;
