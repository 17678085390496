/* eslint-disable */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import React, { useState, useEffect } from "react";
// need to add
import Page from "./widgets/Contacts2/page";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import adminvideo from "../assets/placeholders/adminvideo.mp4";
import logo from "../assets/placeholders/WALSTRAT LOGO-.png";

// import moment from "moment";
// import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import firebase from "../firebase";
import firebase from "../utilities/Firebase";
// import ExpoList from "./ExpoList";
// import Avatar from "@material-ui/core/Avatar";
// import imageUrl from "../common/utils/base-urls";
// import {
//   createProjectLocal,
//   updateProjectLocal,
// } from "../viewer/factoryjs/projectlist-factory";
// import template1 from "../../src/common/constants/Template1";
import TabContext from "@material-ui/lab/TabContext";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
// import { DropzoneArea } from "material-ui-dropzone";
// import {
//   Checkbox,
//   Fab,
//   IconButton,
//   ListItemText,
//   Paper,
// } from "@material-ui/core";
// import AddIcon from "@material-ui/icons/Add";

import AlertDialog from "./widgets/Contacts2/AlertDialog"; ///AlertDialog";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Container,
  Zoom,
  Grid,
  Divider,
  TextField,
  makeStyles,
  Typography,
  withStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
// import axios from "axios";
// import config from "../common/utils/base-urls";
// import { ContactsOutlined, PhotoCamera } from "@material-ui/icons";
// import { uploadFiles } from "../viewer/factoryjs/asset-uploader-factory";
import ConfirmDialog from "./widgets/Contacts2/ConfirmDialog";
import Videojs from "./videojs";
import videoJsOptions from "./videojs-option";

// const allTemplates = []

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    opacity: 0.1,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  formControl: {
    width: "100%",
    variant: "outlined",
  },
  input: {
    display: "none",
  },
  bgcolor: {
    background: "linear-gradient(60deg, #031163, #05716C) !important"
  }
}));
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

const db = firebase.firestore();
const collection = db.collection("walstrat/root/Posts");
const storage = firebase.storage();

const AdminPanel = ({ docid }) => {
  // ----------Create Blog Data-----------//
  const [blog, setBlog] = useState({
    title: "",
    short: "",
    id: 1,
    author: "",
    docid: "",
    category_id: "",
    full: "",
    published: false,
    edit: false,
    postArray: [],
    image: null,
    url: "",
    isLogged: null,
    progress: 0,
  });

  const classes = useStyles();
  const [backPage, setBackPage] = useState(true);
  const [updatedUserData, setUpdatedUserData] = useState({
    updatename: "",
    updateemail: "",
    updatepassword: "",
    imagelink: "",
    updatetheme: "",
    updatekey: "",
  });
  const [spin, setSpin] = useState(false);
  const [logoSpin, setLogoSpin] = useState(false);
  const [values, setValues] = useState({
    expoName: "",
    startDate: "",
    endDate: "",
    checkedName: false,
    checkedEmail: false,
    checkedMobile: false,
    checkedPassword: false,
    checkedEmailOTP: false,
    checkedMobileOTP: false,
  });
  const [tabValue, setTabValue] = React.useState("1");
  const [stallBannerUrl, setStallBannerUrl] = useState({
    logoUrl: "",
  });
  const [bottomMenu, setBottomMenu] = useState({
    receptionLobby: [{ type: "", icon: "", name: "receptionLobby" }],
    auditorium: [{ type: "", icon: "", name: "auditorium" }],
    filter: [{ type: "", icon: "", name: "filter" }],
    vrMode: [{ type: "", icon: "", name: "vrMode" }],
    logout: [{ type: "", icon: "", name: "logout" }],
  });
  const [bottomMenuOption, setBottomMenuOption] = useState({
    bottomMenuOption: [],
  });
  const bottomOption = [
    "ReceptionLobby",
    "Auditorium",
    "Filter",
    "VR MOde",
    "Logout",
  ];

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [stallBanner, setStallBanner] = useState("");

  const [alertDialog, setAlertDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const setAlertDialogfunc = () => {
    setAlertDialog({
      ...alertDialog,
      isOpen: false,
      title: "",
      subTitle: "",
    });
  };

  //   const handleUpdateChange = (event) => {
  //     setUpdatedUserData({
  //       ...updatedUserData,
  //       [event.target.name]: event.target.value,
  //     });
  //   };

  //   function onSaveFeed() {
  //     // console.log("bottomMenu in save", bottomMenu.receptionLobby[0]);
  //     console.log("bottomMenu in save", bottomMenu.receptionLobby[0]);
  //     let abc = [];
  //     bottomMenuOption.bottomMenuOption.map((bottomCheck) => {
  //       if (bottomCheck == "ReceptionLobby") {
  //         abc.push(bottomMenu.receptionLobby[0]);
  //       }
  //       if (bottomCheck == "Auditorium") {
  //         abc.push(bottomMenu.auditorium[0]);
  //       }
  //       if (bottomCheck == "Filter") {
  //         abc.push(bottomMenu.filter[0]);
  //       }
  //       if (bottomCheck == "VR MOde") {
  //         abc.push(bottomMenu.vrMode[0]);
  //       }
  //       if (bottomCheck == "Logout") {
  //         abc.push(bottomMenu.logout[0]);
  //       }
  //     });
  //     // let bottomCheck = [
  //     //   bottomMenu.receptionLobby[0],
  //     //   bottomMenu.auditorium[0],
  //     //   bottomMenu.filter[0],
  //     //   bottomMenu.vrMode[0],
  //     //   bottomMenu.logout[0]
  //     // ];
  //     // let bottom = [];
  //     // bottomCheck.map(bottomObj => {
  //     //   if (bottomObj.icon != "") {
  //     //     bottom.push(bottomObj);
  //     //   }
  //     // });
  //     console.log("bottom menu list item", abc);

  //     if (values.expoName === "") {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Enter expo name",
  //       });
  //     } else if (values.startDate.length === 0) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Select Start Date",
  //       });
  //     } else if (values.endDate.length === 0) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Select End Date",
  //       });
  //     } else if (!values.checkedName) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Select Name",
  //       });
  //     } else if (!values.checkedEmail) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Select Email",
  //       });
  //     } else if (!values.checkedMobile) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Select Mobile",
  //       });
  //     } else if (!values.checkedPassword) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Select Password",
  //       });
  //     } else if (!values.checkedEmailOTP) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Select Email OTP",
  //       });
  //     } else if (!values.checkedMobileOTP) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Select Mobile OTP",
  //       });
  //     } else if (!exteriorImage) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Upload Exterior Image",
  //       });
  //     } else if (!receptionLobbyImage) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Upload Reception Lobby Image",
  //       });
  //     } else if (!auditoriumImage) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Upload Auditorium Image",
  //       });
  //     } else if (!outsideDomImage) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Upload Outside Dom Image",
  //       });
  //     } else if (!insideDomImage) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Upload Inside Dom Image",
  //       });
  //     } else if (abc.length === 0) {
  //       setAlertDialog({
  //         isOpen: true,
  //         title: "Select Atlist One Bottom Menu Item",
  //       });
  //     } else {
  //       setSpin(true);
  //       // moment().format('x')
  //       console.log("MY VALUES :: ", values);
  //       console.log("MY exteriorImage :: ", exteriorImage);
  //       console.log("MY receptionLobbyImage :: ", receptionLobbyImage);
  //       console.log("MY auditoriumImage :: ", auditoriumImage);
  //       console.log("MY outsideDomImage :: ", outsideDomImage);
  //       console.log("MY insideDomImage :: ", insideDomImage);
  //       console.log("MY bottom :: ", abc);
  //       setSpin(false);

  //       // createProjectLocal(values.expoName, "Change Your Description Here").then(respon => {
  //       //     console.warn("Project created", respon.data._id)
  //       //     axios.post(`${config.serverUrl}mela/register`, {
  //       //     userName: values.userName,
  //       //     // logo: url,
  //       //     email: values.email,
  //       //     password: values.expoName,
  //       //     secretKey: respon.data._id,
  //       //     expo: values.expoName,
  //       //     userRole: 'admin',
  //       //     template: { theme: values.theme, bottomMenu: [], basicDetails: {} }
  //       //     }).then(res => {
  //       //     console.log('newly created project', res)
  //       //     setValues({
  //       //         ...values,
  //       //         key: respon.data._id
  //       //     })
  //       //     const projectId = respon.data._id
  //       //     // Scene template manipulation should be here

  //       //     let myTemplate = expoTemplate1;
  //       //     updateProjectLocal({
  //       //         _id: projectId,
  //       //         scenes: myTemplate.scenes,
  //       //     }).then(result => {
  //       //         setSpin(false)
  //       //         const backValue = false;
  //       //         redirectBack(backValue);
  //       //     }).catch(err => {
  //       //         console.log('some error occured', err)
  //       //         // setSceneAdding(false)
  //       //         setSpin(false)
  //       //     })
  //       //     }).catch(err => {
  //       //     console.log(err)
  //       //     setSpin(false);

  //       //     })
  //       // })
  //     }
  //   }

  //   function onUpdateFeed() {
  //     axios
  //       .put(`${config.serverUrl}mela/${docid}`, {
  //         stallName: updatedUserData.updatename,
  //         stallEmail: updatedUserData.updateemail,
  //       })
  //       .then((res) => {
  //         const backValue = false;
  //         redirectBack(backValue);
  //         setSpin(false);
  //         console.log(res.data);
  //         setAlertDialog({
  //           isOpen: true,
  //           title: "Stall User Updated",
  //         });
  //       })
  //       .catch((err) => {
  //         setSpin(false);

  //         console.log(err);
  //       });
  //   }

  useEffect(() => {
    collection.orderBy("id").onSnapshot((querySnapshot) => {
      const posts = [];
      querySnapshot.forEach((doc) => {
        let postObj = doc.data();
        postObj.docid = doc.id;
        posts.push(postObj);
        console.log("doc data", doc.data());
      });
      setTimeout(() => {
        if (posts.length > 0) {
          setBlog(
            {
              ...blog,
              postArray: posts,
              id: parseInt(posts[posts.length - 1].id.split("post_")[1]) + 1,
            },
            () => console.log(posts)
          );
        } else {
          this.setState({
            postArray: posts,
            id: 1,
          });
        }
      }, 100);
    });
  }, []);

  function redirectBack(backToLoad) {
    setBackPage(backToLoad);
  }

  function onBacktoHome() {
    // setBackHome(true)
    const backValue = false;
    redirectBack(backValue);
  }

  //   function guid() {
  //     function s4() {
  //       return Math.floor((1 + Math.random()) * 0x10000)
  //         .toString(16)
  //         .substring(1);
  //     }
  //     return String(
  //       s4() +
  //         s4() +
  //         "-" +
  //         s4() +
  //         "-" +
  //         s4() +
  //         "-" +
  //         s4() +
  //         "-" +
  //         s4() +
  //         s4() +
  //         s4()
  //     );
  //   }

  //   const getUserData = (docid) => {
  //     let docRef = firebase.firestore().collection("StallList").doc(docid);

  //     docRef
  //       .get()
  //       .then(function (doc) {
  //         if (doc.exists) {
  //           return doc.data();
  //         } else {
  //           console.log("No such document!");
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log("Error getting document:", error);
  //       });
  //   };

  const handleBlogDataChange = (event) => {
    setBlog({
      ...blog,
      [event.target.name]: event.target.value,
    });
  };

  const onBlogFileUpload = (e) => {
    const { image } = e.target.files[0];
    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // To Display Progress of image upload
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setBlog({
          ...blog,
          progress: progress,
        });
      },
      (error) => {
        console.log("Blog image file error", error);
      },
      () => {
        storage
          .ref("images")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            setBlog({
              ...blog,
              url: url,
            });
          });
      }
    );
  };

  const publish = () => {
    if (validate()) {
      console.log(blog);
      let post = {
        id: "post_" + blog.id.toString(),
        title: blog.title,
        short: blog.short,
        full: blog.full,
        posting_date: new Date().getTime(),
        updating_date: new Date().getTime(),
        published: true,
        category_id: blog.category_id,
        author: blog.author,
        image: blog.url,
      };
      pushToDB(post);
    }
  };

  const validate = () => {
    if (blog.title === "") {
      alert("Please write title.");
      return false;
    } else if (blog.full === "") {
      alert("Please write content!");
      return false;
    } else if (blog.author === "") {
      alert("Please enter your name.");
    } else if (blog.category_id === "") {
      alert("Please select your post category.");
    } else {
      return true;
    }
  };

  const pushToDB = (post) => {
    collection.add(post).then(() => {
      console.log("Data from DB ", post);
      console.table(this.state);
      cleanInput();
    });
  };

  const cleanInput = () => {
    setBlog({
      full: "",
      title: "",
      category_id: "",
      short: "",
      author: "",
      url: "",
      image: null,
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //   const handleStallBannerChange = (e) => {
  //     let fieldname = e.target.name;
  //     // console.log("fieldname", fieldname);
  //     uploadFiles([e.target.files[0]], "widgetimage", true).then((url) => {
  //       let iconName = bottomMenu[fieldname][0].name
  //         ? bottomMenu[fieldname][0].name
  //         : fieldname;
  //       setBottomMenu({
  //         ...bottomMenu,
  //         [fieldname]: [{ type: fieldname, icon: url[0], name: iconName }],
  //       });
  //       // bottomMenu[fieldname][0].icon = url[0];
  //       // console.log(url[0]);
  //     });
  //   };

  //   const handleChange = (e) => {
  //     // console.log("e name", e.target.name);
  //     // console.log("e name", e.target.value);
  //     let fieldname = e.target.name;
  //     let image = bottomMenu[fieldname][0].icon
  //       ? bottomMenu[fieldname][0].icon
  //       : "";
  //     // console.log("image value", image);
  //     setBottomMenu({
  //       ...bottomMenu,
  //       [fieldname]: [{ type: e.target.name, icon: image, name: e.target.value }],
  //     });
  //   };

  //   const handleChangeDropdown = (event) => {
  //     // console.log(event.target.name);
  //     // console.log(event.target.value);
  //     // console.log("bottomMenu", bottomMenu);
  //     setBottomMenuOption({
  //       ...bottomMenuOption,
  //       [event.target.name]: event.target.value,
  //     });
  //   };

  //   const deleteDialogConfirmationforicon = (fieldName) => {
  //     let iconName = bottomMenu[fieldName][0].name
  //       ? bottomMenu[fieldName][0].name
  //       : fieldName;
  //     setBottomMenu({
  //       ...bottomMenu,
  //       [fieldName]: [{ type: fieldName, icon: "", name: iconName }],
  //     });

  //     setConfirmDialog({
  //       ...confirmDialog,
  //       isOpen: false,
  //     });
  //   };

  // handleSwitchAllChange = e => {
  //     console.log(e.target.checked);
  //     this.setState({
  //       enableAllSwitch: e.target.checked
  //     });
  //   };

  //   const handleDateChange = (event) => {
  //     var myDate = event.target.value;
  //     console.log("Date =>", myDate);
  //     setValues({
  //       ...values,
  //       [event.target.name]: myDate,
  //     });
  //     console.log("caseDetails", values);
  //   };

  //   const handleEndDateChange = (event) => {
  //     var myDate = event.target.value;
  //     console.log("Date =>", myDate);
  //     setValues({
  //       ...values,
  //       [event.target.name]: myDate,
  //     });
  //     console.log("caseDetails", values);
  //   };

  //   const handleCheckbox = (event) => {
  //     setValues({ ...values, [event.target.name]: event.target.checked });
  //   };

  //   const [exteriorImage, setExteriorImage] = useState("");
  //   const [receptionLobbyImage, setLobbyImage] = useState("");
  //   const [auditoriumImage, setAuditoriumImage] = useState("");
  //   const [outsideDomImage, setOutSideDomeImage] = useState("");
  //   const [insideDomImage, setInSideDomeImage] = useState("");

  //   const [exteriorImageUrl, setExteriorImageUrl] = useState({
  //     logoUrl: "",
  //     lobbyUrl: "",
  //     auditoriumUrl: "",
  //     outsideDomUrl: "",
  //     insideDomUrl: "",
  //   });

  //   const handleExteriorImageChange = (e) => {
  //     e.preventDefault();
  //     const image = e.target.files[0];
  //     console.log("Image :: ", image);
  //     setExteriorImage(e.target.files[0]);
  //   };

  //   const handleLobbyImageChange = (e) => {
  //     e.preventDefault();
  //     const image = e.target.files[0];
  //     console.log("Image :: ", image);
  //     setLobbyImage(e.target.files[0]);
  //   };

  //   const handleAuditoriumImageChange = (e) => {
  //     e.preventDefault();
  //     const image = e.target.files[0];
  //     console.log("Image :: ", image);
  //     setAuditoriumImage(e.target.files[0]);
  //   };

  //   const handleOutsideDomImageChange = (e) => {
  //     e.preventDefault();
  //     const image = e.target.files[0];
  //     console.log("Image :: ", image);
  //     setOutSideDomeImage(e.target.files[0]);
  //   };

  //   const handleInsideDomImageChange = (e) => {
  //     e.preventDefault();
  //     const image = e.target.files[0];
  //     console.log("Image :: ", image);
  //     setInSideDomeImage(e.target.files[0]);
  //   };
  //   const onFeaturedCheckChange = (event) => {
  //     // setValues({
  //     //   ...values,
  //     //   [event.target.name]: event.target.checked
  //     // });
  //   };
  const goTOLogin = () => {
    window.location.href = "/admin";
  };

  return (
    <div>
      {backPage && (
        <div>
          <Page className={classes.root} title="Admin Panel">
          <div id="video-viewadmin" >
            {/* <video autoPlay loop muted>
              <source src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/adminvideo-c.mp4" type="video/mp4" />
            </video> */}
           <Videojs {...videoJsOptions} src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/adminvideo-c.mp4"></Videojs>
          </div>
          <div className="serviceheader">
            <img src={logo} className="widthlogoslider positionlogo"></img>
            <button onClick={goTOLogin} className="backtohomebtn">
              Logout
            </button>
          </div>
            <Container maxWidth={false}>
              <div>
                <Box>
                  <Box flexGrow={1}>
                    <Typography variant="h4" className={classes.bgcolor}>Admin Panel</Typography>
                  </Box>
                </Box>
                <hr />
                <form autoComplete="off" noValidate className={classes.root}>
                  <Card>
                    {/* <CardHeader
                    avatar={
                      <ArrowBackIcon
                        color="primary"
                        variant="contained"
                        onClick={() => onBacktoHome()}
                        style={{ margin: "5px" }}
                      />
                    }
                  <Divider />
                  /> */}
                    <CardContent>
                      <Grid container spacing={3}>
                        <TabContext value={tabValue}>
                          <AppBar position="static">
                            <TabList
                              onChange={handleTabChange}
                              aria-label="profiletab"
                            >
                              <Tab label="Create Blog" value="1" />
                              <Tab label="Media House" value="2" />
                              {/* <Tab label="Background" value="3" />
                            <Tab label="Bottom Menu" value="4" /> */}
                            </TabList>
                          </AppBar>
                          <TabPanel value="1">
                            <Grid container spacing={3}>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Blog Title"
                                  name="title"
                                  onChange={handleBlogDataChange}
                                  required
                                  value={blog.title}
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item md={6} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Blog Short Description"
                                  name="short"
                                  onChange={handleBlogDataChange}
                                  required
                                  value={blog.short}
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item md={6} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Blog Written By"
                                  name="author"
                                  onChange={handleBlogDataChange}
                                  required
                                  value={blog.author}
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item md={6} xs={12}>
                                <TextField
                                  multiline
                                  fullWidth
                                  label="Blog Feed"
                                  name="full"
                                  onChange={handleBlogDataChange}
                                  required
                                  value={blog.full}
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item md={6} xs={12}>
                                <Autocomplete
                                  id="combo-box-demo"
                                  options={["Product", "Project", "Artical"]}
                                  getOptionLabel={(option) => option}
                                  style={{ width: 300 }}
                                  onChange={(category) => {
                                    setBlog({
                                      ...blog,
                                      category_id: category,
                                    });
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Blog Category"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid item md={6} xs={12}>
                                <input
                                  className={classes.input}
                                  accept="image/*"
                                  id="contained-button-file"
                                  type="file"
                                  hidden
                                  onChange={onBlogFileUpload}
                                />
                                <label htmlFor="contained-button-file">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                  >
                                    Upload
                                  </Button>
                                </label>
                                {/* <LinearProgressWithLabel value={blog.progress} /> */}
                              </Grid>

                              {/* <Grid item md={6} xs={12}>
                              <TextField
                                id="startdate"
                                label="Start Date"
                                // type="datetime-local"
                                type="date"
                                InputProps={{
                                  inputProps: {
                                    min: moment().format("YYYY-MM-DD"),
                                    max: "2999-05-04"
                                  }
                                }}
                                value={values.startDate}
                                name="startDate"
                                format="dd/MM/yyyy"
                                onChange={handleDateChange}
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                variant="outlined"
                                style={{
                                  width: "100%",
                                  marginLeft: "0",
                                  marginRight: "0"
                                }}
                              />
                            </Grid> */}

                              {/* <Grid item md={6} xs={12}>
                              <TextField
                                id="enddate"
                                label="End Date"
                                type="date"
                                InputProps={{
                                  inputProps: {
                                    min: moment().format("YYYY-MM-DD"),
                                    max: "2999-05-04"
                                  }
                                }}
                                value={values.endDate}
                                name="endDate"
                                format="dd/MM/yyyy"
                                onChange={handleEndDateChange}
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                variant="outlined"
                                style={{
                                  width: "100%",
                                  marginLeft: "0",
                                  marginRight: "0"
                                }}
                              />
                            </Grid> */}
                            </Grid>
                          </TabPanel>
                          {/* <TabPanel value="2">
                          <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkedName}
                                    onChange={handleCheckbox}
                                    name="checkedName"
                                    color="primary"
                                  />
                                }
                                label="Name"
                              />
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkedEmail}
                                    onChange={handleCheckbox}
                                    name="checkedEmail"
                                    color="primary"
                                  />
                                }
                                label="Email"
                              />
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkedMobile}
                                    onChange={handleCheckbox}
                                    name="checkedMobile"
                                    color="primary"
                                  />
                                }
                                label="Mobile Number"
                              />
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkedPassword}
                                    onChange={handleCheckbox}
                                    name="checkedPassword"
                                    color="primary"
                                  />
                                }
                                label="Password"
                              />
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkedEmailOTP}
                                    onChange={handleCheckbox}
                                    name="checkedEmailOTP"
                                    color="primary"
                                  />
                                }
                                label="Email OTP Verification"
                              />
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkedMobileOTP}
                                    onChange={handleCheckbox}
                                    name="checkedMobileOTP"
                                    color="primary"
                                  />
                                }
                                label="Mobile OTP Verification"
                              />
                            </Grid>
                          </Grid>
                        </TabPanel> */}
                          {/* <TabPanel value="3">
                          <Grid container spacing={3}>
                            <label htmlFor="exteriorImage">
                              <input
                                style={{ display: "none" }}
                                id="exteriorImage"
                                name="exteriorImage"
                                type="file"
                                onChange={handleExteriorImageChange}
                              />
                              <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"
                              >
                                <AddIcon />
                                Upload Exterior Image
                              </Fab>
                            </label>
                            <br />
                            <Avatar
                              src={exteriorImageUrl.logoUrl}
                              className={classes.large}
                              onClick={() =>
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    "Are you sure you want to Delete this logo?",
                                  subTitle:
                                    "Once deleted, you can't undo this action",
                                  onConfirm: () => {
                                    deleteDialogConfirmationforlogo(
                                      exteriorImageUrl.logoUrl
                                    );
                                  }
                                })
                              }
                            />
                            <span>{exteriorImage.name}</span>
                          </Grid>

                          <Grid container spacing={3}>
                            <label htmlFor="receptionLobbyImage">
                              <input
                                style={{ display: "none" }}
                                id="receptionLobbyImage"
                                name="receptionLobbyImage"
                                type="file"
                                onChange={handleLobbyImageChange}
                              />

                              <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"
                              >
                                <AddIcon />
                                Upload Reception Lobby Image
                              </Fab>
                            </label>
                            <br />
                            <Avatar
                              src={exteriorImageUrl.lobbyUrl}
                              className={classes.large}
                              onClick={() =>
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    "Are you sure you want to Delete this logo?",
                                  subTitle:
                                    "Once deleted, you can't undo this action",
                                  onConfirm: () => {
                                    deleteDialogConfirmationforlogo(
                                      exteriorImageUrl.lobbyUrl
                                    );
                                  }
                                })
                              }
                            />
                            <span>{receptionLobbyImage.name}</span>
                          </Grid>

                          <Grid container spacing={3}>
                            <label htmlFor="auditoriumImage">
                              <input
                                style={{ display: "none" }}
                                id="auditoriumImage"
                                name="auditoriumImage"
                                type="file"
                                onChange={handleAuditoriumImageChange}
                              />

                              <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"
                              >
                                <AddIcon />
                                Upload Auditorium Image
                              </Fab>
                            </label>
                            <br />
                            <Avatar
                              src={exteriorImageUrl.auditoriumUrl}
                              className={classes.large}
                              onClick={() =>
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    "Are you sure you want to Delete this logo?",
                                  subTitle:
                                    "Once deleted, you can't undo this action",
                                  onConfirm: () => {
                                    deleteDialogConfirmationforlogo(
                                      exteriorImageUrl.auditoriumUrl
                                    );
                                  }
                                })
                              }
                            />
                            <span>{auditoriumImage.name}</span>
                          </Grid>

                          <Grid container spacing={3}>
                            <label htmlFor="outsideDomImage">
                              <input
                                style={{ display: "none" }}
                                id="outsideDomImage"
                                name="outsideDomImage"
                                type="file"
                                onChange={handleOutsideDomImageChange}
                              />

                              <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"
                              >
                                <AddIcon />
                                Upload Outside-Dom Image
                              </Fab>
                            </label>
                            <br />
                            <Avatar
                              src={exteriorImageUrl.outsideDomUrl}
                              className={classes.large}
                              onClick={() =>
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    "Are you sure you want to Delete this logo?",
                                  subTitle:
                                    "Once deleted, you can't undo this action",
                                  onConfirm: () => {
                                    deleteDialogConfirmationforlogo(
                                      exteriorImageUrl.outsideDomUrl
                                    );
                                  }
                                })
                              }
                            />
                            <span>{outsideDomImage.name}</span>
                          </Grid>

                          <Grid container spacing={3}>
                            <label htmlFor="insideDomImage">
                              <input
                                style={{ display: "none" }}
                                id="insideDomImage"
                                name="insideDomImage"
                                type="file"
                                onChange={handleInsideDomImageChange}
                              />

                              <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"
                              >
                                <AddIcon />
                                Upload Inside-Dom Image
                              </Fab>
                            </label>
                            <br />
                            <Avatar
                              src={exteriorImageUrl.insideDomUrl}
                              className={classes.large}
                              onClick={() =>
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    "Are you sure you want to Delete this logo?",
                                  subTitle:
                                    "Once deleted, you can't undo this action",
                                  onConfirm: () => {
                                    deleteDialogConfirmationforlogo(
                                      exteriorImageUrl.insideDomUrl
                                    );
                                  }
                                })
                              }
                            />
                            <span>{insideDomImage.name}</span>
                          </Grid>
                        </TabPanel>
                        <TabPanel value="4">
                          <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <InputLabel
                                  fullWidth
                                  id="demo-mutiple-checkbox-label"
                                >
                                  Bottom Menu Option
                                </InputLabel>
                                <Select
                                  style={{
                                    minWidth:
                                      bottomMenuOption.bottomMenuOption == 0
                                        ? "175px"
                                        : "0px"
                                  }}
                                  labelId="demo-mutiple-checkbox-label"
                                  id="demo-mutiple-checkbox"
                                  multiple
                                  label="bottomMenuOption"
                                  inputProps={{
                                    name: "bottomMenuOption",
                                    id: "demo-mutiple-checkbox-label"
                                  }}
                                  value={bottomMenuOption.bottomMenuOption}
                                  onChange={handleChangeDropdown}
                                  renderValue={selected => selected.join(", ")}
                                >
                                  {bottomOption.map(name => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox
                                        checked={
                                          bottomMenuOption.bottomMenuOption
                                            ? bottomMenuOption.bottomMenuOption.includes(
                                                name
                                              )
                                            : false
                                        }
                                      />

                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {bottomMenuOption.bottomMenuOption.includes(
                              "ReceptionLobby"
                            ) && (
                              <Grid item md={6} xs={12}>
                                <TextField
                                  fullWidth
                                  label="ReceptionLobby Icon Name"
                                  name="receptionLobby"
                                  onChange={handleChange}
                                  required
                                  value={bottomMenu["receptionLobby"][0].name}
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                            {bottomMenuOption.bottomMenuOption.includes(
                              "ReceptionLobby"
                            ) && (
                              <Grid item md={5} xs={12}>
                                <label htmlFor="ReceptionLobbyIcon">
                                  <input
                                    style={{ display: "none" }}
                                    id="ReceptionLobbyIcon"
                                    accept="image/png, image/jpeg"
                                    name="receptionLobby"
                                    type="file"
                                    onChange={handleStallBannerChange}
                                  />
                                  <Fab
                                    color="primary"
                                    size="small"
                                    component="span"
                                    aria-label="add"
                                    variant="extended"
                                  >
                                    {logoSpin ? (
                                      <CircularProgress
                                        color="secondary"
                                        style={{ margin: "5px" }}
                                        size={20}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <AddIcon />
                                    Upload ReceptionLobby Icon
                                  </Fab>
                                </label>
                              </Grid>
                            )}
                            {bottomMenuOption.bottomMenuOption.includes(
                              "ReceptionLobby"
                            ) && (
                              <Grid item md={1} xs={12}>
                                <Avatar
                                  src={
                                    imageUrl.images +
                                    bottomMenu.receptionLobby[0].icon
                                  }
                                  className={classes.large}
                                  onClick={() =>
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "Are you sure you want to Delete ReceptionLobby icon?",
                                      subTitle:
                                        "Once deleted, you can't undo this action",
                                      onConfirm: () => {
                                        deleteDialogConfirmationforicon(
                                          "receptionLobby"
                                        );
                                      }
                                    })
                                  }
                                />
                              </Grid>
                            )}

                            {bottomMenuOption.bottomMenuOption.includes(
                              "Auditorium"
                            ) && (
                              <Grid item md={6} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Auditorium Icon Name"
                                  name="auditorium"
                                  onChange={handleChange}
                                  required
                                  value={bottomMenu["auditorium"][0].name}
                                  variant="outlined"
                                />
                                {/* </FormControl> 
                              </Grid>
                            )}
                            {bottomMenuOption.bottomMenuOption.includes(
                              "Auditorium"
                            ) && (
                              <Grid item md={5} xs={12}>
                                <label htmlFor="AuditoriumIcon">
                                  <input
                                    style={{ display: "none" }}
                                    id="AuditoriumIcon"
                                    accept="image/png, image/jpeg"
                                    name="auditorium"
                                    type="file"
                                    onChange={handleStallBannerChange}
                                  />
                                  <Fab
                                    color="primary"
                                    size="small"
                                    component="span"
                                    aria-label="add"
                                    variant="extended"
                                  >
                                    {logoSpin ? (
                                      <CircularProgress
                                        color="secondary"
                                        style={{ margin: "5px" }}
                                        size={20}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <AddIcon />
                                    Upload Auditorium Icon
                                  </Fab>
                                </label>
                              </Grid>
                            )}
                            {bottomMenuOption.bottomMenuOption.includes(
                              "Auditorium"
                            ) && (
                              <Grid item md={1} xs={12}>
                                <Avatar
                                  src={
                                    imageUrl.images +
                                    bottomMenu.auditorium[0].icon
                                  }
                                  className={classes.large}
                                  onClick={() =>
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "Are you sure you want to Delete Auditorium icon?",
                                      subTitle:
                                        "Once deleted, you can't undo this action",
                                      onConfirm: () => {
                                        deleteDialogConfirmationforicon(
                                          "auditorium"
                                        );
                                      }
                                    })
                                  }
                                />
                              </Grid>
                            )}

                            {bottomMenuOption.bottomMenuOption.includes(
                              "Filter"
                            ) && (
                              <Grid item md={6} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Filter Icon Name"
                                  name="filter"
                                  onChange={handleChange}
                                  required
                                  value={bottomMenu["filter"][0].name}
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                            {bottomMenuOption.bottomMenuOption.includes(
                              "Filter"
                            ) && (
                              <Grid item md={5} xs={12}>
                                <label htmlFor="FilterIcon">
                                  <input
                                    style={{ display: "none" }}
                                    id="FilterIcon"
                                    accept="image/png, image/jpeg"
                                    name="filter"
                                    type="file"
                                    onChange={handleStallBannerChange}
                                  />
                                  <Fab
                                    color="primary"
                                    size="small"
                                    component="span"
                                    aria-label="add"
                                    variant="extended"
                                  >
                                    {logoSpin ? (
                                      <CircularProgress
                                        color="secondary"
                                        style={{ margin: "5px" }}
                                        size={20}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <AddIcon />
                                    Upload Filter Icon
                                  </Fab>
                                </label>
                              </Grid>
                            )}
                            {bottomMenuOption.bottomMenuOption.includes(
                              "Filter"
                            ) && (
                              <Grid item md={1} xs={12}>
                                <Avatar
                                  src={
                                    imageUrl.images + bottomMenu.filter[0].icon
                                  }
                                  className={classes.large}
                                  onClick={() =>
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "Are you sure you want to Delete Filter icon?",
                                      subTitle:
                                        "Once deleted, you can't undo this action",
                                      onConfirm: () => {
                                        deleteDialogConfirmationforicon(
                                          "filter"
                                        );
                                      }
                                    })
                                  }
                                />
                              </Grid>
                            )}

                            {bottomMenuOption.bottomMenuOption.includes(
                              "VR MOde"
                            ) && (
                              <Grid item md={6} xs={12}>
                                <TextField
                                  fullWidth
                                  label="VrMode Icon Name"
                                  name="vrMode"
                                  onChange={handleChange}
                                  required
                                  value={bottomMenu["vrMode"][0].name}
                                  variant="outlined"
                                />
                                {/* </FormControl>
                              </Grid>
                            )}
                            {bottomMenuOption.bottomMenuOption.includes(
                              "VR MOde"
                            ) && (
                              <Grid item md={5} xs={12}>
                                <label htmlFor="VRMOdeIcon">
                                  <input
                                    style={{ display: "none" }}
                                    id="VRMOdeIcon"
                                    accept="image/png, image/jpeg"
                                    name="vrMode"
                                    type="file"
                                    onChange={handleStallBannerChange}
                                  />
                                  <Fab
                                    color="primary"
                                    size="small"
                                    component="span"
                                    aria-label="add"
                                    variant="extended"
                                  >
                                    {logoSpin ? (
                                      <CircularProgress
                                        color="secondary"
                                        style={{ margin: "5px" }}
                                        size={20}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <AddIcon />
                                    Upload VRMOde Icon
                                  </Fab>
                                </label>
                              </Grid>
                            )}
                            {bottomMenuOption.bottomMenuOption.includes(
                              "VR MOde"
                            ) && (
                              <Grid item md={1} xs={12}>
                                <Avatar
                                  src={
                                    imageUrl.images + bottomMenu.vrMode[0].icon
                                  }
                                  className={classes.large}
                                  onClick={() =>
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "Are you sure you want to Delete VR MOde icon?",
                                      subTitle:
                                        "Once deleted, you can't undo this action",
                                      onConfirm: () => {
                                        deleteDialogConfirmationforicon(
                                          "vrMode"
                                        );
                                      }
                                    })
                                  }
                                />
                              </Grid>
                            )}

                            {bottomMenuOption.bottomMenuOption.includes(
                              "Logout"
                            ) && (
                              <Grid item md={6} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Logout Icon Name"
                                  name="logout"
                                  onChange={handleChange}
                                  required
                                  value={bottomMenu["logout"][0].name}
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                            {bottomMenuOption.bottomMenuOption.includes(
                              "Logout"
                            ) && (
                              <Grid item md={5} xs={12}>
                                <label htmlFor="logoutIcon">
                                  <input
                                    style={{ display: "none" }}
                                    id="logoutIcon"
                                    accept="image/png, image/jpeg"
                                    name="logout"
                                    type="file"
                                    onChange={handleStallBannerChange}
                                  />
                                  <Fab
                                    color="primary"
                                    size="small"
                                    component="span"
                                    aria-label="add"
                                    variant="extended"
                                  >
                                    {logoSpin ? (
                                      <CircularProgress
                                        color="secondary"
                                        style={{ margin: "5px" }}
                                        size={20}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <AddIcon />
                                    Upload Logout Icon
                                  </Fab>
                                </label>
                              </Grid>
                            )}
                            {bottomMenuOption.bottomMenuOption.includes(
                              "Logout"
                            ) && (
                              <Grid item md={1} xs={12}>
                                <Avatar
                                  src={
                                    imageUrl.images + bottomMenu.logout[0].icon
                                  }
                                  className={classes.large}
                                  onClick={() =>
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "Are you sure you want to Delete logout icon?",
                                      subTitle:
                                        "Once deleted, you can't undo this action",
                                      onConfirm: () => {
                                        deleteDialogConfirmationforicon(
                                          "logout"
                                        );
                                      }
                                    })
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                        </TabPanel>
                       */}
                        </TabContext>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <div />
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      {spin ? (
                        <CircularProgress
                          color="secondary"
                          style={{ margin: "5px" }}
                          size={20}
                        />
                      ) : (
                        ""
                      )}
                      {tabValue == 1 && (
                        <Grid item md={6} xs={12}>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => publish()}
                            disabled={spin ? true : false}
                          >
                            Publish
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => saveAsDraft()}
                            disabled={spin ? true : false}
                          >
                            Draft
                          </Button>
                        </Grid>
                      )}
                    </Box>
                  </Card>
                </form>
              </div>
            </Container>
            <AlertDialog
              alertDialog={alertDialog}
              setAlertDialog={setAlertDialogfunc}
            />
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </Page>
        </div>
      )}

      {/* {!backPage && <ExpoList />} */}
    </div>
  );
};

export default AdminPanel;
