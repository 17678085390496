import React, { useState } from "react";
import styles from "./Footer.module.scss";
import "./footer.css";
import THEME from "../../../state/theme";
import { useCustomState } from "../../../state/state";
import logo from "../../../assets/placeholders/WALSTRAT LOGO-.png";
import { Link } from "react-scroll";
import { useHistory } from "react-router";
import { Box } from "@material-ui/core";

// import { Link } from "react-scroll";

export default () => {
  const state = useCustomState()[0];
  // const actions = useCustomState()[1];
  const history = useHistory();
const [speed, setSpeed] = useState(5)
  const servicesList = state.data.services
    .map((item, index) => (
      <Link
        key={index}
        url={"/services/" + item.id}
        hoverStyle={{ color: THEME.color }}
      >
        <i className="las la-angle-right" />
        {item.title}
      </Link>
    ))
    .slice(0, 5);
  function gotoProducts() {
    window.location.href = "/products";
  }
  function gotoProjects() {
    window.location.href = "/projects";
  }
  const linkList = state.data.fast_links.map((item, index) => (
    <Link key={index} url={item.url} hoverStyle={{ color: THEME.color }}>
      <i className="las la-angle-right" />
      {item.title}
    </Link>
  ));
  return (
    <div
      padding
      style={{
        background: "#031163",
        padding: "10px 0",
        // margin: "0 auto",
        // marginTop: "25px",
        width: "100%",
        height: "auto",
        zIndex: "150",
        position: "absolute",
      }}
      id="maincontent"
      className="positionfooter"
    >
      <div className={styles.footer}>
        <Box display="flex" style={{ width: "100%" }}>
          <Box flexGrow={1} style={{ margin: "auto" }}>
            <img
              src={logo}
              style={{ width: "100px" }}
              id="footerwalstratlogo"
            ></img>
          </Box>
          <Box
            display="flex"
            style={{ width: "100%", justifyContent: "center" }}
          >
            <ul className="flexfooter">
              <li>
                <Link
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  className="cursorpointer"
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  Home{" "}
                </Link>
              </li>

              <li>
                <a
                  className="cursorpointer"
                  onClick={() => {
                    history.push("/");
                    setTimeout(() => {
                      let productEl = document.getElementById("services");
                      if (productEl) {
                        productEl.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }, 500);
                  }}
                >
                  Our Expertise{" "}
                </a>
              </li>

              <li>
                <a
                  className="cursorpointer"
                  onClick={() => {
                    history.push("/");
                    setTimeout(() => {
                      let productEl = document.getElementById("about");
                      if (productEl) {
                        productEl.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }, 500);
                  }}
                >
                  About{" "}
                </a>
              </li>
              <li>
                <a
                  className="cursorpointer"
                  onClick={() => {
                    history.push("/");
                    setTimeout(() => {
                      let productEl = document.getElementById("blogpage");
                      if (productEl) {
                        productEl.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }, 500);
                  }}
                >
                  Resources{" "}
                </a>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="products"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  className="cursorpointer"
                  onClick={gotoProducts}
                >
                  Products{" "}
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="projects"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  className="cursorpointer"
                  onClick={gotoProjects}
                >
                  Projects{" "}
                </Link>
              </li>
              <li>
                <a
                  className="cursorpointer"
                  onClick={() => {
                    history.push("/");
                    setTimeout(() => {
                      let productEl = document.getElementById("contact");
                      if (productEl) {
                        productEl.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }, 500);
                  }}
                >
                  Contact{" "}
                </a>
              </li>
            </ul>
          </Box>
          <Box flexGrow={1} style={{ margin: "auto", width: "130px" }}></Box>
        </Box>
        <div className="container" style={{margin : " 10px 0px 0px 0px", maxWidth : "fit-content"}}>
          <marquee scrollamount={speed}><mark>
            Please read this disclaimer carefully before using the Walstrat
            website operated by us. The content displayed on the website is the
            intellectual property of the Walstrat Services Pvt. Ltd. You may
            not reuse, republish, or reprint such content without our written
            consent. All information posted is merely for educational and
            informational purposes. It is not intended as a substitute for
            professional advice. Should you decide to act upon any information
            on this website, you do so at your own risk. While the information
            on this website has been verified to the best of our abilities, we
            cannot guarantee that there are no mistakes or errors. We reserve
            the right to change this policy at any given time, of which you will
            be promptly updated. If you want to make sure that you are up to
            date with the latest changes, we advise you to frequently visit this
            page.</mark>
          </marquee>
        </div>
        <p>
          Powered by{" "}
          <a href="https://melzo.com/" target="_blank" className="linkcolor">
            melzo.com
          </a>{" "}
        </p>
      </div>
    </div>
  );
};
