import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyARMR04Jdzlbw11MTivdi9V3F4IfXJ_gFo",
  authDomain: "melzo-2d.firebaseapp.com",
  databaseURL: "https://melzo-2d.firebaseio.com",
  projectId: "melzo-2d",
  storageBucket: "melzo-2d.appspot.com",
  messagingSenderId: "463120681766",
  appId: "1:463120681766:web:231bdb90a9b81d33b28fe7",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
