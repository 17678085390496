import React, { useState, useEffect } from "react";
import Header from "../../layouts/Header/Header.js";
import { useParams } from "react-router-dom";
import { useCustomState } from "../../../state/state";
import { useHistory } from "react-router";
import { Footer } from "../../layouts";
import { Button, Typography } from "@material-ui/core";
// import "../../../../../src/components/widgets/Slider/slider.css";
import "../../widgets/Slider/slider.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Fade from "react-reveal/Fade";
import "../Blog/blogpage.css";
// import image1 from "../../../assets/placeholders/1.jpg";
// import image2 from "../../../assets/placeholders/2.jpg";
// import image3 from "../../../assets/placeholders/3.jpg";
// import image4 from "../../../assets/placeholders/4.jpg";

export default () => {
  const history = useHistory();
  const state = useCustomState()[0];
  // console.log("single post page => ", state.data.posts);
  const { post_id } = useParams();

  let singlePostData = state.data.posts.filter((post) => post.id === post_id);

  const [postData, setPostData] = useState(singlePostData);

  // console.log("single post data state => ", postData);

  useEffect(() => {
    // singlePostData = state.data.posts.filter((post) => post.id === post_id);
    // setPostData(singlePostData);
  });

  const handlePostChange = (PostId) => {
    singlePostData = state.data.posts.filter((post) => post.id === PostId);
    setPostData(singlePostData);
  };

  return (
    <div>
      <div style={{ height: "100px" }}>
        <Header />
      </div>
      <div className="row" id="singleBlog" style={{ margin: "50px" }}>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "xx-large",
          }}
        >
          {/* {console.log('all post data =>',state.data.posts)} */}
          {"welcome to " + `${postData[0].author}` + "'s blog"}
        </span>
        <div className="col-lg-12 md-12">
          {postData[0].image && (
            <OwlCarousel
              items={1}
              autoplay
              loop
              className="owl-theme"
              style={{ marginBottom: "30px" }}
            >
              <div>
                <img
                  className="Img radiuszero widthImg"
                  src={postData[0].image}
                  alt={postData[0].title + "'s Image"}
                />
              </div>
            </OwlCarousel>
          )}
          <Typography
            variant={postData[0].image ? "h6" : "h5"}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {"publised on " +
              new Date(postData[0].posting_date).toLocaleDateString("en-IN")}
          </Typography>
        </div>

        <div className="col-lg-12">
          <h2 style={{ fontSize: "40px" }}>{postData[0].title}</h2>
        </div>
        <div className="col-lg-12">
          <p>{postData[0].full}</p>
        </div>
        <br></br>
        <hr></hr>
        <span
          style={{ color: "blue", justifyContent: "center", display: "flex" }}
        >
          Read Our Other Inspirational Blogs
        </span>
        <OwlCarousel
          className="owl-theme"
          items={3}
          responsiveClass={true}
          id="owlBasicBar"
          loop={true}
          nav={false}
          autoplay={true}
          rewind={true}
          dots={true}
          responsiveClass={true}
          responsive={{
            0: {
              items: 1,
              loop: false,
            },
            600: {
              items: 1,
              loop: false,
            },
            700: {
              items: 1,
              loop: false,
            },
            1000: {
              items: 3,
              loop: true,
            },
          }}
        >
          {state.data.posts.map((blog, index) => (
            <div className="news-one__single">
              <div className="news-one__img-box">
                <div className="news-one-img" style={{ height: "200px" }}>
                  <img src={blog.image} alt="" />
                </div>

                <div className="news-one__date-box">
                  <p>{new Date(blog.posting_date).toDateString("en-In")}</p>
                </div>
              </div>
              <div className="news-one__content" style={{ height: "200px" }}>
                <h3 className="news-one__title">
                  <span>{blog.title}</span>
                </h3>
                <p className="news-one__text">
                  {blog.full
                    ? blog.full.length > 40
                      ? blog.full.slice(0, 40) + " ..."
                      : blog.full
                    : ""}
                </p>
                <br></br>
                <a href="#singleBlog" className="news-one__btn" onClick={() => handlePostChange(blog.id)}>
                  Read More
                </a>
                {/* <Button
                  className="readMore"
                  variant="contained"
                  
                >
                  Read More
                </Button> */}
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
      <Footer />
    </div>
  );
};
