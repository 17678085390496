import React, { Component } from "react";
import Editor from "react-medium-editor";
import "medium-editor/dist/css/medium-editor.css";
import "medium-editor/dist/css/themes/default.css";
import BlogCards from "./pages/Blog/BlogList";
import { Table } from "react-bootstrap";
// import TableChartIcon from "@material-ui/icons/TableChartIcon";
import { TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
// import firebase from "firebase";
import firebase from "../utilities/Firebase";
import Paper from "@material-ui/core/Paper";
import { EditorState } from "draft-js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import logo from "../assets/placeholders/WALSTRAT LOGO-.png";
import "../css/adminblog.css";
import Videojs from "./videojs";
import videoJsOptions from "./videojs-option";
import moment from "moment";
import { AdminFooter } from "./layouts";

const db = firebase.firestore();
const collection = db.collection("walstrat/root/Events");
const storage = firebase.storage();
export default class MediaEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      eventName: "",
      eventDate: moment().format("YYYY-MM-DD"),
      eventDetails: "",
      short: "",
      id: 1,
      author: "",
      docid: "",
      category_id: "",
      full: "",
      published: false,
      edit: false,
      postArray: [],
      image: null,
      url: "",
      isLogged: null,
      progress: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleImage = this.handleImage.bind(this);
  }
  handleImage = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
    }
  };
  handleUpload = () => {
    const { image } = this.state;
    if (image) {
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              this.setState({ url: url });
            });
        }
      );
    } else {
      alert("please select file first");
    }
  };
  componentDidMount() {
    if (localStorage.getItem("Isloggedin")) {
      collection.orderBy("id").onSnapshot((querySnapshot) => {
        const posts = [];
        querySnapshot.forEach((doc) => {
          let postObj = doc.data();
          postObj.docid = doc.id;
          posts.push(postObj);
          console.log("doc data", doc.data());
        });
        setTimeout(() => {
          if (posts.length > 0) {
            this.setState(
              {
                postArray: posts,
                id: parseInt(posts[posts.length - 1].id.split("event_")[1]) + 1,
              },

              () => console.log(posts)
            );
          } else {
            this.setState({
              postArray: posts,
              id: 1,
            });
          }
        }, 100);
      });
    } else {
      window.location.href = "/admin";
    }
  }

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  
  onAddEvent = () => {
    if (this.validate()) {
      console.log(this.state);
      let event = {
        id: "event_" + this.state.id.toString(),
        eventName: this.state.eventName,
        eventDetails: this.state.eventDetails,
        eventDate: this.state.eventDate,
        image: this.state.url,
      };
      this.pushToDB(event);
    }
  };

  pushToDB = (event) => {
    collection.add(event).then(() => {
      console.log("Data from DB ", event);
      console.table(this.state);
      this.cleanInput();
    });
  };

  validate = () => {
    if (this.state.eventName === "") {
      alert("Please write event name.");
      return false;
    } else if (this.state.eventDetails === "") {
      alert("Please write event details!");
      return false;
    } else if (this.state.eventDate === "") {
      alert("Please select event date");
    } else {
      return true;
    }
  };

  cleanInput = () => {
    this.setState({
      eventName: "",
      eventDetails: "",
      url: "",
      image: null,
      eventDate: moment().format("YYYY-MM-DD"),
    });
  };

  goTOLogin = () => {
    localStorage.clear();
    window.location.href = "/admin";
  };

  render() {
    return (
      <div>
        <div className="padding-blog">
          <div class="header-mainadmin">
            <h1 className="adminlogintext">Media Event</h1>

            <div class="main-icon">
              <span class="fa fa-eercast"></span>
            </div>
            <div class="header-left-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <div class="icon1">
                      <input
                        fullWidth={true}
                        id="eventName"
                        placeholder="Event Name"
                        name="eventName"
                        title="Enter the event name"
                        value={this.state.eventName}
                        variant="outlined"
                        onChange={this.handleChange}
                        className="inputadmin"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div class="icon1">
                      <input
                        id="eventDate"
                        type="date"
                        placeholder="Event Date"
                        name="eventDate"
                        title="Enter the event date"
                        value={this.state.eventDate}
                        variant="outlined"
                        fullWidth={true}
                        onChange={this.handleChange}
                        className="inputadmin"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <div class="icon1">
                      <textarea
                        id="eventDetails"
                        placeholder="Enter event details"
                        multiline
                        rows={3}
                        title="enter event details"
                        name="eventDetails"
                        value={this.state.eventDetails}
                        fullWidth={true}
                        variant="outlined"
                        onChange={this.handleChange}
                        className="inputadmin"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div class="icon1">
                      {!this.state.edit && (
                        <div>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={this.handleImage}
                            style={{ width: "100%" }}
                            className="inputadmin"
                          />

                          <div className="flexchoose">
                            <progress value={this.state.progress} max="100" />
                            <span>{this.state.progress + "%"}</span>
                            <button
                              onClick={this.handleUpload}
                              className="backtohomebtn"
                            >
                              Upload
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-3 col-lg-3">
                    {this.state.edit ? (
                      <button
                        variant="contained"
                        color="primary"
                        onClick={this.update}
                        className="backtohomebtn"
                      >
                        Update
                      </button>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <button
                          variant="contained"
                          color="primary"
                          onClick={this.onAddEvent}
                          className="backtohomebtn marginrightpublish"
                        >
                          Add Event
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-3 col-lg-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
