import React, { Component } from 'react';
import './servicepage.css'
import Particles from 'react-particles-js';
// import './partical.js';

export default class Servicepage2 extends Component {
    componentDidMount() {

    }
    render() {
        return (
            <div>
                {/* <!-- particles.js container --> */}
                {/* <div id="particles-js"></div> */}
                <div className="bgcolor">
                <h1 className="title">Automobile & Auto Components</h1>

                    {/* <div className="particleposition"> */}
                    <Particles />
                    {/* </div> */}
                </div>
                
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="servicesubtitle">
                                    One of the fastest evolving markets, with newer technologies introduced almost daily.

                                </h1>
                                <p className="description">
                                The Automobile market is fast evolving into a competitive and increasingly sophisticated arena. The needs of the customers are being met with greater efficiency and customization. Almost all the major Global players in the Automobile and auto-components industry are setting up manufacturing plants in India to meet the domestic as well as global demand. 

                                </p>
                                <p className="description">
                                The industry’s highly competitive environment translates into a strong demand for senior management who can successfully steer companies through challenging times. This trend has positioned India as the hotbed of talent for the Automobile industry. The Indian auto components industry which feeds the motor vehicle manufacturing companies is one of the fastest-growing manufacturing sectors and is both forward and backward integrated with the opposite engineering and manufacturing divisions in the country.

                                </p>
                                <p className="description">
                                We offer to consult services to organizations engaged in the manufacturing and distribution of automobiles or auto components. Our consulting methodology focuses on identifying the leverage point of a corporation for intervention. Our expertise in tackling issues associated with growth strategies sustainability and operational efficiency has made it suitably positioned to deliver tangible value to its clients in handling cost pressures, partnering, sourcing and delivery capabilities & improving Automotive OEMs management.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
