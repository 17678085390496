import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { TabContext } from "@material-ui/lab";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../utilities/Firebase";
import { AdminFooter } from "./layouts";
import logo from "../assets/placeholders/WALSTRAT LOGO-.png";
import EditIcon from "@material-ui/icons/Edit";

// import filterCancelIcon from '../../assets/img/filter-cancel.png'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Snackbar,
  Typography,
  Tooltip,
  Zoom,
  withStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  TextField,
  TextareaAutosize,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
// import noDataImg from "../../assets/img/NoData.png";
import Page from "./page";
import Avatar from "material-ui/Avatar";
// import ConfirmDialog from "../Register/ConfirmDialog";
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";

const db = firebase.firestore();
const collection = db.collection("walstrat/root/Posts");
const storage = firebase.storage();

class CreateBlog2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: "1",
      title: "",
      short: "",
      id: 1,
      author: "",
      docid: "",
      category_id: "",
      full: "",
      published: false,
      blogEdit: false,
      postArray: [],
      image: null,
      url: "",
      imageSpin: false,
      progress: 0,
      posting_date: null,
      isPublished: false,
    };
  }

  componentDidMount = () => {
    collection.orderBy("id").onSnapshot((querySnapshot) => {
      const posts = [];
      querySnapshot.forEach((doc) => {
        let postObj = doc.data();
        postObj.docid = doc.id;
        posts.push(postObj);
        console.log("doc data", doc.data());
      });
      setTimeout(() => {
        if (posts.length > 0) {
          this.setState(
            {
              postArray: posts,
              id: parseInt(posts[posts.length - 1].id.split("post_")[1]) + 1,
            },

            () => console.log(posts)
          );
        } else {
          this.setState({
            postArray: posts,
            id: 1,
          });
        }
      }, 100);
    });
  };

  handleChangeTab = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleImage = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(
        {
          image: image,
          imageSpin: true,
        },
        () => {
          this.handleUpload();
        }
      );
    }
  };

  handleUpload = () => {
    this.setState({
      imageSpin: true,
    });
    const { image } = this.state;
    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error) => {
        // error function ....
        console.log(error);
      },
      () => {
        storage
          .ref("images")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            this.setState({
              imageSpin: false,
              url: url,
            });
          });
      }
    );
  };

  publish = () => {
    if (this.validate()) {
      console.log(this.state);
      let post = {
        id: "post_" + this.state.id.toString(),
        title: this.state.title,
        short: this.state.short,
        full: this.state.full,
        posting_date: this.state.blogEdit
          ? this.state.posting_date
          : new Date().getTime(),
        updating_date: new Date().getTime(),
        published: true,
        category_id: this.state.category_id,
        author: this.state.author,
        image: this.state.url,
      };
      this.pushToDB(post);
    }
  };

  saveAsDraft = () => {
    if (this.validate()) {
      let draft = {
        id: "post_" + this.state.id.toString(),
        title: this.state.title,
        short: this.state.short,
        full: this.state.full,
        posting_date: this.state.blogEdit
          ? this.state.posting_date
          : new Date().getTime(),
        updating_date: new Date().getTime(),
        published: false,
        category_id: this.state.category_id,
        author: this.state.author,
        image: this.state.url,
      };
      this.pushToDB(draft);
    }
  };

  pushToDB = (post) => {
    if (!this.state.blogEdit) {
      collection.add(post).then(() => {
        console.log("Data from DB ", post);
        this.cleanInput();
      });
    } else {
      collection
        .doc(this.state.docid)
        .update(post)
        .then((res) => {
          console.log("res", res);
          this.cleanInput();
        });
    }
  };

  validate = () => {
    if (this.state.title === "") {
      alert("Please write title.");
      return false;
    } else if (this.state.full === "") {
      alert("Please write content!");
      return false;
    } else if (this.state.author === "") {
      alert("Please enter your name.");
    } else if (this.state.category_id === "") {
      alert("Please select your post category.");
    } else {
      return true;
    }
  };

  editData = (key) => {
    this.state.postArray.map((post) => {
      if (post.id === key) {
        this.setState(
          {
            id: parseInt(key.split("post_")[1]),
            title: post.title,
            short: post.short,
            full: post.full,
            author: post.author,
            category_id: post.category_id,
            docid: post.docid,
            isPublished: post.published,
            posting_date: post.posting_date,
            tabValue: "1",
            blogEdit: true,
            url: post.image,
          },
          () => {
            console.log("edit data ", this.state);
          }
        );
      }
    });
  };

  cleanInput = () => {
    this.setState({
      tabValue: "1",
      title: "",
      short: "",
      // id: 1,
      author: "",
      docid: "",
      category_id: "",
      full: "",
      published: false,
      blogEdit: false,
      // postArray: [],
      image: null,
      url: "",
      imageSpin: false,
      progress: 0,
      posting_date: null,
      isPublished: false,
    });
  };

  goTOLogin = () => {
    localStorage.clear();
    window.location.href = "/admin";
  };

  render() {
    return (
      <div>
        <Page title="Enquiry Details">
          <Container style={{ marginTop: "110px" }}>
            <Box
              display="flex"
              style={{ alignItems: "center", margin: "10px" }}
            >
              <Box
                flexGrow={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <Typography variant="h5" style={{ fontFamily: "Sitka, serif" }}>
                  {this.state.tabValue == 1
                    ? this.state.blogEdit
                      ? "Edit Blog"
                      : "Create Blog"
                    : "Blog List"}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Card>
              <CardContent>
                <TabContext value={this.state.tabValue}>
                  <AppBar
                    position="static"
                    style={{
                      color: "white",
                      background: "linear-gradient(60deg, #031163, #05716C)",
                    }}
                  >
                    <TabList
                      onChange={this.handleChangeTab}
                      aria-label="profiletab"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#fff",
                        }
                      }}
                    >
                      <Tab
                        label="Create Blog"
                        value="1"
                        style={{ fontFamily: "Sitka", fontSize : "medium" }}
                      />
                      <Tab
                        label="View Previous Blogs"
                        value="2"
                        style={{ fontFamily: "Sitka, serif", fontSize : "medium" }}
                      />
                      {/* <Tab
                        label={"Meeting"}
                        value="3"
                        style={{ fontFamily: "Montserrat" }}
                      /> */}
                    </TabList>
                  </AppBar>
                  <TabPanel value="1">
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          className="inputadmin"
                          label="Title"
                          name="title"
                          id="title"
                          placeholder="Title"
                          onChange={this.handleChange}
                          required
                          value={this.state.title}
                          variant="standard"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="short"
                          placeholder="Short"
                          name="short"
                          value={this.state.short}
                          variant="standard"
                          fullWidth
                          onChange={this.handleChange}
                          className="inputadmin"
                          label="Short"
                          required
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="author"
                          placeholder="Written By"
                          name="author"
                          value={this.state.author}
                          variant="standard"
                          fullWidth={true}
                          onChange={this.handleChange}
                          className="inputadmin"
                          label="Author"
                          required
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          rowsMin={3}
                          id="full"
                          placeholder="Enter blog data"
                          multiline
                          name="full"
                          value={this.state.full}
                          fullWidth
                          label="Blog full data"
                          variant="standard"
                          onChange={this.handleChange}
                          className="inputadmin"
                          required
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="demo-simple-select-outlined-label">
                            Category
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            name="category_id"
                            value={this.state.category_id}
                            onChange={this.handleChange}
                            label="Category"
                          >
                            <MenuItem value="">
                              <em>Category</em>
                            </MenuItem>
                            <MenuItem value={0}>Product</MenuItem>
                            <MenuItem value={1}>Project</MenuItem>
                            <MenuItem value={2}>Article</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <label htmlFor="images">
                          <input
                            // style={{ display: "none" }}
                            id="images"
                            name="images"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={this.handleImage}
                          />
                          {this.state.imageSpin ? (
                            <CircularProgress
                              color="secondary"
                              style={{ margin: "5px" }}
                              size={20}
                            />
                          ) : (
                            ""
                          )}
                        </label>
                        {this.state.url !== "" && (
                          <div>
                            <img
                              width="150px"
                              height="150px"
                              src={this.state.url}
                            />
                            <br></br>
                            <h6 style={{ color: "red" }}>
                              If you upload new image, it override over previous
                              image
                            </h6>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <br />
                    <Button
                      variant="contained"
                      style={{ background: "linear-gradient(60deg, #031163, #05716C)", color: "#fff" }}
                      onClick={this.publish}
                    >
                      Publish
                    </Button>{" "}
                    &nbsp; &nbsp;
                    <Button
                      variant="contained"
                      color="secondary"
                      style={
                        this.state.isPublished ? { cursor: "not-allowed" } : {}
                      }
                      disabled={this.state.isPublished}
                      onClick={this.saveAsDraft}
                    >
                      Save Draft
                    </Button>
                    &nbsp; &nbsp;
                    {this.state.blogEdit && (
                      <Button
                        variant="contained"
                        color="default"
                        onClick={this.cleanInput}
                        style={{ background: "linear-gradient(60deg, #031163, #05716C)", color: "#fff" }}
                      >
                        Create a New
                      </Button>
                    )}
                  </TabPanel>
                  <TabPanel value="2">
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow
                                style={{ backgroundColor: "lightgrey" }}
                              >
                                <TableCell>Blog Title</TableCell>
                                <TableCell align="left">Blog Short</TableCell>
                                <TableCell align="left">
                                  Blog Description
                                </TableCell>
                                <TableCell align="left">Category</TableCell>
                                <TableCell align="left">Author</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left">Posting Date</TableCell>
                                <TableCell align="left">Update Date</TableCell>
                                <TableCell align="left">Edit</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.postArray.map((data, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {data.title}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.short}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.full
                                      ? data.full.length > 50
                                        ? data.full.slice(0, 50) + " ..."
                                        : data.full
                                      : ""}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.category_id == 0
                                      ? "Product"
                                      : data.category_id == 1
                                      ? "Project"
                                      : "Article"}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {data.author}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {data.published ? "Published" : "Drafted"}
                                  </TableCell>
                                  <TableCell align="left">
                                    {new Date(
                                      data.posting_date
                                    ).toLocaleDateString("en-IN")}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.updating_date
                                      ? new Date(
                                          data.updating_date
                                        ).toLocaleDateString("en-IN")
                                      : " - "}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <EditIcon
                                      color={"inherit"}
                                      titleAccess={"Click to Edit"}
                                      onClick={() => this.editData(data.id)}
                                      variant="contained"
                                      size="sm"
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </CardContent>
            </Card>

            {/* <Snackbar
              open={this.state.showSnackbar}
              autoHideDuration={4000}
              key="topcenter"
              onClose={this.closeSnackbar}
            >
              <Alert onClose={this.closeSnackbar} severity="success">
                {this.state.snackbarText}
              </Alert>
            </Snackbar> */}

            {/* <ConfirmDialog
              confirmDialog={this.state.confirmDialog}
              setConfirmDialog={this.setConfirmDialog}
            /> */}
          </Container>
        </Page>
      </div>
    );
  }
}
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
export default CreateBlog2;
