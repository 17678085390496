import React from "react";
import styles from "./Logo.module.scss";
import THEME from "../../../state/theme";
import logo from '../../../assets/placeholders/WALSTRAT LOGO-.png';
import '../../../css/style.css';
export default ({ dark }) => (
    <figure
        className={[styles.logo, dark ? styles.dark : styles.light].join(" ")}
        style={{ borderColor: THEME.color }}
    >
        <img src={logo} className="widthlogoslider"></img>
        {/* <i className="las la-industry" />
    <span>
      Build<b>Stars.</b>
    </span> */}
    </figure>
);
