import React, { Component } from "react";
import "./blogpage.css";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import blogimg1 from "../../../assets/placeholders/4.jpg";
import blogimg2 from "../../../assets/placeholders/3.jpg";
import blogimg3 from "../../../assets/placeholders/consultancy.jpg";
import blogimg4 from "../../../assets/placeholders/industrial.jpg";
import blogimg5 from "../../../assets/placeholders/3.jpg";
import blogimg6 from "../../../assets/placeholders/3.jpg";
import $ from "jquery";
import firebase from "../../../utilities/Firebase";
// import LazyLoad from "react-lazy-load";

export default class BlogPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      owlCarousel: {},
      blogData: [],
    };
  }

  componentDidMount() {
    let data = [];
    const self = this;
    new Promise((res, rej) => {
      firebase
        .firestore()
        .collection("walstrat/root/Posts")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if(doc.data().published){
              data.push(doc.data());
              console.log("blog data", doc.data());
            }
          });
          res(data)
        });
    }).then((res) => {
      self.setState({
        blogData: res,
      });
    });
    // var owlOptions = {
    //   loop: true,
    //   margin: 10,
    //   nav: true,
    //   autoplay: true,
    //   dots: false,
    //   responsiveClass: true,
    //   responsive: {
    //     0: {
    //       items: 1,
    //     },
    //     600: {
    //       items: 1,
    //     },
    //     1000: {
    //       items: 1,
    //     },
    //   },
    // };
  }

  render() {
    return (
      <div id="blogpage">
        {/* <!--News Page Start--> */}
        {this.state.blogData.length > 0 && (
          <section className="news-one news-one__page">
            <div className="container" id="blog">
              <div className="layout-header">
                {/* <LazyLoad> */}
                <Flip top>
                  <span className="subtitle">Blog</span>
                </Flip>
                {/* </LazyLoad> */}
              </div>
              <div className="row">
                <OwlCarousel
                  className="owl-theme"
                  items={3}
                  responsiveClass={true}
                  id="owlBasicBar"
                  loop={false}
                  nav={false}
                  // navText = {["<i class='fa fa-chevron-left fa-4x' aria-hidden='true'></i>","<i class='fa fa-chevron-right fa-4x' aria-hidden='true'></i>"]}
                  autoplay={true}
                  rewind={true}
                  dots={true}
                  responsiveClass={true}
                  responsive={{
                    0: {
                      items: 1,
                      loop: false,
                    },
                    600: {
                      items: 1,
                      loop: false,
                    },
                    700: {
                      items: 1,
                      loop: false,
                    },
                    1000: {
                      items: 3,
                      loop: false,
                    },
                  }}
                >
                  {this.state.blogData.map((blog, index) => (
                    <Fade left key={index}>
                      <div className="news-one__single">
                        <div className="news-one__img-box">
                          <div
                            className="news-one-img"
                            style={{ height: "200px" }}
                          >
                            {/* <LazyLoad> */}
                            {/* <Fade left> */}
                            <img src={blog.image} alt="" />
                            {/* </Fade> */}
                            {/* </LazyLoad> */}
                            {/* <LazyLoad>
                              <Fade left>
                                <a href="">
                                  <span className="news-one__plus"></span>
                                </a>
                              </Fade>
                            </LazyLoad> */}
                          </div>
                          {/* <LazyLoad> */}
                          {/* <Fade left> */}
                          <div className="news-one__date-box">
                            <p>
                              {new Date(blog.posting_date).toDateString(
                                "en-In"
                              )}
                            </p>
                          </div>
                          {/* </Fade> */}
                          {/* </LazyLoad> */}
                        </div>
                        <div
                          className="news-one__content"
                          style={{ height: "200px" }}
                        >
                          {/* <LazyLoad> */}
                          {/* <Fade left> */}
                          <h3 >
                            <a className="news-one__title" href={"/blog/" + `${blog.id}`}>{blog.title}</a>
                            {/* {blog.title} */}
                          </h3>
                          {/* </Fade> */}
                          {/* </LazyLoad> */}
                          {/* <LazyLoad> */}
                          {/* <Fade left> */}
                          <p className="news-one__text">{blog.full ? blog.full.length > 40 ? blog.full.slice(0,40) + " ..." : blog.full : ""}</p>
                          {/* </Fade> */}
                          {/* </LazyLoad> */}
                          {/* <LazyLoad> */}
                          {/* <Fade left> */}
                          <a
                            href={"/blog/" + `${blog.id}`}
                            className="news-one__btn"
                          >
                            Read More
                          </a>
                          {/* </Fade> */}
                          {/* </LazyLoad> */}
                        </div>
                      </div>
                    </Fade>
                  ))}
                  {/* <Fade bottom>
                  <div className="news-one__single">
                    <div className="news-one__img-box">
                      <div className="news-one-img">
                        <LazyLoad>
                          <Fade bottom>
                            <img src={blogimg1} alt="" />
                          </Fade>
                        </LazyLoad>
                        <LazyLoad>
                          <Fade bottom>
                            <a href="">
                              <span className="news-one__plus"></span>
                            </a>
                          </Fade>
                        </LazyLoad>
                      </div>
                      <div className="news-one__date-box">
                        <LazyLoad>
                          <Fade bottom>
                            <p>20 april</p>
                          </Fade>
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="news-one__content">
                      <LazyLoad>
                        <Fade bottom>
                          <h3 className="news-one__title">
                            <a href="">Title Name</a>
                          </h3>
                        </Fade>
                      </LazyLoad>
                      <LazyLoad>
                        <Fade bottom>
                          <p className="news-one__text">
                            Lorem ipsum is simply free text used by new pesnhl
                            note this copytyping refreshing.
                          </p>
                        </Fade>
                      </LazyLoad>
                      <LazyLoad>
                        <Fade bottom>
                          <a href="" className="news-one__btn">
                            Read More
                          </a>
                        </Fade>
                      </LazyLoad>
                    </div>
                  </div>
                </Fade> */}
                  {/* <Fade right>
                  <div className="news-one__single">
                    <div className="news-one__img-box">
                      <div className="news-one-img">
                        <LazyLoad>
                          <Fade right>
                            <img src={blogimg2} alt="" />
                          </Fade>
                        </LazyLoad>
                        <LazyLoad>
                          <Fade right>
                            <a href="">
                              <span className="news-one__plus"></span>
                            </a>
                          </Fade>
                        </LazyLoad>
                      </div>
                      <div className="news-one__date-box">
                        <LazyLoad>
                          <Fade right>
                            <p>20 april</p>
                          </Fade>
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="news-one__content">
                      <LazyLoad>
                        <Fade right>
                          <h3 className="news-one__title">
                            <a href="">Title Name</a>
                          </h3>
                        </Fade>
                      </LazyLoad>
                      <LazyLoad>
                        <Fade right>
                          <p className="news-one__text">
                            Lorem ipsum is simply free text used by new pesnhl
                            note this copytyping refreshing.
                          </p>
                        </Fade>
                      </LazyLoad>
                      <LazyLoad>
                        <Fade right>
                          <a href="" className="news-one__btn">
                            Read More
                          </a>
                        </Fade>
                      </LazyLoad>
                    </div>
                  </div>
                </Fade> */}
                  {/* <div className="news-one__single">
                  <div className="news-one__img-box">
                    <div className="news-one-img">
                      <LazyLoad>
                        <img src={blogimg3} alt="" />
                      </LazyLoad>
                      <LazyLoad>
                        <a href="">
                          <span className="news-one__plus"></span>
                        </a>
                      </LazyLoad>
                    </div>
                    <div className="news-one__date-box">
                      <LazyLoad>
                        <p>20 april</p>
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="news-one__content">
                    <LazyLoad>
                      <h3 className="news-one__title">
                        <a href="">Title Name</a>
                      </h3>
                    </LazyLoad>
                    <LazyLoad>
                      <p className="news-one__text">
                        Lorem ipsum is simply free text used by new pesnhl note
                        this copytyping refreshing.
                      </p>
                    </LazyLoad>
                    <LazyLoad>
                      <a href="" className="news-one__btn">
                        Read More
                      </a>
                    </LazyLoad>
                  </div>
                </div> */}
                  {/* <div className="news-one__single">
                  <div className="news-one__img-box">
                    <div className="news-one-img">
                      <LazyLoad>
                        <img src={blogimg3} alt="" />
                      </LazyLoad>
                      <LazyLoad>
                        <a href="">
                          <span className="news-one__plus"></span>
                        </a>
                      </LazyLoad>
                    </div>
                    <div className="news-one__date-box">
                      <LazyLoad>
                        <p>20 april</p>
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="news-one__content">
                    <LazyLoad>
                      <h3 className="news-one__title">
                        <a href="">Title Name</a>
                      </h3>
                    </LazyLoad>
                    <LazyLoad>
                      <p className="news-one__text">
                        Lorem ipsum is simply free text used by new pesnhl note
                        this copytyping refreshing.
                      </p>
                    </LazyLoad>
                    <LazyLoad>
                      <a href="" className="news-one__btn">
                        Read More
                      </a>
                    </LazyLoad>
                  </div>
                </div> */}
                  {/* <div className="news-one__single">
                  <div className="news-one__img-box">
                    <div className="news-one-img">
                      <LazyLoad>
                        <img src={blogimg4} alt="" />
                      </LazyLoad>
                      <a href="">
                        <span className="news-one__plus"></span>
                      </a>
                    </div>
                    <div className="news-one__date-box">
                      <LazyLoad>
                        <p>20 april</p>
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="news-one__content">
                    <LazyLoad>
                      <h3 className="news-one__title">
                        <a href="">Title Name</a>
                      </h3>
                    </LazyLoad>
                    <LazyLoad>
                      <p className="news-one__text">
                        Lorem ipsum is simply free text used by new pesnhl note
                        this copytyping refreshing.
                      </p>
                    </LazyLoad>
                    <LazyLoad>
                      <a href="" className="news-one__btn">
                        Read More
                      </a>
                    </LazyLoad>
                  </div>
                </div> */}
                </OwlCarousel>
              </div>
            </div>
          </section>
        )}
      </div>
      /* <!--News Page End--> */
      // </div >
    );
  }
}
