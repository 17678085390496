const videoJsOptions = {
  autoplay: true,
  muted: true,
  controls: false,
  fluid: true,
  loop: true,
  errorDisplay: false
};

export default videoJsOptions;
