/* eslint-disable */

import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
  Button,
  TextField,
  DialogContentText,
  Fab,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import CancelIcon from "@material-ui/icons/Cancel";
import AlertDialog from "./AlertDialog";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import firebase from "../../../utilities/Firebase";
const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function ProductDialog(props, {}) {
  const { open, Product, onClose } = props;
  const [alertDialog, setAlertDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [meetingSpin, setmeetingSpin] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [mailerror, setMailError] = useState(false);

  const setAlertDialogfunc = () => {
    setAlertDialog({
      ...alertDialog,
      isOpen: false,
      title: "",
      subTitle: "",
    });
  };

  const [customer, setCustomer] = useState({
    customerFName: "",
    customerLName: "",
    customerEmail: "",
    customerMessage: "",
  });

  const [productD, setProduct] = useState({
    productId: "",
    productName: "",
    productDescription: "",
    productImage: [],
  });
  useEffect(() => {
    setProduct({
      productName: Product[0] ? Product[0].name : "walstrat_default",
      productId: Product[0] ? Product[0].id : "0123456789",
    });
  }, [Product]);

  const emailcheck = () => {
    if (customer.customerEmail === "") {
      setErrorText(null);
    } else {
      if (ValidateEmail(customer.customerEmail)) {
        setErrorText(null);
        setMailError(false);
      } else {
        setMailError(true);
        setErrorText("Please enter valid email");
      }
    }
  };

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }
  const handleCustomerChange = (event) => {
    setCustomer({
      ...customer,
      [event.target.name]: event.target.value,
    });
    console.log("customerDetails", customer);
  };
  const handleCloseProductInquiry = () => {
    setmeetingSpin(true);
    if (customer.customerFName === "") {
      setAlertDialog({
        isOpen: true,
        title: "Please enter your name",
      });
      setmeetingSpin(false);
    } else if (customer.customerEmail === "") {
      setAlertDialog({
        isOpen: true,
        title: "Please enter your email",
      });
      setmeetingSpin(false);
    } else if (customer.customerMessage === "") {
      setAlertDialog({
        isOpen: true,
        title: "Please enter your query",
      });
      setmeetingSpin(false);
    } else if (mailerror) {
      setAlertDialog({
        isOpen: true,
        title: "Please enter valid mail",
      });
      setmeetingSpin(false);
    } else {
      new Promise((res, rej) => {
        firebase
          .firestore()
          .collection("walstrat")
          .doc("root")
          .collection("Enquiry")
          .add({
            type: "Product Enquiry",
            userName: customer.customerFName + customer.customerLName,
            userEmail: customer.customerEmail,
            userMessage: customer.customerMessage,
            dateTime: new Date().getTime(),
            productName: productD.productName,
            productId: productD.productId ? productD.productId : "",
            mailStatus: "failure",
          })
          .then((response) => {
            res(response.id);
            console.log("doc id", response.id);
          });
      })
        .then((res) => {
          sendEmail(
            customer.customerFName,
            customer.customerLName,
            customer.customerEmail,
            customer.customerMessage,
            productD.productName,
            productD.productId,
            res
          );
          handleClose();
          setmeetingSpin(false);
        })
        .catch((err) => {
          console.log("error on upload", err);
        });
    }
  };

  const handleClose = () => {
    setCustomer({
      ...customer,
      customerFName: "",
      customerLName: "",
      customerEmail: "",
      customerMessage: "",
    });
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <h1 className="titleschedule">Product Inquiry Form</h1>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {"Enter your details so we can get back to you"}
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                name="customerFName"
                onChange={handleCustomerChange}
                value={customer.customerFName}
                variant="standard"
                label={"Enter your first name"}
                autoFocus
                type="text"
                fullWidth
                id="customerFName"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="customerLName"
                onChange={handleCustomerChange}
                value={customer.customerLName}
                variant="standard"
                label={"Enter your last name"}
                type="text"
                fullWidth
                id="customerLName"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={mailerror}
                helperText={errorText}
                onBlur={emailcheck}
                name="customerEmail"
                onChange={handleCustomerChange}
                required
                value={customer.customerEmail}
                variant="standard"
                id="customerEmail"
                label={"Enter your Email"}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="customerMessage"
                onChange={handleCustomerChange}
                value={customer.customerMessage}
                variant="standard"
                label={"Enter your querry"}
                type="text"
                fullWidth
                id="customerMessage"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className="btn-glow btn-hover-shine fontschedule"
          >
            <CancelIcon style={{ marginRight: "5px" }} />
            Cancel
          </Button>
          {meetingSpin ? (
            <CircularProgress
              color="secondary"
              style={{ margin: "5px" }}
              size={20}
            />
          ) : (
            ""
          )}
          {
            <Button
              onClick={handleCloseProductInquiry}
              disabled={meetingSpin}
              className="btn-glow btn-hover-shine fontschedule"
            >
              <SendIcon style={{ marginRight: "5px" }} /> Inquire Now
            </Button>
          }
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertDialog={alertDialog}
        setAlertDialog={setAlertDialogfunc}
      />
    </React.Fragment>
  );
}

function sendEmail(
  customerFName,
  customerLName,
  customerEmail,
  customerMsg,
  product,
  productId,
  firebaseId
) {
  var customerName = customerFName + " " + customerLName;
  const regMessage =
    "Dear Admin," +
    "<br><br/>" +
    `<p> You get this email because one of your customer inquired for your products. </p>` +
    `<br></br>` +
    `<p> Details are as follow :: </p>` +
    `<br></br><br></br>` +
    `<table style="width:100%">
    <tr>
      <th>Customer Name</th>
      <th>Customer Email</th>
      <th>Inquiry For</th>
      <th>Inquiry Message</th>
    </tr>
    <tr>
      <td>${customerName}</td>
      <td>${customerEmail}</td>
      <td>${product}</td>
      <td>${customerMsg}</td>
      </tr>
      </table>`;

  var obj = {
    useremail: "walstrat999@gmail.com", // admin mail
    message: regMessage,
    expoName: "walstrat-info",
    senderName: customerName,
    subject: `Product Inquiry by ${customerEmail} `,
    ccUser: "jyoti.kaur67@gmail.com",
    bccUser: "",
  };

  console.table("Mail data => ", regMessage);
  axios
    .post(
      "https://us-central1-sendtoemail-3610d.cloudfunctions.net/commonGmailSendEmailFunction",
      obj,
      {
        headers: {
          // 'x-access-token': token
        },
      }
    )
    .then((regEmail) => {
      new Promise((res, rej) => {
        firebase
          .firestore()
          .collection("walstrat")
          .doc("root")
          .collection("Enquiry")
          .doc(firebaseId)
          .update({
            mailStatus: "success",
          });
        res("updated");
      })
        .then((res) => {
          alert("mail sent succesfully");
          console.log("Done", regEmail);
        })
        .catch((err) => {
          console.log("error on updating", err);
        });
    })
    .catch((error) => {
      console.log("Not Done", error);
    });
}
