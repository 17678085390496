// import React, { Fragment } from "react";
// import {
//   Header,
//   TextBlock,
//   Process,
//   Facts2,
//   Portfolio,
//   Partners,
// } from "../../widgets";
// import { useCustomState } from "../../../state/state";

// export default () => {
//   const state = useCustomState()[0];

//   return (

//     <Fragment >
//       {/* <Header img={state.data.header_bgs.about}>About company</Header> */}
//       <TextBlock  />
//       {/* <Process data={state.data.process} />
//       <Facts2 data={state.data.facts2} />
//       <Portfolio data={state.data.portfolio} />
//       <Partners data={state.data.partners} /> */}
//     </Fragment>
//   );
// };
import React, { Component } from "react";
import Flip from "react-reveal/Flip";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import "../About/About.css";
import styles from "../../widgets/TextBlock//TextBlock.module.scss";
import Layout from "../../layouts/Layout/Layout";
import THEME from "../../../state/theme";
import { Button } from "../../elements";
import { useCustomState } from "../../../state/state";
import aboutimg from "../../../assets/placeholders/us.jpg";
import "../../widgets/TextBlock/about.css";
//import walstartvideo from '../../../assets/placeholders/walstartvideo.mp4';
import walstartimg from "../../../assets/placeholders/videoimage.png";
import logo from "../../../assets/placeholders/WALSTRAT LOGO-.png";
import Videojs from "../../../components/videojs";
import { Footer, Header } from "../../layouts";

// import LazyLoad from "react-lazy-load";
// import videoJsOptions from '../../../components/videojs-option'
// const videoJsOptions = {
//   autoplay: true,
//   muted: false,
//   controls: true,
//   fluid: true,
//   loop: true,
// };

export default class Aboutfullpage extends Component {
  oniframe = () => {
    document.getElementById("Overlay").style.display = "block";
    // document.getElementById("body").style.overflow = "hidden";
    // document.getElementById("Videoid").src = 'assets/images/medex/medexvideo.mp4';
    document.getElementById("Videoid").style.display = "block";
    document.getElementById("Videoid").play();
  };
  offvideo = () => {
    document.getElementById("Overlay").style.display = "none";
    // document.getElementById("Videoid").src = '';
    document.getElementById("Videoid").style.display = "block";
    document.getElementById("Videoid").pause();
  };
  aboutpage = () => {
    window.location.href = "/servicepage3";
  };
  render() {
    return (
      <div>
        <div>
          <Header />
        </div>

        <div className="container" id="about">
          <div className="row">
            <div className="col-md-6 col-12 col-lg-6">
              <article
                className={styles.wrapper}
                style={{ paddingBottom: "0" }}
                id=""
              >
                <div className={styles.info}>
                  <Flip top>
                    <span
                      className={styles.subtitle}
                      style={{ color: THEME.color }}
                    >
                      About WALSTRAT
                    </span>
                  </Flip>
                  <Fade left>
                    <p className={styles.paragraph}>
                      WALSTRAT is short for Walnut Strategies. The name, chosen
                      after considerable deliberation, carries great symbolism.
                      Walnuts have always been equated with wisdom, intelligence
                      and inspiration. A lot of writers have used walnuts as a
                      metaphor for strength and enduring hardships. Walnuts also
                      mark new beginnings. Walnut Srategies aims at providing
                      it's clients the whole gamut of strategic services in the
                      entire range of trade and investment activities as a
                      one-stop-shop.
                    </p>
                    <p className={styles.paragraph}>
                      The company is founded in September 2020 by Mrs.Jyoti Kaur
                      who had held senior positions in governmental,
                      quasi-governmental and private enterprises covering varied
                      fields, all focussing on trade and investment promotion,
                      The stakeholders who worked with her vouchsafe for her
                      vision, hard work, result-oriented approach, integrity and
                      dedication to the tasks entrusted to her. Her expertise in
                      providing practicable inputs in policy formulation for
                      trade policies that are aimed at trade promotion is well
                      recognised. She has held key positions in India's Apparel
                      Export Promotion Council, Services Export Promotion
                      Council, Plastics Export Promotion Council, to cite a few.
                      She has led highly successful business delegations to
                      Africa, Middle East, Central Asia, China, etc
                    </p>{" "}
                    <p
                      className={styles.paragraph}
                      style={{ marginBottom: "2rem" }}
                    >
                      After serving in various prestigious government,
                      semi-govetnment and private entities, it was time for her
                      to move on as an independent consultant to continue giving
                      her expertise and benefits of her global business contacts
                      to her peers and friends and the business community. With
                      that in mind she took voluntary retirement to launch a
                      streamlined consultancy organization with clear focus.
                      Thus WALSTRAT was born, with her at the helm.
                    </p>
                  </Fade>
                </div>
              </article>
            </div>
            <div className="col-md-6 col-12 col-lg-6">
              <div className="borderabout"></div>
              <Zoom>
                <div className="flip-cardabout">
                  <div className="flip-cardabout-inner">
                    <div className="flip-cardabout-front">
                      <img src={aboutimg} alt="Avatar" />
                    </div>
                    <div
                      className="flip-cardabout-back"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={logo} alt="Avatar" style={{ height: "auto" }} />
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
            <div className="col-md-12 col-12 col-lg-12">
              <article
                className={styles.wrapper}
                style={{ paddingTop: "0", marginTop: "32px" }}
              >
                <div className={styles.info} id="aboustustext">
                  <Fade left>
                    <p className={styles.paragraph}>
                      Walstrat Solutions provides a wide range of services to
                      individuals and businesses alike - from Heavy Machinery to
                      Education, Research and Development to Financial
                      Solutions, Walstrat has got your back. With a diverse and
                      skilled advisory board, with a combined experience of over
                      50 years - Walstrat offers you the best in the market.
                    </p>

                    <p className={styles.paragraph}>
                      In a dynamic, Young Country like India which is the new
                      hub of start-ups, we identified the need for an entity
                      that provided all-around holistic support and services to
                      young individuals and businesses.
                    </p>

                    <p className={styles.paragraph}>
                      Enhancing the business operations and easing the
                      day-to-day processes of our clientele is the sole goal of
                      the Company.
                    </p>

                    <p className={styles.paragraph}>
                      The company is fully capable of providing all kinds of
                      services involving national and international trade;
                      including but not limited to turnkey project services,
                      marketing, and formulating business strategies.
                    </p>
                    <p className={styles.paragraph}>
                      With an aim at extending new, innovative technologies in
                      engineering and aviation services, we help trade-in
                      turnkey projects related to engineering services along
                      with offering unique solutions in marketing to aviation
                      services.
                    </p>
                    <p className={styles.paragraph}>
                      Being pioneers in all things consultancy and advisory, we
                      also specialize in drafting strategies and services
                      policies for Indian states and international
                      organizations.
                    </p>

                    <p className={styles.paragraph}>
                      Additionally, Artificial Intelligence is one sector
                      wherein our proficiency and efficiency are reflected in
                      the outcomes that show as a result of providing bespoke
                      services catering customized to the clients’ exact
                      requirements.
                    </p>

                    <p className={styles.paragraph}>
                      One key distinguishing factor for WalStrat is it’s being
                      equipped with the tools and techniques for the collection
                      and collation of services data. This makes us unique and
                      one-of-a-kind because there is no other consultancy
                      organization extending this service.
                    </p>

                    <p className={styles.paragraph}>
                      We have the requisite expertise to extend services across
                      an eclectic mix of industries and sectors such as
                      Construction, Renewable Energy, Education, Maritime trade,
                      Retail, Entertainment services, Co-production services,
                      Designing services as varied as those in the field of
                      spaceship design as well as designing of water
                      vessels/ships and architectural designs. We are also
                      equipped to extend services in the field of Agriculture
                      including but not limited to equipment set-up, advanced
                      seed implant services, irrigation services, and
                      introduction of renewable energy into agricultural
                      activities.
                    </p>

                    <p className={styles.paragraph}>
                      We can help to set the base of Indian companies intending
                      to carve a footprint in international markets, and at the
                      same time run due diligence for them to ensure successful
                      operations. In a similar light, we work with our clients
                      abroad who would like to grab a foothold and share a piece
                      of the pie that is the humongous Indian economy.
                    </p>

                    <p className={styles.paragraph}>
                      Having been entrusted with such work by the who’s who
                      within the government, embassies, and ministries, Ms.
                      Jyoti Kaur brings over three decades of experience to the
                      table, along with the other promoters of WalStrat who
                      bring to fore remarkable experience in carrying through
                      engineering projects from start to execution to
                      culmination.
                    </p>

                    <p className={styles.paragraph}>
                      The company is just a few months old, but has been
                      recognized as a startup by the Government of India, and
                      has already established MoUs with some companies,
                      representing them in India and vice versa.{" "}
                    </p>

                    <p className={styles.paragraph}>
                      This is what could prove to be a key differentiator
                      between WalStrat and other start-ups: acting as lead
                      managers and extending handheld support in respect of
                      coordination of work on behalf of companies, firms, and
                      any other entities to monitor the same. We undertake
                      appropriate services to identify the scope or potential of
                      industrial and economic development in any particular
                      geographical area or location in or outside the country.
                    </p>

                    <p className={styles.paragraph}>
                      We do believe we would be able to extend the kind of
                      professionalism that comes through the virtues of tried
                      and tested experience, along with the wisdom that
                      accompanies it.
                    </p>
                    <p className={styles.paragraph}>
                      We cater to clients from all walks of life,
                      Industrialists, Private companies, Corporates, MNCs,
                      Institutions, Embassies, Younger Professionals.{" "}
                    </p>
                    <p className={styles.paragraph}>
                      Get in touch with us for solutions that’ll help you
                      enhance your business operations and workings.
                    </p>
                  </Fade>
                </div>
              </article>
            </div>
          </div>
          {/* video section start */}
          {/* <!--==================================================================== 
            Start Video Section
        =====================================================================--> */}

          <section className="video-section pt-185">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <img
                    onClick={this.oniframe}
                    src={walstartimg}
                    style={{ width: "100%", cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </section>
          <div className="overlay" id="Overlay" onclick="offvideo()">
            <div className="closebtn" onClick={this.offvideo}>
              X
            </div>
            <div className="videoposition">
              <video autoPlay loop controls id="Videoid" className="videowidth">
                <source
                  src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/whywalstrat-c.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
        <div style={{ bottom: 0, marginTop: "50px" }}>
          <Footer />
        </div>
      </div>

      // </div >
    );
  }
}
