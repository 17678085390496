import React, { Fragment, useState } from "react";
import styles from "./Contacts2.module.scss";
import Flip from "react-reveal/Flip";

import Layoutcontact from "../../layouts/Layout/Layoutcontact";
import { ContactForm } from "../../ui";
import { AddressBox, Button } from "../../elements";
import THEME from "../../../state/theme";
import { useCustomState } from "../../../state/state";
import "./contact.css";
//import video from '../../../assets/placeholders/contactvideo.mp4';
import MeetingDialog from "./MeetingDialog";
import * as Button1 from "@material-ui/core";
import LazyLoad from "react-lazy-load";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AlertDialog from "./AlertDialog";
import axios from "axios";
import firebase from "../../../utilities/Firebase";
import assistant from "../../../assets/placeholders/bot.png"

const talkbotLink = "https://vr.melzo.com/dekho/U2FsdGVkX19a92D4bViaYpN9Tu9lwZYbgh8WtjyDeBvl3sQ9NQw3oQ4ThdCSqC3S0cc_Fj9SJ8AhM0OYVEhJ5Q=="

export default () => {
  // const actions = useCustomState()[1];
  const [openMeeting, setOpenMeeting] = React.useState(false);
  const [fname, setFname] = React.useState("");
  const [lname, setLname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleClickOpenMeeting = () => {
    setOpenMeeting(true);
  };
  const handleClickCloseMeeting = () => {
    setOpenMeeting(false);
  };

  const handleClickOpenTalkbot = () => {
    window.open(talkbotLink,"_blank")
  }

  const [alertDialog, setAlertDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const setAlertDialogfunc = () => {
    setAlertDialog({
      ...alertDialog,
      isOpen: false,
      title: "",
      subTitle: "",
    });
  };

  const [open, setOpen] = useState(false);

  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  const handleClose = () => {
    document.getElementById("fname").value = "";
    document.getElementById("lname").value = "";
    document.getElementById("email").value = "";
    document.getElementById("message").value = "";
    document.getElementById("checked").checked = false;
    setOpen(false);
  };

  const handleAlertClose = () => {
    setAlert(false);
  };
  const onContactSubmit = () => {
    let fName = document.getElementById("fname").value;
    let lName = document.getElementById("lname").value;
    let email = document.getElementById("email").value;
    let message = document.getElementById("message").value;
    let checked = document.getElementById("checked").checked;

    if (fName == "") {
      setAlertDialog({
        isOpen: true,
        title: "Please enter your name",
      });
    } else if (email == "") {
      setAlertDialog({
        isOpen: true,
        title: "please enter email",
      });
      // setAlertMsg("please enter email");
    } else if (message == "") {
      setAlertDialog({
        isOpen: true,
        title: "please enter your message",
      });
      // setAlertMsg("please enter your message");
    } else if (!checked) {
      setAlertDialog({
        isOpen: true,
        title: "please accept the data privacy option",
      });
      // setAlertMsg("please accept the data privacy option");
    } else if (!ValidateEmail(email)) {
      setAlertDialog({
        isOpen: true,
        title: "please enter valid email",
      });
    } else {
      new Promise((res, rej) => {
        firebase
          .firestore()
          .collection("walstrat")
          .doc("root")
          .collection("Enquiry")
          .add({
            type: "Website Enquiry",
            userName: fName + lName,
            userEmail: email,
            userMessage: message,
            dateTime: new Date().getTime(),
            mailStatus: "failure",
          })
          .then((response) => {
            res(response.id);
          });
      })
        .then((res) => {
          sendEmail(fName, lName, email, message, res);
          setOpen(true);
        })
        .catch((err) => {
          console.log("error on upload", err);
        });
    }
  };
  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  return (
    <Fragment>
      <div className={styles.header} id="contact">
        <LazyLoad>
          <Flip top>
            <span className="subtitle" style={{ color: THEME.color }}>
              Send message
            </span>
          </Flip>
        </LazyLoad>
        <LazyLoad>
          <h2>Feel free to contact us.</h2>
        </LazyLoad>

        {/* <p>
          Energistically predominate high-payoff alignments whereas maintainable
          materials timely deliverables. Synergistically leverage other's
          holistic mindshare via high-payoff expertise.
        </p> */}
      </div>
      <div>
        {/* <Button1.Button variant="contained" color="primary" onClick={handleClickOpenMeeting} className="schedulebtn">MyButton</Button1.Button> */}
        
        <button
          onClick={handleClickOpenMeeting}
          className="schedulebtn btn-glow btn-hover-shine"
        >
          Schedule Meeting
        </button>
        
          <img src={assistant} className="schedulebtn2 btn-glow btn-hover-shine" onClick={handleClickOpenTalkbot} title="Walstrat Virtual TalkBot" />
       
      </div>
      <div className="row">
        <div className="col-12">
          <div style={{ position: "absolute" }} className="contactvideo">
            <LazyLoad>
              <video
                autoPlay
                loop
                muted
                id="contactbgvideo"
                // style={{ height: "93vh" }}
              >
                <source
                  src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/contactvideo-c.mp4"
                  type="video/mp4"
                />
              </video>
            </LazyLoad>
          </div>
        </div>
      </div>
      <div className="contactvideolayer"></div>
      <div>
        <div style={{ marginBottom: "50px" }}>
          <div className="row">
            <div className="col-12 col-md-4 col-lg-4"></div>
            <div className="col-12 col-md-4 col-lg-4">
              <div className="cont">
                <LazyLoad>
                  <div className="contact">
                    <input
                      type="text"
                      className="c1 colorinput"
                      id="fname"
                      placeholder="First name *"
                    />

                    <input
                      type="text"
                      className="c2 colorinput"
                      id="lname"
                      placeholder="Last name *"
                    />

                    <input
                      type="email"
                      className="c3 colorinput"
                      id="email"
                      placeholder="Email address *"
                    />

                    <textarea
                      className="c4 colorinput"
                      placeholder="Message"
                      id="message"
                    ></textarea>
                    <p className="termstext">
                      <input
                        type="checkbox"
                        id="checked"
                        style={{ marginRight: "10px" }}
                      />
                      I here by consent to the processing of my personal data in
                      accordance with the data protection declaration of
                      consent.
                    </p>
                    <Button click={onContactSubmit} hoverType="solid-gray-tb">
                      Submit {/* to="/blog/post_4" */}
                    </Button>
                  </div>
                </LazyLoad>
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-4"></div>
          </div>
        </div>
        <Layoutcontact col="4" className="heightlayout">
          {/* <div className="heightlayout"> */}
          <LazyLoad>
            <AddressBox
              icon="las la-phone"
              title="Call Us"
              text="+91 99101 69994"
            />
          </LazyLoad>
          <LazyLoad>
            <AddressBox
              icon="la la-envelope-open"
              title="Mail Us"
              text="walstrat999@gmail.com"
            />
          </LazyLoad>
          <LazyLoad>
            <AddressBox
              icon="la la-video"
              title="24x7 Buyer Seller Meet"
              text="schedule a video call"
            />
          </LazyLoad>
          <LazyLoad>
            <AddressBox icon="las la-comment" title="Live " text="Live  24/7" />
          </LazyLoad>
          {/* </div> */}
        </Layoutcontact>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          TransitionComponent={TransitionUp}
          autoHideDuration={6000}
          onClose={handleClose}
          typeof="alert"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          <Alert severity="success">Message sent</Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={alert}
          TransitionComponent={TransitionUp}
          autoHideDuration={1000}
          onClose={handleAlertClose}
          typeof="alert"
        >
          <Alert severity="alert">{alertMsg}</Alert>
        </Snackbar>
        <AlertDialog
          alertDialog={alertDialog}
          setAlertDialog={setAlertDialogfunc}
        />
        <MeetingDialog open={openMeeting} onClose={handleClickCloseMeeting} type={"scheduleMeeting"} />
      </div>

      {/* <Layout col="2">
        <div className={styles.wrapper}>
          <ContactForm style={{ border: "1px solid #eee" }} />
        </div>

        <div className={styles.info}>
          <i
            className="las la-long-arrow-alt-right"
            style={{ color: THEME.color }}
          />
          <h2>Ready to start making busines with us?</h2>
          <p>
            Rapidiously transform integrated processes via frictionless
            paradigms. Intrinsicly productize proactive catalysts for change via
            economically sound relationships.
          </p>
          <Button
            click={() =>
              actions.toogleRequest(
                "I'm ready to start making business with you!"
              )
            }
            type="solid-gray-tb"
            hoverType="solid-white-tb"
            after="&#xf107;"
          >
            Get quote
          </Button>
        </div>
      </Layout> */}
    </Fragment>
  );
};
function sendEmail(
  customerFName,
  customerLName,
  customerEmail,
  customerMsg,
  firebaseId
) {
  var regName = customerFName + " " + customerLName;
  var regEmail = customerEmail;
  var regMsg = customerMsg;
  var ref = firebase
    .firestore()
    .collection("walstrat")
    .doc("root")
    .collection("Enquiry")
    .doc(firebaseId);

  console.log("Mail Data", regName, regEmail, regMsg);
  const adminMsg =
    "Dear Admin" +
    "," +
    "<br/><br/>" +
    `<p>You received this mail because one of your customer try to contact you from the contact page of website.</p>
    <br/>
    <p> Details are as follow ::
    <br></br>` +
    `<table style={"width:100%",border : "1px solid black"}>
    <tr>
      <th>Customer Name</th>
      <th>Customer Email</th>
      <th>Message</th>
    </tr>
    <tr>
      <td>${regName}</td>
      <td>${regEmail}</td>
      <td>${customerMsg}</td>
      </tr>
      </table>
    <br/>
  <p>have a wonderful day! </p>
  <br/>
  <p>Thank you,</p>
  <p>Walstrat Services Pvt. Ltd. </p>
  <br/>`;

  const regMessage =
    "Dear " +
    regName +
    "," +
    "<br/><br/>" +
    `<p>Thank you for contact us we will get back to you.</p>
  
    <br/>
  <p>have a wonderful day! </p>
  <br/>
  <b>If you have any queries please reply to this mail.</b>
  <br/>
  <p>Thank you,</p>
  <p>Walstrat Services Pvt. Ltd. </p>
  <br/>`;

  var obj = {
    useremail: regEmail, // User Mail Id
    message: regMessage,
    expoName: "walstrat-info",
    senderName: "Walstrat Services Pvt. Ltd.",
    ccUser: "jyoti.kaur67@gmail.com", // Admin Mail Id
    bccUser: "",
    subject: "Thank you for contact us!",
  };

  var adminObj = {
    useremail: "walstrat999@gmail.com", // Admin Mail Id
    message: adminMsg,
    expoName: "walstrat-info",
    senderName: "Walstrat Services Pvt. Ltd.",
    ccUser: "jyoti.kaur67@gmail.com",
    bccUser: "",
    subject: `${regEmail} contact via website!.`,
  };

  axios
    .post(
      "https://us-central1-sendtoemail-3610d.cloudfunctions.net/commonGmailSendEmailFunction",
      obj,
      {
        headers: {
          // 'x-access-token': token
        },
      }
    )
    .then((regEmail) => {
      console.log("Done", regEmail);
      axios
        .post(
          "https://us-central1-sendtoemail-3610d.cloudfunctions.net/commonGmailSendEmailFunction",
          adminObj,
          {
            headers: {
              // 'x-access-token': token
            },
          }
        )
        .then((adminMail) => {
          new Promise((res, rej) => {
            ref.update({
              mailStatus: "success",
            });
            res("success");
          })
            .then((res) => {
              console.log("Done", adminMail);
            })
            .catch((err) => {
              console.log("error on updating ", err);
            });
        })
        .catch((error) => {
          console.log("Not Done", error);
          new Promise((res, rej) => {
            ref.update({
              mailStatus: "Admin-side failure",
            });
            res("success");
          })
        });
    })
    .catch((error) => {
      console.log("Not Done", error);
      new Promise((res, rej) => {
        ref.update({
          mailStatus: "Both-side failure",
        });
        res("success");
      })
    });
}
