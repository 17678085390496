import React, { useState } from "react";
import styles from "./Services2.module.scss";
import THEME from "../../../state/theme";
import "bootstrap/dist/css/bootstrap.min.css";
import Flip from "react-reveal/Flip";

import manuimg1 from "../../../assets/placeholders/services/manu.jpg";
import aerospaceimg from "../../../assets/placeholders/services/aerospace.jpg";
import automobile1img from "../../../assets/placeholders/services/automobile1.jpg";
import consumerimg from "../../../assets/placeholders/services/consumer.jpg";
import development1img from "../../../assets/placeholders/services/development1.jpg";
import educationimg from "../../../assets/placeholders/services/education.jpg";
import financialimg from "../../../assets/placeholders/services/financial.jpg";
import FMCGimg from "../../../assets/placeholders/services/FMCG.jpg";
import Healthcareimg from "../../../assets/placeholders/services/healthcare.jpg";
import Industrialimg from "../../../assets/placeholders/services/intrnet.jpg";
import Infrastructureimg from "../../../assets/placeholders/services/infrastructure.jpg";
import Logisticsimg from "../../../assets/placeholders/services/logistics.jpg";
import Tourismimg from "../../../assets/placeholders/services/tourism.jpg";
import MediaandEntertainmentimg from "../../../assets/placeholders/services/media and entertainment.jpg";
import Metalsimg from "../../../assets/placeholders/services/miningmetals.jpg";
import Oilimg from "../../../assets/placeholders/services/gas and oil.jpg";
import Outsourcingimg from "../../../assets/placeholders/services/outscourcing.jpg";
import Pharmaimg from "../../../assets/placeholders/services/pharma.jpg";
import Professionalimg from "../../../assets/placeholders/services/services.jpg";
import RealEstateimg from "../../../assets/placeholders/services/real estate.jpg";
import Retailimg from "../../../assets/placeholders/services/retail.jpg";
import Mergersimg from "../../../assets/placeholders/services/power.jpg";
// import aviationgif from '../../../assets/placeholders/aviation.gif';
import educationgif from "../../../assets/placeholders/education.gif";
import aviation1gif from "../../../assets/placeholders/ezgif.com-gif-maker (3).gif";

//import educationvideo from '../../../assets/placeholders/education.mp4';
//import Researchvideo from '../../../assets/placeholders/Researchvideo.m4v';
//import aviationvideo from '../../../assets/placeholders/aviation.m4v';
// import automobilevideo from '../../../assets/placeholders/automobile.m4v';
//import HEALTHCAREvideo from '../../../assets/placeholders/servicesvideo/HEALTHCARE.m4v';
// import Mergervideo from '../../../assets/placeholders/servicesvideo/Merger.m4v';
// import Pharmavideo from '../../../assets/placeholders/servicesvideo/Pharma.m4v';
// import Professionalvideo from '../../../assets/placeholders/servicesvideo/Professional.m4v';
// import fmcgvideo from '../../../assets/placeholders/servicesvideo/fmcg.m4v';
// import miningvideo from '../../../assets/placeholders/servicesvideo/mining.mp4';
// import industrialvideo from '../../../assets/placeholders/servicesvideo/industrial.m4v';
// import Electricityvideo from '../../../assets/placeholders/servicesvideo/Electricity.mp4';
// import GasOilvideo from '../../../assets/placeholders/servicesvideo/oil & gas.mp4';
// import Infrastructurevideo from '../../../assets/placeholders/servicesvideo/Infrastructure.m4v';
// import Logisticsvideo from '../../../assets/placeholders/servicesvideo/Logistics.mp4';
// import Mediavideo from '../../../assets/placeholders/servicesvideo/Media.mp4';
// import outsourcingvideo from '../../../assets/placeholders/servicesvideo/outsourcing.mp4';
// import Retailvideo from '../../../assets/placeholders/servicesvideo/Retail.m4v';
// import Tourismvideo from '../../../assets/placeholders/servicesvideo/Tourism.mp4';
// import Financialvideo from '../../../assets/placeholders/servicesvideo/Financial.mp4';
//import Artificialvideo from '../../../assets/placeholders/servicesvideo/Artificial-Intelligence.m4v';
// import marketingvideo from '../../../assets/placeholders/servicesvideo/Marketing.mp4';
// import consultancyvideo from '../../../assets/placeholders/servicesvideo/consultancy.mp4';

import planeicon from "../../../assets/placeholders/icon/plane.png";
import educationicon from "../../../assets/placeholders/icon/education.png";
import automobileicon from "../../../assets/placeholders/icon/AUTOmobile.png";
import RESEARCHicon from "../../../assets/placeholders/icon/RESEARCH.png";
import industrialicon from "../../../assets/placeholders/icon/industrial.png";
import infraicon from "../../../assets/placeholders/icon/infra.png";
import mergersicon from "../../../assets/placeholders/icon/mergers.png";
import outsourcingicon from "../../../assets/placeholders/icon/outsourcing.png";
import realestateicon from "../../../assets/placeholders/icon/real estate.png";
import tourismicon from "../../../assets/placeholders/icon/tourism.png";
// import outsourcingicon from '../../../assets/placeholders/icon/outsourcing.png';
import oilandgasicon from "../../../assets/placeholders/icon/oilandgas.png";
import electricityicon from "../../../assets/placeholders/icon/electricity.png";
import fmcgicon from "../../../assets/placeholders/icon/fmcg.png";
import miningmineralsmicon from "../../../assets/placeholders/icon/miningminerals.png";
import hospitalicon from "../../../assets/placeholders/icon/HOSPITAL.png";
import professionalservicesicon from "../../../assets/placeholders/icon/professionalservices.png";
import aiicon from "../../../assets/placeholders/icon/AI.png";
import consultancyicon from "../../../assets/placeholders/icon/consultancy.png";
import marketingicon from "../../../assets/placeholders/icon/marketing.png";
import researchanddevelopmenticon from "../../../assets/placeholders/icon/research-and-development.png";
import mediaicon from "../../../assets/placeholders/icon/media1.png";
import pharmaicon from "../../../assets/placeholders/icon/pharma1.png";
import retailicon from "../../../assets/placeholders/icon/retail.png";
import Financialicon from "../../../assets/placeholders/icon/Financial services.png";
import logisticsicon from "../../../assets/placeholders/icon/Logistics.png";
import manpowericon from "../../../assets/placeholders/icon/Manpower.png";
import eventicon from "../../../assets/placeholders/icon/event.png";
// import manpowervideo from "../../../assets/placeholders/manpower.m4v";
// import eventvideo from "../../../assets/placeholders/event.m4v";

import LazyLoad from "react-lazy-load";
import Videojs from "../../videojs";
import videoJsOptions from "../../videojs-option";
import Particle from "./particle";
import Aviation from "./aviation";
import { Button } from "@material-ui/core";

const realestatevideo =
  "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/realestate-c.mp4";

export default class Services2 extends React.Component {
  componentDidMount() {}

  loadSector1() {
    let sectorParent = document.getElementById("sector1");
    for (let i = 0; i < 100; i++) {
      let tempObj = document.createElement("a");

      // <a class="reflection-grid-cell reflection-grid-cell-1" href="#"></a>
    }
  }
  openservicepage() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/aviation-c.m4v"
    );

    localStorage.setItem("title", "Aviation and Defense");
    localStorage.setItem(
      "colorCode",
      "linear-gradient(rgba(207, 124, 201, 0.7), rgba(65, 196, 206, 0.7))"
    );
    localStorage.setItem(
      "discription1",
      "Marketing services for sales on private Aircraft, Jet, Private Aircraft, Corporate Aircraft, Helicopters. Reliable Agents for High Tech Machines."
    );
    localStorage.setItem(
      "discription2",
      "To assist clients in the acquisition of P Developing MRO Agreements between other countries and India and getting them executed."
    );
    localStorage.setItem(
      "discription3",
      " To set up manufacturing units, acquire private air travel vehicles, and to reach the international market."
    );
    localStorage.setItem(
      "discription4",
      " Consulting on infrastructure, facilities, ground equipment and systems, airside capacity and resilience, punctuality and delay optimization techniques, and more. "
    );
    localStorage.setItem(
      "discription5",
      "To provide Data Services, Risk Analysis Studies, Extensive Market Studies and to make informed decisions that eliminate the chances of Losses."
    );
    localStorage.setItem(
      "discription6",
      "Facilitating the setup of Manufacturing Units, Technology Mergers, Facilitating Joint Ventures with Foreign Companies in India, or helping them set up bases in India."
    );
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage2() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/automobile-c.m4v"
    );

    localStorage.setItem("title", "Automobile & Auto Components");
    localStorage.setItem(
      "discription1",
      "Helping in marketing, mergers and acquisition, and Customized OEM (Original Equipment Manufacturing) components supply."
    );
    localStorage.setItem(
      "discription2",
      "Services for Gear Parts arrangements for OEM and Replacement Markets."
    );
    localStorage.setItem(
      "discription3",
      "Opening up avenues to Export Markets for Vehicles, Gears, Components manufacturing industries."
    );
    localStorage.setItem(
      "discription4",
      "Consultation services to organizations engaged in the manufacturing and distribution of automobiles or auto components in International Distribution Networking."
    );
    localStorage.setItem(
      "discription5",
      "To tackle issues associated with growth strategies sustainability and operational efficiency."
    );
    localStorage.setItem(
      "discription6",
      " Partnering, sourcing, and delivering capabilities & improving Automotive OEMs management."
    );
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage3() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/education-c.mp4"
    );

    localStorage.setItem("title", "Education");
    localStorage.setItem(
      "discription1",
      "To create an atmosphere that promotes talent and nurtures creativity."
    );
    localStorage.setItem(
      "discription2",
      "Helping in advising and leading educational initiatives focused on delivering results to their enterprise."
    );
    localStorage.setItem(
      "discription3",
      "To develop skill sets and educational institutions aligned towards changing needs."
    );
    localStorage.setItem(
      "discription4",
      "To combine primary research with advanced analytics and strategy consulting to help clients realize growth opportunities in new sectors and geographies, including untapped developing countries."
    );
    localStorage.setItem(
      "discription5",
      "To Curriculum Tie-Ups in form of Merging Various Syllabi."
    );
    localStorage.setItem(
      "discription6",
      "Tying up with Foreign Universities, Facilitating Student Exchange Programs and Helping interested students apply to Indian Institutions, etc."
    );
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage4() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/Researchvideo-c.m4v"
    );

    localStorage.setItem("title", "Research and Development");
    localStorage.setItem(
      "discription1",
      "To provide market research services and help you make informed decisions and plan accordingly."
    );
    localStorage.setItem(
      "discription2",
      "Assistance to conduct research, develop concepts, create prototypes, and execute tests that deeply accelerate your speed to promote products/services."
    );
    localStorage.setItem(
      "discription3",
      "Assistance  to develop an idea for getting your designs into production."
    );
    localStorage.setItem(
      "discription4",
      "Complete research and development solutions to R&D-inclined Indian Industries and understand the myriad of government grants and incentives available to R&D and make sure their clients get all they deserve."
    );
    localStorage.setItem(
      "discription5",
      "Product development activities to Nutraceuticals, Ayurveda, Engineering, Chemical & Pharmaceutical etc Companies."
    );
    localStorage.setItem(
      "discription6",
      "Market Research aiding the set up of Chain Stores dealing in Consumer Goods and setting up of Research Labs."
    );
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage5() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/Electricity-c.mp4"
    );

    localStorage.setItem("title", "Electrical & Electronics");
    localStorage.setItem(
      "discription1",
      "To Set up Manufacturing Units, explore the possibilities of Import and Export and more with our seasoned Professional Services."
    );
    localStorage.setItem(
      "discription2",
      "To help SMEs or new entrepreneurs in various aspects of R&D, product prototyping and testing, regulatory agency interaction and approval."
    );
    localStorage.setItem(
      "discription3",
      "To complete manufacturing, packaging, and finally, sales and marketing To meet the global requirements."
    );
    localStorage.setItem(
      "discription4",
      "To fulfill the manpower requirements of the electrical equipment, electronics manufacturing sector for India."
    );
    localStorage.setItem(
      "discription5",
      "Aiding the set up of Electricity Generating Plants Internationally."
    );
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage6() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/Financial-c.mp4"
    );

    localStorage.setItem("title", "Financial services");
    localStorage.setItem(
      "discription1",
      "To help our clients in managing and investing their resources efficiently, so as to harness the maximum ROI."
    );
    localStorage.setItem(
      "discription2",
      "To help financial services institutions in every region and to find innovative ways to optimize processes with our experience across industries."
    );
    localStorage.setItem(
      "discription3",
      "To reduce back-office costs, align IT, and manage risks."
    );
    localStorage.setItem(
      "discription4",
      "To capture the economic benefits of building loyal customers, and plan for evolving competitive and regulatory landscapes."
    );
    localStorage.setItem(
      "discription5",
      "Bringing long-term superior returns to our clients by guiding investments through the Indian Financial Markets and vice versa."
    );
    localStorage.setItem(
      "discription6",
      "Financial advisory and execution services and with our professional expertise aspire to be the financial shelter for our clients."
    );
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage7() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/walstart-service.mp4"
    );

    localStorage.setItem("title", "FMCG");
    localStorage.setItem(
      "discription1",
      "To develop a go-to-market strategy for a new product category to accelerate the growth of existing products through sales & channel excellence, and expanding to newer markets by way of Exports and Imports."
    );
    localStorage.setItem(
      "discription2",
      "Assistance in setting up chain stores, marketing initiatives, and market research, Our expert services give you an edge over the competition."
    );
    localStorage.setItem(
      "discription3",
      "Tackling the highest strategic issues for commodity businesses including the way to grow your brand, gain market share, and increase profits."
    );
    localStorage.setItem(
      "discription4",
      "Assistance to identify the foremost attractive opportunities for growth and build focussed strategies to seize them."
    );
    localStorage.setItem(
      "discription5",
      "To help with  Product life-cycles across consumer goods categories, fast-changing customer needs, intensifying competition, and changing regulatory requirements."
    );
    localStorage.setItem(
      "discription6",
      "To bring your brand’s communications into the digital era with powerful new customer insights."
    );
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage8() {
    localStorage.setItem(
      "src",
      ""
      // "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/HEALTHCARE-c.m4v"
    );

    localStorage.setItem("title", "Healthcare");
    localStorage.setItem(
      "discription1",
      "Complete Consultancy from building the health care institutes from scratch to the actual functioning of the patients with mergers to hospitals from all around the world."
    );
    localStorage.setItem(
      "discription2",
      "Training and Skill Development Programmes, designing Hospitals and more within India and abroad."
    );
    localStorage.setItem(
      "discription3",
      "Providing Pharmaceuticals, machinery supplies and helping you in turn key projects."
    );
    localStorage.setItem(
      "discription4",
      "To set up not only Health care institutes but also Diagnostic Laboratories all around the world."
    );
    localStorage.setItem(
      "discription5",
      "To supply products related to Medical, Ayurvedic, Food, and Dietary supplements."
    );
    localStorage.setItem(
      "discription6",
      "To Handhold The Hospital And Do A Complete Overhaul Of All The Systems And Processes That The Accreditation Body Expects To Follow."
    );
    localStorage.setItem(
      "discription7",
      "To practice, expertly across Pharmaceuticals, Medical Technology, Healthcare Payers, and Delivery Systems, bringing diverse and deep experience to each healthcare engagement."
    );
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    // window.location.href = "/healthcarepage";
    window.location.href = "/servicepage3";
  }
  openservicepage9() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/industrial-c.m4v"
    );

    localStorage.setItem("title", "Industrial");
    localStorage.setItem(
      "discription1",
      "Consultancy services for firms that are seeking assistance to enter into the large Indian market and vice versa and establish their brand names within the sub-continent."
    );
    localStorage.setItem(
      "discription2",
      "A rigorous approach to strategic planning that helps our client attain a favorable position in the market"
    );
    localStorage.setItem(
      "discription3",
      "To meet quality product standards and continually improve its products' cost, effectively to satisfy the ever-growing need of its clients."
    );
    localStorage.setItem(
      "discription4",
      "Helping you in identifying the vision and mission to your business aspirations."
    );
    localStorage.setItem(
      "discription5",
      "Providing an intricate report with our detailed analysis and findings that are aligned with your organizational goals and objectives."
    );
    localStorage.setItem(
      "discription6",
      "Designing parts and systems that make the Equipment work reliably and enable effective manufacturing, assembly, and O&M throughout its life."
    );
    localStorage.setItem(
      "discription7",
      "Helping in Building SOPs and Setting up Apparel Parks."
    );
    localStorage.setItem(
      "discription8",
      "Expertise in Tire Manufacturing Industries on Turnkey Basis."
    );
    localStorage.setItem(
      "discription9",
      "Establishing Rubber and Natural Latex processing Industries - in Creating Mattresses and Pillows from Natural Latex, Coir, Spring  and to Complete Turnkey Projects."
    );
    window.location.href = "/servicepage3";
  }
  openservicepage10() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/Infrastructure-c.m4v"
    );

    localStorage.setItem("title", "Infrastructure");
    localStorage.setItem(
      "discription1",
      "End-to-End planning services for your new ventures, from experienced and expert professionals."
    );
    localStorage.setItem(
      "discription2",
      "Helping you to rapidly capture cost savings opportunities, zero-base your budget, or strategically transform your cost structure."
    );
    localStorage.setItem(
      "discription3",
      "We work with the world's leading construction and building products firms to consistently achieve positive results across favorable and unfavorable market environments."
    );
    localStorage.setItem(
      "discription4",
      "To research, plan, budget, comprehend the necessity of the project and its utility for the general public for ensuring the progression of the project within the pre-construction phase."
    );
    localStorage.setItem("discription5", "");
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage11() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/Logistics-c.mp4"
    );

    localStorage.setItem("title", "Logistics");
    localStorage.setItem(
      "discription1",
      "Our team of experts who have extensive experience and in-depth knowledge of Logistics helps to provide a chain talent landscape."
    );
    localStorage.setItem(
      "discription2",
      "Expertise to help in identifying the proper talent basis for the requirements within the changing Indian business landscape."
    );
    localStorage.setItem(
      "discription3",
      "Offering product engineering services from concept to testing for various systems and components of vehicles."
    );
    localStorage.setItem(
      "discription4",
      "With a profound understanding of end-user requirements, we relentlessly work on adding value to your products."
    );
    localStorage.setItem(
      "discription5",
      "Establishing a multi-channel network including Air freight and sea freight, for multiway movement of materials."
    );
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage12() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/Tourism-c.mp4"
    );

    localStorage.setItem("title", "Tourism");
    localStorage.setItem(
      "discription1",
      "Expert Solutions and Guidance navigating International Markets with Medical Tourism, Business Tourism, and everything in Between."
    );
    localStorage.setItem(
      "discription2",
      "We guide our clients’ endeavors by Export Promotion to various other countries."
    );
    localStorage.setItem(
      "discription3",
      "Offering services advice about Medical Tourism, Domestic Tourism, International Tourism, Ruler Tourism, Business Tourism, Educational Tourism, Wildlife Tourism and many more."
    );
    localStorage.setItem(
      "discription4",
      "Offering Business toBusiness Services/ Business to Customer Services/ Business to Government Services/ Government to Government Services."
    );
    localStorage.setItem("discription5", "");
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    // window.location.href = "/tourismpage";
    window.location.href = "/servicepage3";
  }
  openservicepage13() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/Media-c.mp4"
    );

    localStorage.setItem("title", "Media & Entertainment");
    localStorage.setItem(
      "discription1",
      "Advising clients in all areas of media, entertainment, and technology and providing the seamless integration of our Media, Technology to help with your projects, International Connects for Co-Production, AR/VR etc."
    );
    localStorage.setItem(
      "discription2",
      "To create clarity amid this turmoil and stand ready to help drive excellence across your enterprise — from customer experience design and improving B2B relationships, to transforming operations and managing the risks that come with operating in a rapidly changing environment."
    );
    localStorage.setItem(
      "discription3",
      "The seamless integration of our Media, Technology, and Telecommunications practices to help with your projects."
    );
    localStorage.setItem(
      "discription4",
      "Providing insightful consulting & advisory services that are based on extensive experience, deep research, and an understanding of emerging global and local trends."
    );
    localStorage.setItem("discription5", "");
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage14() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/mining-c.mp4"
    );

    localStorage.setItem("title", "Metals, Minerals & Mining");
    localStorage.setItem(
      "discription1",
      "Catering to the demand for skilled human resources in this highly specialized sector, we find ourselves as the best providers of talent search to deliver value to our clients."
    );
    localStorage.setItem(
      "discription2",
      "Providing an in-depth understanding of the mining and metals industry working closely with companies ranging from exploration to distribution of metals."
    );
    localStorage.setItem("discription3", "");
    localStorage.setItem("discription4", "");
    localStorage.setItem("discription5", "");
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage15() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/oil & gas-c.mp4"
    );

    localStorage.setItem("title", "Oil & Gas");
    localStorage.setItem(
      "discription1",
      "Consultancy to drive performance excellence in production optimization, cost, and capital management, maintenance, and supply chain management."
    );
    localStorage.setItem(
      "discription2",
      "High-quality services and consultancy within all project phases: Feasibility, Concept Studies and Pre-engineering, Designing Services, Basic and Detail Engineering as well as Site Services and As-Built drawings."
    );
    localStorage.setItem(
      "discription3",
      "Complete Services from single discipline engineering to EPCM services for managing the whole project or specific parts of it."
    );
    localStorage.setItem(
      "discription4",
      "We offer full-scope multi-discipline engineering, technical documentation, and project management services directly to oil companies, as well as to oil and gas industry contractors, ship owners, engineering houses, suppliers, and operators."
    );
    localStorage.setItem(
      "discription5",
      "Management for waste oil/diesel/petrochemical disposal and building Affluent management Systems."
    );
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage16() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/outsourcing-c.mp4"
    );

    localStorage.setItem("title", "Outsourcing & Offshoring");
    localStorage.setItem(
      "discription1",
      "To support and guide organizations to set up centers of excellence & innovation labs within their business operations."
    );
    localStorage.setItem(
      "discription2",
      "To enable them to become best in class outsourcing services units, so other units/divisions within the organization are often inspired to emulate their success, thereby raising the general level of excellence and galvanizing confidence in their clients"
    );
    localStorage.setItem("discription3", "");
    localStorage.setItem("discription4", "");
    localStorage.setItem("discription5", "");
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage17() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/Pharma-c.m4v"
    );

    localStorage.setItem(
      "title",
      "Pharma, Lifesciences, Devices & Diagnostics"
    );
    localStorage.setItem(
      "discription1",
      "To help in creating models and help in strengthening the top and the bottom line while continuing the focus on the approach of focusing on the niche segment in areas of products, partners, and markets."
    );
    localStorage.setItem(
      "discription2",
      "Decades of Specialisation in the industry."
    );
    localStorage.setItem(
      "discription3",
      "To implement Strategy Execution, Program Management Office, and Strategic Cost Management, setting up Research Labs to adapt to this evolving and dynamic Indian market."
    );
    localStorage.setItem(
      "discription4",
      "Manufacturing and Setup of plants and machinery dealing in Ayurvedic Medicines, Pharmaceuticals , Nutraceuticals, PPE kits, Surgical Equipments Devices and Diagnostic Equipments."
    );
    localStorage.setItem(
      "discription5",
      "Specialised Product Manufacture Consultancy for mass production of Personalised  Pharmaceuticals, Ayurvedic, Nutraceutical Products."
    );
    localStorage.setItem(
      "discription6",
      "Assistance in set up of Manufacturing Plants and Consultancy."
    );
    localStorage.setItem(
      "discription7",
      "Setting up and upgradation of labs, Regulatory Services of Products on Turnkey Projects."
    );
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage18() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/Professional-c.m4v"
    );

    localStorage.setItem("title", "Professional Services");
    localStorage.setItem(
      "discription1",
      "To provide the best of Professional Services - Legal, Auditory, Architecture, Bookkeeping, Physicians, Veterinarian Doctors, Training and Development Services, Project Management and other Auxiliary Services."
    );
    localStorage.setItem(
      "discription2",
      "To offer Professional Services and  Clients access to the best of Professional Services in the Market to offer you a hassle-free experience."
    );
    localStorage.setItem(
      "discription3",
      "To improve auxiliary services and the vitality of these for the smooth functioning of a business."
    );
    localStorage.setItem("discription4", "");
    localStorage.setItem("discription5", "");
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage19() {
    localStorage.setItem("src", realestatevideo);

    localStorage.setItem("title", " Real Estate");
    localStorage.setItem(
      "discription1",
      "Extensive Network to Explore the Potential in Commercial and Private Real Estate."
    );
    localStorage.setItem(
      "discription2",
      "Consulting services real estate and urban economics to developers, owners, occupiers, institutional investors, and public sector organizations around the world."
    );
    localStorage.setItem(
      "discription3",
      "Some services provided are development, occupational, and investment advice."
    );
    localStorage.setItem(
      "discription4",
      "Customized solutions aligned with the key business objectives of the clients."
    );
    localStorage.setItem("discription5", "");
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage20() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/Retail-c.m4v"
    );

    localStorage.setItem("title", "Retail");
    localStorage.setItem(
      "discription1",
      "Tap the vast market of Customer Retail Goods, and set up Stores and Networks with support from us."
    );
    localStorage.setItem(
      "discription2",
      "Strategy Consulting services to assist clients in addressing their strategic challenges and enabling them to discover value in their business."
    );
    localStorage.setItem(
      "discription3",
      "Helping the clients by navigating the industry through our broad range of consulting services to achieve optimum results in the best possible manner."
    );
    localStorage.setItem(
      "discription4",
      "To provide the gamut of strategy consulting services including Functional Strategy, Strategic Brand Consulting‎, M&A advisory, Marketing, and many more."
    );
    localStorage.setItem("discription5", "");
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage21() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/Merger-c.m4v"
    );

    localStorage.setItem("title", "Mergers and Acquisitions");
    localStorage.setItem(
      "discription1",
      "To Expand your Operations in Newer Markets and amplify your Brand Reach and Brand Value."
    );
    localStorage.setItem(
      "discription2",
      "The company specializes in helping your endeavors expand into newer Markets via Mergers and Acquisitions."
    );
    localStorage.setItem(
      "discription3",
      "To help prospects get in touch with relevant stakeholders, Investors, and Startups."
    );
    localStorage.setItem(
      "discription4",
      "Guidance through the legal, financial aspects, and long-term effects of Mergers and/or Acquisitions and which would be suitable for your establishment."
    );
    localStorage.setItem("discription5", "");
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage22() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/manpower.m4v"
    );
    localStorage.setItem("title", "Manpower , Leaders and Employment");
    localStorage.setItem(
      "discription1",
      "The Experienced and quick-witted Employees of ours will help you"
    );
    localStorage.setItem(
      "discription2",
      "Identifying your skills, talents, and interests and will steer you to choose a suitable position of Employment and the Right Employee."
    );
    localStorage.setItem(
      "discription3",
      "To find you a suitable Employer with over 30 years of experience established business network and cordial relations with the industry’s who’s who."
    );
    localStorage.setItem("discription4", "");
    localStorage.setItem("discription5", "");
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage23() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/Artificial-Intelligence-c.m4v"
    );

    localStorage.setItem("title", "Artificial Intelligence");
    localStorage.setItem(
      "discription1",
      "To help you not just to be with the developing world but to evolve with the same pace and same growth."
    );
    localStorage.setItem(
      "discription2",
      "We will help you increase your productivity and business With our Astonishing strategies and technologies, bright and experienced employees."
    );
    localStorage.setItem(
      "discription3",
      "We will increase your customer engagements with a better experience. All that under one roof with reliability and safety."
    );
    localStorage.setItem(
      "discription4",
      "Remote Administration Tools enhanced by AI give you a reliable way to supervise workers as they’re working remotely."
    );
    localStorage.setItem("discription5", "");
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage24() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/Marketing-c.mp4"
    );

    localStorage.setItem("title", "Marketing");
    localStorage.setItem(
      "discription1",
      "To Widen your business and Audience with our grown network across the globe."
    );
    localStorage.setItem(
      "discription2",
      "To help you in making your products and services popular worldwide with our impactful strategies and consultancies."
    );
    localStorage.setItem(
      "discription3",
      "Our skilled and experienced team will help you with Strategies to target the best market with Customer insights."
    );
    localStorage.setItem(
      "discription4",
      "Organisation of BSMs, establishing Points of Contact to facilitate B2B, B2C, B2G and G2G Trade and Commerce Activities."
    );
    localStorage.setItem(
      "discription5",
      "Services as  Marketing Agents for companies trying to market their product and services into India."
    );
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage25() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/revised/consultancy-c.mp4"
    );

    localStorage.setItem("title", "Consultancy");
    localStorage.setItem(
      "discription1",
      "Proper guidance for different sectors from Aviation and Defence to Healthcare and Tourism by Professionals with optimistic and creative thinking."
    );
    localStorage.setItem(
      "discription2",
      "Helping you take big decisions backed with detailed analysis of the market and considering every aspect related to it."
    );
    localStorage.setItem(
      "discription3",
      "The best available options and solutions for almost all your needs and requirements."
    );
    localStorage.setItem("discription4", "");
    localStorage.setItem("discription5", "");
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  openservicepage26() {
    localStorage.setItem(
      "src",
      "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/event.m4v"
    );

    localStorage.setItem("title", "Events and Networking ");
    localStorage.setItem(
      "discription1",
      "Participate and conduct all sorts of events, via our Virtual Video Conferencing Platform, Melzo Unite. "
    );
    localStorage.setItem(
      "discription2",
      "Enjoy insightful Seminars, Exhibitions, Networking events, Conferences, Buyer Seller Meets (Physical and VIrtual) and more, in the safety of your own home with a completely immersive Virtual Environment with Walstrat."
    );
    localStorage.setItem(
      "discription3",
      ""
    );
    localStorage.setItem("discription4", "");
    localStorage.setItem("discription5", "");
    localStorage.setItem("discription6", "");
    localStorage.setItem("discription7", "");
    localStorage.setItem("discription8", "");
    localStorage.setItem("discription9", "");
    window.location.href = "/servicepage3";
  }
  render() {
    return (
      <section className="services-area section-padding">
        <div className="container">
          <div className="layout-header" id="services">
            {/* <LazyLoad> */}
              <Flip top>
                <span className="subtitle" style={{ color: THEME.color }}>
                  Our services
                </span>
              </Flip>
            {/* </LazyLoad> */}
            {/* <LazyLoad> */}
              <h2>We offer you professional bunch of services.</h2>
            {/* </LazyLoad> */}
            {/* <LazyLoad> */}
              <p className="parargraph">
                Energistically predominate high-payoff alignments whereas
                maintainable materials timely deliverables. Synergistically
                leverage other's holistic mindshare via high-payoff expertise.
              </p>
            {/* </LazyLoad> */}
          </div>

          <div className="row">
            {/* Marketing */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay4">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video7.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Marketing.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                      {/* <img className="Img" src={marketingvideo} alt="Brohm Lake" /> */}
                    </figure>
                    <figcaption className="Figcaption"> Marketing</figcaption>
                    <img
                      src={marketingicon}
                      className="Tourismicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay4">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video7.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Marketing.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                      {/* <img src={marketingvideo} alt="Brohm Lake" /> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                        End to End Marketing Solutions
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage24}
                        >
                         <span><u>Click here </u>to know more about Marketing</span>
                        </button>
                        <br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Consultancy */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay25">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/consultancy.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/consultancy.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                      {/* <img className="Img" src={marketingvideo} alt="Brohm Lake" /> */}
                    </figure>
                    <figcaption className="Figcaption"> Consultancy</figcaption>
                    <img
                      src={consultancyicon}
                      className="Tourismicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay25">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/consultancy.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/consultancy.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                      {/* <img src={marketingvideo} alt="Brohm Lake" /> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                      Our expert solutions help you take better decisions 
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage25}
                        >
                          <span>
                          <u>Click here </u>to know more about Consultancy
                          </span>
                        </button>
                        <br></br>
                     
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>  
            {/* <!-- Aviation and Defense --> */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Aviation /> */}
                  {/* <div id="aeroplane"></div> */}

                  {/* <Particles /> */}
                  <div className="card-front overlay1">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div> */}
                      <LazyLoad>
                        {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/aviation.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/aviation.m4v"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <img className="Img" src={aerospaceimg} alt="Brohm Lake" /> */}
                    </figure>
                    <img
                      src={planeicon}
                      className="topgif positionserviceicon"
                    ></img>
                    <figcaption className="Figcaption ">
                      {" "}
                      Aviation and Defense
                      {/* <img src={planeicon} className="topgif widthtopgif" ></img> */}
                    </figcaption>
                    {/* <div className="overlay1 overlay1position"> </div>    */}

                    {/* <img src="https://aepc-images.s3.ap-south-1.amazonaws.com/image/public/image2d/walstrat/helicopter.png" className="bottomgif"></img> */}
                  </div>
                  <div className="card-back  overlay1">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={aerospaceimg} alt="Brohm Lake" /> */}
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/aviation.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/aviation.m4v"
                            }
                          />
                        </div>
                      </LazyLoad>
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                          Acquire Private Air Vehicles and build up Manufacturing Units
                      </li>
                      <li>         
                      <button className="btn-glow btn-hover-shine" onClick={this.openservicepage} >
                      <span><u>Click here </u>to know more about Aviation and Defense</span></button><br></br>
                      
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Automobile & Auto Components */}
            <div className="col-lg-3 col-md-4  col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}

                  <div className="card-front overlay2">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      {/* <img className="Img" src={automobile1img} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video14.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/automobile.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <img
                      src={automobileicon}
                      className="Financialicon positionserviceicon"
                    ></img>

                    <figcaption className="Figcaption">
                      Automobile & Auto Components
                    </figcaption>
                  </div>

                  <div className="card-back  overlay2">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={automobile1img} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video14.mp4"
                            }
                          />
                          {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/automobile.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                        </div>
                      </LazyLoad>
                    </figure>

                    <ul className="detailul">
                      <li className="detailli">
                          Grow your Business with our superior Strategies and Tech Expertise
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage2}
                        >
                          <span><u>Click here </u>to know more about Automobile & Auto Components</span>
                        </button>
                        <br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Artificial Intelligence */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}

                  <div className="card-front overlay24">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img className="Img" src={Mergersimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video11.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Artificial-Intelligence.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>

                    <figcaption className="Figcaption">
                      {" "}
                      Artificial Intelligence
                    </figcaption>
                    <img
                      src={aiicon}
                      className="Tourismicon positionserviceicon"
                    ></img>
                  </div>

                  <div className="card-back  overlay24">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={Mergersimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video11.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Artificial-Intelligence.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>

                    <ul className="detailul">
                      <li className="detailli">
                      Leverage the power of new-age technologies and enrich your business DNA
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage23}
                        >
                          <span><u>Click here </u>to know more about Artificial Intelligence</span>
                        </button>
                        <br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Education */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle />
    <Particle /> */}

                  <div className="card-front overlay3">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video6.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/education.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                      {/* <img className="Img" src={educationimg} alt="Brohm Lake" /> */}
                    </figure>
                    <img
                      src={educationicon}
                      className="topeducationicon positionserviceicon"
                    ></img>

                    <figcaption className="Figcaption">Education</figcaption>
                  </div>

                  <div className="card-back  overlay3">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={educationimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video6.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/education.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>

                    <ul className="detailul">
                      <li className="detailli">
                        Pace towards success with new learning patterns and innovations
                      </li>
                      <li>
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage3}
                        >
                          <span><u>Click here </u>to know more about Education</span>
                        </button>
                        <br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Electrical & Electronics */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}

                  <div className="card-front overlay5">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img className="Img" src={consumerimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video18.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Electricity.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <img
                      src={electricityicon}
                      className="Electricalicon positionserviceicon"
                    ></img>

                    <figcaption className="Figcaption">
                      Electrical & Electronics
                    </figcaption>
                  </div>

                  <div className="card-back  overlay5">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={consumerimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video18.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Electricity.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>

                    <ul className="detailul">
                      <li className="detailli">
                      Advanced Technologies with proficient Personnel{" "}
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage5}
                        >
                          <span><u>Click here </u>to know more about Electrical & Electronics</span>
                        </button>
                        <br></br>
                       
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
           {/* Healthcare */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay8">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div> */}
                      {/* <img className="Img" src={Healthcareimg} alt="Brohm Lake" /> */}
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source src={HEALTHCAREvideo} type="video/m4v" />
                      </video> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          {/* <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video9.mp4"
                          /> */}
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/HEALTHCARE.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <img
                      src={hospitalicon}
                      className="Healthcareicon positionserviceicon"
                    ></img>
                    <figcaption className="Figcaption">Healthcare</figcaption>
                  </div>
                  <div className="card-back  overlay8">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={Healthcareimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          {/* <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video9.mp4"
                          /> */}
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/HEALTHCARE.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                      Holistic Healthcare Solutions
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage8}
                        >
                          <span><u>Click here </u>to know more about Healthcare</span>
                        </button>
                        <br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Industrial */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay9">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img className="Img" src={Industrialimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video15.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/industrial.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <figcaption className="Figcaption">Industrial</figcaption>
                    <img
                      src={industrialicon}
                      className="Industrialicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay9">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      <img
                        src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video15.mp4"
                        alt="Brohm Lake"
                      />
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                      End to End Turnkey Project Management and Industrial Know how
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage9}
                        >
                          <span><u>Click here </u>to know more about Industrial</span>
                        </button>
                        <br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Infrastructure */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay10">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img className="Img" src={Infrastructureimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video5.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Infrastructure.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <figcaption className="Figcaption">
                      Infrastructure
                    </figcaption>
                    <img
                      src={infraicon}
                      className="Infrastructureicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay10">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={Infrastructureimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video5.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Infrastructure.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                      Cost-effective strategies with superior standards and designs
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage10}
                        >
                          <span><u>Click here </u>to know more about Infrastructure</span>
                        </button>
                        <br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Logistics */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay11">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img className="Img" src={Logisticsimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video4.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Logistics.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <figcaption className="Figcaption">Logistics</figcaption>
                    <img
                      src={logisticsicon}
                      className="Logisticsicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay11">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={Logisticsimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video4.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Logistics.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                        Understanding your needs and suggestions from Experts of the Market in many fields
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage11}
                        >
                        <span> <u>Click here </u>to know more about Logistics</span>
                        </button><br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Media & Entertainment*/}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay12">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img className="Img" src={MediaandEntertainmentimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Media.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Media.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <figcaption className="Figcaption">
                      Media & Entertainment
                    </figcaption>
                    <img
                      src={mediaicon}
                      className="Mediaicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay12">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={MediaandEntertainmentimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Media.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Media.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">                       
                        Coproduce the finest experiences with our insights and expertise
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage13}
                        >
                         <span> <u>Click here </u>to know more about Media & Entertainment</span>
                        </button><br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
           {/* Mergers and Acquisitions */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay20">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div> */}
                      {/* <img className="Img" src={Mergersimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Merger.m4v"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Merger.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>

                    <figcaption className="Figcaption">
                      {" "}
                      Mergers and Acquisitions
                    </figcaption>
                    <img
                      src={mergersicon}
                      className="Tourismicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay20">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={Mergersimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Merger.m4v"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Merger.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                         Grow exponentially with crossword Connects
                      </li>
                      <li>
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage21}
                        >
                         <span> <u>Click here </u>to know more about Mergers and Acquisitions</span>
                        </button><br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Manpower , Leaders and Employment */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              <div className="flip-card-container">
                <div className="flip-card">
                  <div className="card-front overlay22">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/manpower.m4v"
                          />
                        </div>
                      </LazyLoad>
                      {/* <img className="Img" src={Mergersimg} alt="Brohm Lake" /> */}
                    </figure>
                    <figcaption className="Figcaption">
                      {" "}
                      Manpower , Leaders and Employment
                    </figcaption>
                    <img
                      src={manpowericon}
                      className="Tourismicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay22">
                    <figure className="Figure">
                    <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/manpower.m4v"
                          />
                        </div>
                      </LazyLoad>
                      {/* <div className="img-bg"></div> */}
                      {/* <img src={Mergersimg} alt="Brohm Lake" /> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                        Right Tech , Right People, Growth Multiplied
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage22}
                        >
                          <span><u>Click here </u>to know more about Manpower , Leaders and Employment</span>
                        </button><br></br>
                       
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
           {/* Oil & Gas */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay14">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img className="Img" src={Oilimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video10.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/oil & gas.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <figcaption className="Figcaption">Oil & Gas</figcaption>
                    <img
                      src={oilandgasicon}
                      className="Oilicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay14">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={Oilimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video10.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/oil & gas.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                       Waste Management and Maintenance Infrastructure development 
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage15}
                        > 
                          <span><u>Click here </u>to know more about Oil & Gas</span>
                        </button><br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Outsourcing & Offshoring */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}{" "}
                  <div className="card-front overlay15">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img className="Img" src={Outsourcingimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video1.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/outsourcing.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <figcaption className="Figcaption">
                      Outsourcing & Offshoring
                    </figcaption>
                    <img
                      src={outsourcingicon}
                      className="Outsourcingicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay15">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={Outsourcingimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video1.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/outsourcing.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                       Directions and support to spread your networks
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage16}
                        >
                         <span> <u>Click here </u>to know more about Outsourcing & Offshoring</span>
                        </button><br></br>
                       
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Pharma, Lifesciences, Devices & Diagnostics */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay16">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      {/* <img className="Img" src={Pharmaimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video12.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Pharma.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <figcaption className="Figcaption">
                      Pharma, Lifesciences, Devices & Diagnostics
                    </figcaption>
                    <img
                      src={pharmaicon}
                      className="Pharmaicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay16">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={Pharmaimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video12.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Pharma.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                      Fruitful Strategies/Innovations and upright research to be with evolving market
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage17}
                        >
                         <span> <u>Click here </u>to know more about Pharma, Lifesciences, Devices & Diagnostics</span>
                        </button><br></br>
                       
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Professional Services */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay17">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      {/* <img className="Img" src={Professionalimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video8.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Professional.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <figcaption className="Figcaption">
                      {" "}
                      Professional Services
                    </figcaption>
                    <img
                      src={professionalservicesicon}
                      className="Professionalicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay17">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={Professionalimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video8.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Professional.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                        Solutions for All Professional Services under one roof 
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage18}
                        >
                         <span> <u>Click here </u>to know more about Professional Services </span>
                        </button><br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
           {/* Professional Services */}
            {/* Research and development */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay4">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Researchvideo.m4v"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Researchvideo.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                      {/* <img className="Img" src={development1img} alt="Brohm Lake" /> */}
                    </figure>
                    <img
                      src={researchanddevelopmenticon}
                      className="FMCGicon positionserviceicon"
                    ></img>
                    <figcaption className="Figcaption">
                      Research and Development
                    </figcaption>
                  </div>
                  <div className="card-back  overlay4">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={development1img} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Researchvideo.m4v"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Researchvideo.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                          Rooted Research across Sectors, Leading you towards Innovation 
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage4}
                        >
                        <span>  <u>Click here </u>to know more about Research and Development</span>
                        </button><br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Tourism */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay12">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img className="Img" src={Tourismimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video3.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Tourism.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <figcaption className="Figcaption">Tourism</figcaption>
                    <img
                      src={tourismicon}
                      className="Tourismicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay12">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={Tourismimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video3.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Tourism.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                       Tap in the Ever Growing Field of Tourism and Expand to newer Markets
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage12}
                        >
                        <span>  <u>Click here </u>to know more about Tourism</span>
                          {/* <span>Tourism</span> */}
                        </button><br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

             {/* Events */}
             <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container">
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay4">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/event.m4v"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Marketing.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                      {/* <img className="Img" src={marketingvideo} alt="Brohm Lake" /> */}
                    </figure>
                    <figcaption className="Figcaption">Events and Networking </figcaption>
                    <img
                      src={eventicon}
                      className="Tourismicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay4">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/event.m4v"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Marketing.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                      {/* <img src={marketingvideo} alt="Brohm Lake" /> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                      Right Networking Opportunities, for the best 
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage26}
                        >
                        <span>  <u>Click here </u>to know more about Events and Networking </span>
                        </button><br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
                   
            {/* FMCG */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container"   >
                <div className="flip-card">
                  {/* <Particle /> */}

                  <div className="card-front overlay7">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div> */}
                      {/* <img className="Img" src={FMCGimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/walstart-service.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/fmcg.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <img
                      src={fmcgicon}
                      className="FMCGicon positionserviceicon"
                    ></img>

                    <figcaption className="Figcaption">FMCG</figcaption>
                  </div>

                  <div className="card-back  overlay7">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={FMCGimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/walstart-service.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/fmcg.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>

                    <ul className="detailul">
                      <li className="detailli">
                        Aiding the Product Journey from Manufacturing to homes {" "}
                      </li>
                      <li>
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage7}
                        >
                         <span> <u>Click here </u>to know more about FMCG</span>
                        </button><br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Retail */}
            <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container"  >
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay19">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img className="Img" src={Retailimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video16.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Retail.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <figcaption className="Figcaption"> Retail</figcaption>
                    <img
                      src={retailicon}
                      className="Tourismicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay19">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={Retailimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src={
                              "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video16.mp4"
                            }
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Retail.m4v"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                        Effective strategies and solutions to navigate Markets 
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage20}
                        > 
                      <span>    <u>Click here </u>to know more about Retail</span>
                        </button><br></br>
                       
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            
             {/* Real Estate*/}
             <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container"   >
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay18">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      {/* <img src={RealEstateimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs {...videoJsOptions} src={"https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video13.mp4"} />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source src={realestatevideo} type="video/mp4" />
                      </video> */}
                    </figure>
                    <figcaption className="Figcaption"> Real Estate</figcaption>
                    <img
                      src={realestateicon}
                      className="FMCGicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay18">
                    <figure className="Figure">
                      <div className="img-bg"></div>
                      {/* <img src={RealEstateimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs {...videoJsOptions} src={"https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video13.mp4"} />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source src={realestatevideo} type="video/mp4" />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                      Customized Solutions for all your needs.
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage19}
                        >
                        <span>  <u>Click here </u>to know more about Real Estate</span>
                        </button><br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            
             {/* Financial services */}
             <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container"  >
                <div className="flip-card">
                  {/* <Particle /> */}

                  <div className="card-front overlay6">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img className="Img" src={financialimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Financial.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Financial.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <img
                      src={Financialicon}
                      className="Financialicon positionserviceicon"
                    ></img>

                    <figcaption className="Figcaption">
                      Financial services
                    </figcaption>
                  </div>

                  <div className="card-back overlay6">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={financialimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Financial.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/Financial.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                        Step into an advanced field of Fintech
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage6}
                        >
                       <span>   <u>Click here </u>to know more about Financial services</span>
                        </button><br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          
             {/* Metals, Minerals & Mining */}
             <div className="col-lg-3 col-md-4 col-6 wow fadeInUp">
              {/* <!-- flip-card-container --> */}
              <div className="flip-card-container"  >
                <div className="flip-card">
                  {/* <Particle /> */}
                  <div className="card-front overlay13">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div> */}
                      {/* <img className="Img" src={Metalsimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video2.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/mining.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <figcaption className="Figcaption">
                      Metals, Minerals & Mining
                    </figcaption>
                    <img
                      src={miningmineralsmicon}
                      className="Metalsicon positionserviceicon"
                    ></img>
                  </div>
                  <div className="card-back  overlay13">
                    <figure className="Figure">
                      {/* <div className="img-bg"></div>
                      <img src={Metalsimg} alt="Brohm Lake" /> */}
                      <LazyLoad>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Videojs
                            {...videoJsOptions}
                            src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/services/sector-video2.mp4"
                          />
                        </div>
                      </LazyLoad>
                      {/* <video className="Img" autoPlay loop muted playsInline>
                        <source
                          src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/walstrat/mining.mp4"
                          type="video/mp4"
                        />
                      </video> */}
                    </figure>
                    <ul className="detailul">
                      <li className="detailli">
                       Erudite researchers with different Strategies
                      </li>
                      <li>
                        {" "}
                        <button
                          className="btn-glow btn-hover-shine"
                          onClick={this.openservicepage14}
                        >
                          <span><u>Click here </u>to know more about Metals, Minerals & Mining </span>
                        </button>
                        <br></br>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    );
  }
}
