/* eslint-disable */

import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
  Button,
  TextField,
  DialogContentText,
  Fab,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import axios from "axios";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import AlertDialog from "./AlertDialog";
import "./contact.css";
import firebase from "../../../utilities/Firebase";
import MuiPhoneNumber from "material-ui-phone-number";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function MeetingDialog(props) {
  const { open, onClose, type } = props;
  const classes = useStyles();
  const [alertDialog, setAlertDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [meetingSpin, setmeetingSpin] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [mailerror, setMailError] = useState(false);

  const setAlertDialogfunc = () => {
    setAlertDialog({
      ...alertDialog,
      isOpen: false,
      title: "",
      subTitle: "",
    });
  };

  const [customer, setCustomer] = useState({
    customerFName: "",
    customerLName: "",
    customerEmail: "",
    customerMessage: "",
    companyName:"",
    phoneNumber:""
    // customerContactNo: 0,
  });

  const [selectedDate, handleDateChange] = useState(new Date());

  useEffect(() => {
    setCustomer({
      ...customer,
      customerFName: "",
      customerLName: "",
      customerEmail: "",
      customerMessage: "",
    });
  }, []);

  const emailcheck = () => {
    if (customer.customerEmail === "") {
      setErrorText(null);
    } else {
      if (ValidateEmail(customer.customerEmail)) {
        setErrorText(null);
        setMailError(false);
      } else {
        setMailError(true);
        setErrorText("Please enter valid email");
      }
    }
  };

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  const handleCustomerChange = (event) => {
    setCustomer({
      ...customer,
      [event.target.name]: event.target.value,
    });
    console.log("customerDetails", customer,event.target.value);
  };

  const handleCloseMeeting = () => {
    if (customer.customerFName === "") {
      setAlertDialog({
        isOpen: true,
        title: "Please enter your name",
      });
    } else if (customer.customerEmail === "") {
      setAlertDialog({
        isOpen: true,
        title: "Please enter your email",
      });
    } else if (mailerror) {
      setAlertDialog({
        isOpen: true,
        title: "Please enter valid email",
      });
    } else {
      // setCustomer({
      //   ...customer,
      //   meetingDateTime: moment(selectedDate).format("x"),
      // });
      // console.log(
      //   "meetingDateTime",
      //   new Date(selectedDate).toLocaleString("en-IN")
      // );
      new Promise((res, rej) => {
        firebase
          .firestore()
          .collection("walstrat")
          .doc("root")
          .collection("Enquiry")
          .add({
            type: "Meeting",
            userName: customer.customerFName + customer.customerLName,
            userEmail: customer.customerEmail,
            userMessage: customer.customerMessage,
            dateTime: new Date().getTime(),
            meetingdateTime: new Date(selectedDate).getTime(),
            mailStatus: "failure",
          })
          .then((response) => {
            res(response.id);
          });
      })
        .then((res) => {
          let meetingDate = new Date(selectedDate).toLocaleString("en-IN");
          sendEmail(
            customer.customerFName,
            customer.customerLName,
            customer.customerEmail,
            meetingDate,
            res
          );

          props.onClose();
          setCustomer({
            ...customer,
            customerFName: "",
            customerLName: "",
            customerEmail: "",
            customerMessage: "",
          });
        })
        .catch((err) => {
          console.log("error on upload", err);
        });
    }
  };
const handleMelzoUnite=()=>{
  if (customer.customerFName === "") {
    setAlertDialog({
      isOpen: true,
      title: "Please enter your name",
    });
  } else if (customer.customerEmail === "") {
    setAlertDialog({
      isOpen: true,
      title: "Please enter your email",
    });
  } else if (customer.phoneNumber === "") {
    setAlertDialog({
      isOpen: true,
      title: "Please enter your phone number",
    });
  }else if (mailerror) {
    setAlertDialog({
      isOpen: true,
      title: "Please enter valid email",
    });
  } else {
    new Promise((res, rej) => {
      firebase
        .firestore()
        .collection("walstrat")
        .doc("root")
        .collection("Enquiry")
        .add({
          type: "melzoUnite",
          userName: customer.customerFName + customer.customerLName,
          userEmail: customer.customerEmail,
          userMessage: customer.customerMessage,
          dateTime: new Date().getTime(),
          companyName:customer.companyName?customer.companyName:"",
          phoneNumber:customer.phoneNumber?customer.phoneNumber:""
        })
        .then((response) => {
          window.open("https://unite.melzo.com/","_blank")
          props.onClose();
          setCustomer({
            customerFName: "",
            customerLName: "",
            customerEmail: "",
            customerMessage: "",
            companyName:"",
            phoneNumber:""
          });
          res(response.id);
        });
    })
  }
}
  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <h1 className="titleschedule">{props.type=="melzoUnite" ? "Buyer Seller Form" : "Schedule Meeting with us"}</h1>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {props.type=="melzoUnite" ? "Welcome to Walstrat" : "Please add the following details to schedule a meeting us..!!!"}
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                name="customerFName"
                onChange={handleCustomerChange}
                value={customer.customerFName}
                variant="standard"
                label="Enter your first name"
                type="text"
                aria-label="Enter your first name"
                fullWidth
                id="customerFName"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="customerLName"
                onChange={handleCustomerChange}
                value={customer.customerLName}
                variant="standard"
                label={"Enter your last name"}
                type="text"
                fullWidth
                id="customerLName"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={mailerror}
                helperText={errorText}
                onBlur={emailcheck}
                name="customerEmail"
                onChange={handleCustomerChange}
                required
                value={customer.customerEmail}
                variant="standard"
                // autoFocus
                id="customerEmail"
                label={"Enter your Email"}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="customerMessage"
                onChange={handleCustomerChange}
                value={customer.customerMessage}
                variant="standard"
                label={"Enter your valuable message"}
                type="text"
                fullWidth
                id="customerMessage"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {props.type=="scheduleMeeting" ? 
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                  inputVariant="standard"
                  value={selectedDate}
                  disablePast
                  onChange={(dateTime) => {
                    handleDateChange(dateTime);
                  }}
                  label="Schedule Meeting Date and Time"
                  showTodayButton
                  style={{ width: "100%" }}
                />
              </MuiPickersUtilsProvider>
              :
              <TextField
                name="companyName"
                onChange={handleCustomerChange}
                value={customer.companyName}
                variant="standard"
                label={"Enter your company name"}
                type="text"
                fullWidth
                id="companyName"
              />}
            </Grid>
            <Grid item md={6} xs={12}>
            {props.type=="melzoUnite" ?
            <MuiPhoneNumber
              style={{paddingTop:"15px", width: "100%"}}
                name="phoneNumber"
                onChange={(num)=>setCustomer({...customer,phoneNumber:num})}
                value={customer.phoneNumber}
                defaultCountry={"in"}
            />:""}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            className="btn-glow btn-hover-shine fontschedule"
          >
            <CancelIcon style={{ marginRight: "5px" }} />
            Cancel
          </Button>
          {meetingSpin ? (
            <CircularProgress
              color="secondary"
              style={{ margin: "5px" }}
              size={20}
            />
          ) : (
            ""
          )}
          {props.type == "melzoUnite" ? <Button
            onClick={handleMelzoUnite}
            disabled={meetingSpin}
            className="btn-glow btn-hover-shine fontschedule"
          >
            <AddCircleIcon style={{ marginRight: "5px" }} /> Submit
            </Button> :

            <Button
              onClick={handleCloseMeeting}
              disabled={meetingSpin}
              className="btn-glow btn-hover-shine fontschedule"
            >
              <AddCircleIcon style={{ marginRight: "5px" }} /> Schedule Meeting
            </Button>
          }
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertDialog={alertDialog}
        setAlertDialog={setAlertDialogfunc}
      />
    </React.Fragment>
  );
}

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return String(
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}
function sendEmail(
  customerFName,
  customerLName,
  customerEmail,
  meetingDateTime,
  firebaseId
) {
  var regName = customerFName + " " + customerLName;
  var regEmail = customerEmail;
  var regMeetingDate = meetingDateTime;
  var regMeetingLink = "https://unite.melzo.com/" + guid();
  var ref = firebase
    .firestore()
    .collection("walstrat")
    .doc("root")
    .collection("Enquiry")
    .doc(firebaseId);

  console.log("Mail Data", regName, regEmail, regMeetingDate, regMeetingLink);
  const regMessage =
    "Dear " +
    regName +
    "," +
    "<br/><br/>" +
    `<p>Thank you for scheduling a meeting with us!.</p>
  
  <p>To join the meeting please click on the following link </p>
 
  <br/>
  
  <p> Join Meeting at (${regMeetingDate}): <a href=${regMeetingLink}>${regMeetingLink}</a></p>
  <br/>
  <p>Thank you once again and have a wonderful day! </p>
  <br/>
  <b>If you have any queries please reply to this mail.</b>
  <br/>
  <p>Thank you,</p>
  <p>Walstrat Services Pvt. Ltd. </p>
  <br/>`;

  const adminMsg =
    "Dear Admin" +
    "," +
    "<br/><br/>" +
    `<p>Admin, you get this mail because one of your customer shedule a meeting.!</p>
  
  <p>Meeting details are as follow :: </p>
 
  <br/>
  <p> Meeting Date : ${regMeetingDate} </P>
  <br/>
  <p> Click to Join Meeting : <a href=${regMeetingLink}>${regMeetingLink}</a></p>
  <br/>
  <p>Have a wonderful day! </p>
  <br/>
  <p>Thank you,</p>
  <p>Walstrat Services Pvt. Ltd. </p>
  <br/>`;
  var obj = {
    useremail: regEmail, // User Mail Id
    message: regMessage,
    expoName: "walstrat-info",
    senderName: "Walstrat Services Pvt. Ltd.",
    ccUser: "jyoti.kaur67@gmail.com", // Admin Mail Id
    bccUser: "",
    subject: "Thank you for schedule a meeting with us!",
  };
  var adminObj = {
    useremail: "walstrat999@gmail.com", // Admin Mail Id
    message: adminMsg,
    expoName: "walstrat-info",
    senderName: "Walstrat Services Pvt. Ltd.",
    ccUser: "jyoti.kaur67@gmail.com",
    bccUser: "",
    subject: `${regEmail} Schedule a meeting on ${regMeetingDate} !`,
  };
  // http://localhost:5000/sendtoemail-3610d/us-central1/expoRegistration
  axios
    .post(
      "https://us-central1-sendtoemail-3610d.cloudfunctions.net/commonGmailSendEmailFunction",
      obj,
      {
        headers: {
          // 'x-access-token': token
        },
      }
    )
    .then((regEmail) => {
      console.log("Done", regEmail);
      alert("Meeting details sent to your mail !");
      axios
        .post(
          "https://us-central1-sendtoemail-3610d.cloudfunctions.net/commonGmailSendEmailFunction",
          adminObj,
          {
            headers: {
              // 'x-access-token': token
            },
          }
        )
        .then((adminMsg) => {
          new Promise((res, rej) => {
            ref.update({
              mailStatus: "success",
            });
            res("success");
          })
            .then((res) => {
              console.log("Done admin", adminMsg);
            })
            .catch((err) => {
              console.log("error in updating ", err);
            });
        })
        .catch((error) => {
          console.log("Not Done admin", error);
          new Promise((res, rej) => {
            ref.update({
              mailStatus: "Admin-side failure",
            });
            res("success");
          })
        });
    })
    .catch((error) => {
      console.log("Not Done", error);
      new Promise((res, rej) => {
        ref.update({
          mailStatus: "Both-side failure",
        });
        res("success");
      })
    });
  
}
